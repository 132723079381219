import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UIProps } from '../../types';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import { getDevicesLink } from '../../utils/instruction-links';

interface InformationMenuProps {}

export const InformationMenu: FunctionComponent<InformationMenuProps> = () => {
  const connectionsStore = useTypedSelector(({ connections }) =>
    connections);
  const [loadingPhones, setLoadingPhones] = useState(true);
  useEffect(() => {
    setLoadingPhones(connectionsStore?.data == null);
  }, [connectionsStore]);

  const { t, i18n } = useTranslation();

  const phoneSetupRuUrl = 'https://iproxy.online/ru/blog/kak-zarabatyvat-na-sdache-v-arendu-mobilnih-proksi';
  const phoneSetupUrl = 'https://iproxy.online/blog/how-to-earn-money-on-mobile-proxies-in-any-city-or-country';
  const mobileProxySetupRuUrl = 'https://iproxy.online/ru/blog/polniy-guide-po-nastroike-iproxy-online';
  const mobileProxySetupUrl = 'https://iproxy.online/blog/guide-for-setting-up-mobile-proxies-in-iproxyonline';

  const phoneSetupRecommendations = i18n.language === 'ru' ? phoneSetupRuUrl : phoneSetupUrl;
  const mobileProxySetupGuide = i18n.language === 'ru' ? mobileProxySetupRuUrl : mobileProxySetupUrl;

  return !loadingPhones ? (
    <InformationBlock>
      <InformationItem
        onClick={() => {
          window.open(`${phoneSetupRecommendations}`, '_blank');
        }}
      >
        {t('information.phoneSetup')}
      </InformationItem>
      <InformationItem
        onClick={() => {
          window.open(`${mobileProxySetupGuide}`, '_blank');
        }}
      >
        {t('information.proxySetup')}
      </InformationItem>
      <InformationItem
        onClick={() => {
          window.open(getDevicesLink(i18n.language), '_blank');
        }}
      >
        {t('information.recommendedDevices')}
      </InformationItem>
      <InformationItem
        onClick={() => {
          window.open('https://t.me/iproxy_online_support', '_blank');
        }}
      >
        {t('information.moreQuestions')}
      </InformationItem>
    </InformationBlock>
  ) : null;
};

const InformationBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 0 10px;

  @media (max-width: 720px) {
    flex-direction: column;
    margin: 32px 12px;
  }
`;

const InformationItem = styled.div`
  width: 100%;
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  color: ${(prop: UIProps) =>
    prop.theme.colors.white};
  cursor: pointer;
  padding: 10px 35px;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${(prop: UIProps) =>
    prop.theme.sizes.font.medium};
  min-height: 55px;
  box-sizing: border-box;
  margin-right: 20px;
  transition: 0.3s;

  @media (max-width: 720px) {
    padding: 11px 24px;
    margin: 0 0 8px;
    font-size: 14px;

    &:last-child {
      margin: 0;
    }
  }

  :hover {
    opacity: 0.9;
  }

  :last-child {
    margin-right: 0px;
  }
`;
