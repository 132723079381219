import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { UIProps, UserRef } from '../types';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { formatDate, showNotification } from '../utils';
import { WrapperScreen } from '../styles/ui-controls';
import { ConfirmForm } from '../components/common/ConfirmForm';
import { ScreenInfo } from './styles';

import iconCopy from '../assets/coppy_icon.svg';
import iconRemove from '../assets/phone_icons/remove_icon.svg';
import iconIncome from '../assets/referalicons/income.svg';
import iconPiggy from '../assets/referalicons/piggy.svg';
import iconSCart from '../assets/referalicons/shoppingcart.svg';
import iconUser from '../assets/referalicons/user.svg';
import iconTg from '../assets/referalicons/tg.svg';
import { dateFormats } from '../utils/constants';
import store from '../core/store/store';
import { getInvitedUsers, removeReferralCode, addReferralCode } from '../core/store/actions/user';

const IconStats = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  .stat {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
  }

  .icon {
 width: 25px;
 margin-bottom: 10px;
  }

  .info {
 ${(props: UIProps) =>
    props.theme.sizes.font.small};
 font-weight: 600;
 color: #000;
 text-align: center;

 .label {
   ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
   text-transform: uppercase;
   color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
   font-weight: normal;
   margin-top: 5px;
 }
  }
`;

const RefContent = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  gap: 30px;
  align-items: start;

  @media (max-width: 720px) {
    flex-direction: column-reverse;
  }
  .title {
 color: ${(props: UIProps) =>
    props.theme.colors.dark};
 ${(props: UIProps) =>
    props.theme.sizes.font.medium};
 font-weight: 500;
  }

  .ref-link {
 color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
 ${(props: UIProps) =>
    props.theme.sizes.font.small};
 font-weight: 500;
 position: relative;
 display: flex;
 align-items: center;
 margin-top: 16px;
  }
`;

const PartnersList = styled.div`
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 20px;
  max-height: 150px;
  overflow: auto;

   ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props: UIProps) =>
    props.theme.colors.scrollBg};
    border-radius: 3px;
    cursor: pointer;
  }
`;

const PartnerTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;

  thead {
 ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
 color: ${(props: UIProps) =>
    props.theme.colors.darkGray};

 th {
   text-align: left;
   font-weight: normal;
   border-bottom: 1px solid rgba(191, 191, 191, 0.4);

   .content {
  margin-bottom: 8px;
   }
 }
  }

  tbody {
 ${(props: UIProps) =>
    props.theme.sizes.font.small};
 color: #000000;

 td {
   border-bottom: 1px solid rgba(191, 191, 191, 0.4);

 }

 tr {
   :last-child {
  td {
    border-bottom: none;
  }
   }
 }

 .content {
   padding: 12px 0;
 }
  }
`;

const URLIcon = styled.div`
  width: 12px;
  height: 12px;
  background-image: url(${iconCopy});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 10px;
  cursor: pointer;
  :hover {
 box-shadow: 0 0 10px #244eb23b;
  }
`;

const RemoveIcon = styled.div`
  width: 12px;
  height: 12px;
  background-image: url(${iconRemove});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 10px;
  cursor: pointer;
  :hover {
 box-shadow: 0 0 10px #244eb23b;
  }
`;

const WithdawInfo = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: #000000;

  a {
 color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
 font-weight: 600;
 text-decoration: none;

 :hover {
   opacity: 0.8;
 }
  }
`;

const Support = styled.div`
  margin-top: 15px;
  position: relative;
  display: flex;
  align-items: center;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  cursor: pointer;

  :hover {
 opacity: 0.8;
  }

  :before {
 width: 15px;
 height: 15px;
 display: block;
 content: '';
 background-image: url(${iconTg});
 margin-right: 10px;
  }
`;

const InputWrapper = styled.div`
 .info {
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  font-weight: 500;
  margin-bottom: 5px;
 }

 .wrong-input {
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  margin-top: 10px;
 }

 .input-wrapper {
  margin-bottom: 10px;
 }
`;

const Input = styled.input`
 display: inline-block;
 box-sizing: border-box;
 width: 250px;
 height: 30px;
 padding: 11px;
 border: 1px solid
 ${(props: UIProps) =>
    props.theme.colors.darkGray};
 border-radius: 4px;
 outline: none;
 font: 112.5%/1.45em "Montserrat", sans-serif;

 ::placeholder {
 font: 112.5%/1.45em "Montserrat", sans-serif;
 }
 :disabled{
 background-color: #5f5f5f1c;
 }


 ${(props: UIProps) =>
    props.theme.sizes.font.small};
 :focus {
 border-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
 box-shadow: 0px 0px 6px rgba(36, 78, 178, 0.2);
 }
`;

const Button = styled.button`
 display: inline-block;
 min-width: 155px;
 position: relative;
 box-sizing: border-box;

 padding: 7px;
 margin-left: 10px;
 border-radius: 5px;
 border: none;
 color: ${(props: UIProps) =>
    props.theme.colors.white};
 background-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};

 text-align: center;
 cursor: pointer;

 ${(props: UIProps) =>
    props.theme.sizes.font.small};
 transition: .2s;

 &:hover {
 opacity: .8;
 }

 @media (max-width: 720px) {
 display: flex;
 align-items: center;
 justify-content: center;
 margin-left: 0;
 margin-top: 10px;
 }
`;

const RefWrapper = styled.div`

`;

export interface ReferralScreenProps { }
export function ReferralScreen() {
  const { t } = useTranslation();
  const { profile } = useTypedSelector(({ user }) =>
    user);
  const referralCodes = useMemo(() =>
    profile?.referralCodes, [profile]);
  const { invitedUsers } = useTypedSelector(({ user }) =>
    user);
  const { transactions } = useTypedSelector(({ user }) =>
    user);
  const [thisMonth, setThisMonth] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalPurchase, setTotalPurchase] = useState(0);

  const [inputValue, setInputValue] = useState('');
  const [urlIsTaken, setUrlIsTaken] = useState(false);
  const [urlToDelete, setUrlToDelete] = useState('');
  const [confirmDeletingUrl, setConfirmDeletingUrl] = useState(false);
  const [wrongCharacter, setWrongCharacter] = useState(false);
  const [wrongLinkLength, setWrongLinkLength] = useState(false);
  const [wrongListLength, setWrongListLength] = useState(false);

  const invitedUsersData = invitedUsers?.data;
  const invitedUsersLength = invitedUsersData?.length;

  const calculateMoney = (userId) => {
    if (transactions?.data != null && transactions?.data.length) {
      const trs = transactions?.data.filter((a) =>
        (a?.friendId && a?.friendId === userId));
      return Math.round(trs.reduce((sm, a) =>
        (+sm + +a.amount), 0));
    }
    return 0;
  };

  const calculateMonthMoney = (userId) => {
    if (transactions?.data != null && transactions?.data.length) {
      const trs = transactions?.data
        .filter((a) =>
          (a?.friendId && a?.friendId === userId))
        .filter((a) => {
          const transDate = moment(a?.timestamp);
          if (a?.timestamp) {
            return transDate.isSameOrBefore(moment().startOf('month')) && transDate.isSameOrAfter(moment().endOf('month'));
          }
          return null;
        });
      return Math.round(trs.reduce((sm, a) =>
        (+sm + +a.amount), 0));
    }
    return 0;
  };

  const calculateRefPurchase = (userId) => {
    if (transactions?.data != null && transactions?.data.length) {
      const trs = transactions?.data.filter((a) =>
        (a?.friendId && a?.friendId === userId));
      return trs.length;
    }
    return 0;
  };

  const addUrl = async (url: string) => {
    setWrongCharacter(false);
    setUrlIsTaken(false);
    setWrongListLength(false);
    setWrongLinkLength(false);

    if (referralCodes?.includes(url.toUpperCase())) {
      setUrlIsTaken(true);
      return;
    }

    if (referralCodes?.length >= 5) {
      setWrongListLength(true);
      return;
    }

    if (url.length < 4) {
      setWrongLinkLength(true);
      return;
    }

    const reg = /^[A-z0-9]+$/;

    if (!reg.test(url)) {
      setWrongCharacter(true);
      return;
    }

    store.dispatch(
      addReferralCode.request({
        code: url,
        callback: () => {
          setInputValue('');
          setWrongCharacter(false);
          setUrlIsTaken(false);
          setWrongListLength(false);
          setWrongLinkLength(false);
        },
      }),
    );
  };

  const onApprove = async (url: string) => {
    store.dispatch(removeReferralCode.request({
      code: url,
      callback: () => {
        setConfirmDeletingUrl(false);
        setWrongCharacter(false);
        setWrongListLength(false);
        setWrongLinkLength(false);
      },
    }));
  };

  useEffect(() => {
    store.dispatch(getInvitedUsers.request(null, null));
  }, []);

  useEffect(() => {
    const income = invitedUsersData?.reduce(
      (accum, value) =>
        accum + calculateMoney((value as UserRef).id),
      0,
    );

    const monthIncome = invitedUsersData?.reduce(
      (accum, value) =>
        accum + calculateMonthMoney((value as UserRef).id),
      0,
    );

    const purchase = invitedUsersData?.reduce(
      (accum, value) =>
        accum + calculateRefPurchase((value as UserRef).id),
      0,
    );

    setTotalPurchase(purchase);
    setTotalIncome(income);
    setThisMonth(monthIncome);
  }, [invitedUsers]);

  const inviteLink = 'https://iproxy.online/invite/friend/';

  return (
    <WrapperScreen>

      <ScreenInfo
        maxWidth="940px"
        marginTop="25px"
        justifyContent
        alignItems
      >
        <div className="title">{t('referral.title')}</div>
        <div className="description">{t('referral.description')}</div>
        <div className="sub-description">{t('referral.subDescription')}</div>
        <div
          className="sub-description"
          dangerouslySetInnerHTML={{
            __html: t('referral.warning', {
              prohibited: `<span style='color: #FF3737'>${t('referral.prohibited')}</span>`,
              cancelBonuses: `<span style='color: #FF3737'>${t('referral.cancelBonuses')}</span>`,
            }),
          }}
        />
      </ScreenInfo>

      <RefContent>
        <RefWrapper>
          <div className="title">{t('referral.link')}</div>
          <div className="ref-link">
            {`${inviteLink}${profile != null ? profile.id : ''}`}
            <URLIcon onClick={() => {
              copy(`${inviteLink}${profile != null ? profile.id : ''}`);
              showNotification(`${t('notification.urlCopied')}`);
            }}
            />
          </div>
          {
            referralCodes?.map((url) =>
              (
                <div key={url} className="ref-link">
                  {`${inviteLink}${url != null ? url : ''}`}
                  <URLIcon onClick={() => {
                    copy(`${inviteLink}${url != null ? url : ''}`);
                    showNotification(`${t('notification.urlCopied')}`);
                  }}
                  />
                  <RemoveIcon onClick={() => {
                    setConfirmDeletingUrl(true);
                    setUrlToDelete(url);
                    setWrongCharacter(false);
                    setWrongListLength(false);
                    setWrongLinkLength(false);
                  }}
                  />
                </div>
              ))
          }
        </RefWrapper>

        <InputWrapper>
          <div className="info">{t('referral.maximumUrls')}</div>
          <div className="input-wrapper">
            <Input
              type="text"
              value={inputValue}
              placeholder={t('referral.inputLength')}
              onChange={(e) =>
                setInputValue(e.target.value)}
            />
            <Button
              type="button"
              onClick={() =>
                addUrl(inputValue)}
            >
              {t('referral.addNewUrl')}
            </Button>
          </div>

          {
            urlIsTaken
            && <div className="wrong-input">{t('referral.urlIsTaken')}</div>
          }

          {
            wrongCharacter
            && <div className="wrong-input">{t('referral.characterInfo')}</div>
          }

          {
            wrongListLength
            && <div className="wrong-input">{t('referral.maximumUrls')}</div>
          }

          {
            wrongLinkLength
            && <div className="wrong-input">{t('referral.minimumUrlLength')}</div>
          }

          {
            confirmDeletingUrl
            && (
              <ConfirmForm
                approveText={t('referral.deleteUrl')}
                cancelText={t('referral.cancelDelete')}
                text={`${t('referral.confirmDelete')} ${urlToDelete}?`}
                onApprove={() =>
                  onApprove(urlToDelete)}
                onCancel={() =>
                  setConfirmDeletingUrl(false)}
              />
            )
          }
        </InputWrapper>

      </RefContent>

      <div className="title">
        {t('referral.partners')}
        {' '}
        (
        {invitedUsersLength}
        )
      </div>
      <PartnersList>
        {invitedUsersLength > 0 ? (
          <PartnerTable>
            <thead>
              <tr>
                <th>
                  <div className="content">{t('referral.date')}</div>
                </th>
                <th>
                  <div className="content">{t('referral.rewards')}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                invitedUsersData?.map((refuf: UserRef) =>
                  (
                    <tr key={refuf.id}>
                      <td>
                        <div
                          className="content"
                        >
                          {formatDate(refuf?.createdTimestamp, dateFormats.longDateTime)}
                        </div>
                      </td>
                      <td>
                        <div className="content">
                          {calculateMoney(refuf.id)}
                          {' '}
                          {t('wallet.rub')}
                        </div>
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </PartnerTable>
        ) : null}
      </PartnersList>

      <IconStats>
        <div className="stat">
          <img className="icon" src={iconIncome} alt="icon" />
          <div className="info">
            {thisMonth}
            {' '}
            {t('wallet.rub')}
            <div className="label">{t('referral.thisMonth')}</div>
          </div>
        </div>
        <div className="stat">
          <img className="icon" src={iconPiggy} alt="" />
          <div className="info">
            {totalIncome}
            {' '}
            {t('wallet.rub')}
            <div className="label">{t('referral.rewards')}</div>
          </div>
        </div>

        <div className="stat">
          <img className="icon" src={iconUser} alt="icon" />
          <div className="info">
            {invitedUsersLength}
            <div className="label">{t('referral.signUp')}</div>
          </div>
        </div>
        <div className="stat">
          <img className="icon" src={iconSCart} alt="iproxy.online" />
          <div className="info">
            {totalPurchase}
            <div className="label">{t('referral.purchase')}</div>
          </div>
        </div>
      </IconStats>

      <WithdawInfo>
        {t('referral.withdraw')}
        <a href="https://t.me/iproxy_online_support" target="_blank" rel="noreferrer">{t('referral.withdraw2')}</a>
      </WithdawInfo>
      <Support onClick={() =>
        window.open('https://t.me/iproxy_online_support')}
      >
        iproxy_online_support
      </Support>
    </WrapperScreen>
  );
}
