import { Epic } from 'redux-observable';
import {
  catchError, filter, switchMap, withLatestFrom,
} from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';
import { from, of, concat } from 'rxjs';

import { idle } from '../actions/app';
import { loadUserPhones } from '../actions/connections';
import {
  addReferralCode,
  getApiKey,
  getInvitedUsers,
  getReferralCodes,
  loadPaymentMethods,
  loadUserAutoPaymentInfo,
  loadUserBalance,
  loadUserInfo,
  loadUserTransaction,
  logInUser,
  removeReferralCode,
  updateApiKey,
  updateUserPassword,
  updateUserProfile,
} from '../actions/user';
import {
  AutoPaymentInfo, SignInResponse, User, UserProfile,
} from '../../../types';
import { Api } from '../../api';
import { storage } from '../../../services';
import AnalyticsUtils from '../../../utils/analytics-utils';
import { landingPath } from '../../../constants';

const ApiURLSignIn = () =>
  '/users/login';
const ApiURLUserProfile = () =>
  '/user/token/[TOKEN]';
const ApiURLUserAutoPaymentInfo = (userId: string) =>
  `users/${userId}/auto-payment-info?token=[TOKEN]`;
const ApiURLUserBalance = (userId: string) =>
  `/user/${userId}/balance`;

const ApiURLUserGetToken = (userId: string) =>
  `/user/${userId}/api_key`;
const ApiURLUserRefreshToken = (userId: string) =>
  `/user/${userId}/refresh_api_key`;
const ApiURLGetInvitedUsers = () =>
  '/referral/user/invites';
const ApiURLAddReferralCode = () =>
  '/referral-codes';
const ApiURLRemoveReferralCode = (code: string) =>
  `/referral-codes/${code}`;
const ApiURLGetUserTransactions = (userId: string) =>
  `/user/${userId}/transactions`;
const ApiURLUpdateUserProfile = () =>
  '/users/update';
const ApiURLUpdateUserPassword = () =>
  '/change_password';
const ApiURLLoadPaymentMethods = () =>
  '/stripe/payment_methods?token=[TOKEN]';

export const userSignInEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(logInUser.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) => {
      const user: User = payload as User;
      return from(Api.post(ApiURLSignIn(), user as any)).pipe(
        switchMap(({ data }) => {
          storage.addItem('SYSTEM', 'token', (data as SignInResponse).token);
          return of(logInUser.success(null, null));
        }),
        catchError((error) => {
          console.error('Info:> error :=', error);
          return of(logInUser.failure(null));
        }),
      );
    }),
    catchError((error) => {
      console.error('Error:> error :=', error);
      return of(logInUser.failure(null));
    }),
  );

export const userProfileEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadUserInfo.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLUserProfile(), null, null, false)).pipe(
        switchMap(({ data }) => {
          if (data?.hasOwnProperty('error')) {
            storage.removeItem('SYSTEM', 'token').then((token) => {
              if (token != null) {
                window.location.reload();
              } else {
                window.location.href = landingPath;
              }
            });
          } else {
            const [id, email, tel, date] = [data?.id, data?.email, data?.telegram, data?.createdTimestamp];
            const name = `${data?.firstName} ${data?.lastName}`;
            AnalyticsUtils.initDashboardUserId(id, email, tel, date, name, data?.lang, data?.langSystem);
            return concat(
              of(loadUserInfo.success(data as UserProfile)),
              of(loadUserPhones.request(null)),
              of(loadUserBalance.request({ userId: (data as UserProfile).id })),
              // of(loadUserConnections.request({userId: (data as UserProfile).id}))
            );
          }
          return null;
        }),
      )),
    catchError((error: Error) =>
      of(loadUserInfo.failure(error))),
  );

export const loadUserAutoPaymentInfoEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadUserInfo.success)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLUserAutoPaymentInfo(state$.value.user.profile.id), null, null, false)).pipe(
        switchMap(({ data }) =>
          concat(
            of(loadUserAutoPaymentInfo.success({ data: data as AutoPaymentInfo })),
          )),
      )),
    catchError((error: Error) =>
      of(loadUserAutoPaymentInfo.failure(error))),
  );

export const loadUserBalanceEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadUserBalance.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLUserBalance(payload.userId), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(loadUserBalance.success(data))),
      )),
    catchError((error: Error) =>
      of(loadUserBalance.failure(error))),
  );

export const getApiKeyEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getApiKey.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLUserGetToken(state$.value.user.profile.id), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(getApiKey.success({ token: data.result }))),
      )),
    catchError((error: Error) =>
      of(getApiKey.failure(error))),
  );

export const updateApiKeyEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateApiKey.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.post(ApiURLUserRefreshToken(state$.value.user.profile.id), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(updateApiKey.success({ token: data.result }))),
      )),
    catchError((error: Error) =>
      of(updateApiKey.failure(error))),
  );

export const getInvitedUsersEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getInvitedUsers.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLGetInvitedUsers(), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(getInvitedUsers.success(data))),
      )),
    catchError((error: Error) =>
      of(getInvitedUsers.failure(error))),
  );

export const getReferralCodesEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getReferralCodes.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLAddReferralCode(), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(getReferralCodes.success(data))),
      )),
    catchError((error: Error) =>
      of(getReferralCodes.failure(error))),
  );

export const addReferralCodeEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addReferralCode.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLAddReferralCode(), { code: payload.code }, null, false)).pipe(
        switchMap(({ data }) => {
          payload.callback();
          return of(addReferralCode.success({ result: data }));
        }),
      )),
    catchError((error: Error) =>
      of(addReferralCode.failure(error))),
  );

export const removeReferralCodeEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeReferralCode.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.delete(ApiURLRemoveReferralCode(payload.code), null, null, false)).pipe(
        switchMap(({ data }) => {
          payload.callback();
          return of(removeReferralCode.success({ result: data }));
        }),
      )),
    catchError((error: Error) =>
      of(removeReferralCode.failure(error))),
  );

export const loadUserTransactionsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadUserTransaction.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLGetUserTransactions(state$.value.user.profile.id), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(loadUserTransaction.success({ data }))),
      )),
    catchError((error: Error) =>
      of(loadUserTransaction.failure(error))),
  );

export const updateUserProfileEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateUserProfile.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) => {
      const newPayload = { ...payload };
      newPayload.id = state$.value.user.profile?.id;
      return from(Api.post(ApiURLUpdateUserProfile(), newPayload, null, false)).pipe(
        switchMap(({ data }) => {
          payload.callback?.({ success: true });
          return of(loadUserInfo.success(data as UserProfile));
        }),
        catchError((error: Error) => {
          payload.callback?.(error);
          return of(loadUserTransaction.failure(error));
        }),
      );
    }),
  );

export const updateUserPasswordEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateUserPassword.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLUpdateUserPassword(), payload, null, false)).pipe(
        switchMap(() =>
          of(idle())),
      )),
    catchError((error: Error) =>
      of(updateUserPassword.failure(error))),
  );

export const loadPaymentMethodsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadPaymentMethods.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLLoadPaymentMethods(), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(loadPaymentMethods.success(data?.data))),
        catchError(() =>
          of(loadPaymentMethods.failure(null))),

      )),
    catchError(() =>
      of(idle())),
  );
