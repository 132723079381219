import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';

import { MenuStructure, UIProps } from '../../types';
import arrowExpand from '../../assets/arrow_down.svg';

interface VerticalModalMenuProps {
  menuConfig: MenuStructure[];
  onChange: Function;
  activeItem: MenuStructure;
}

export const VerticalModalMenu: FunctionComponent<VerticalModalMenuProps> = (props) => {
  const {
    menuConfig,
    onChange,
    activeItem,
  } = props;

  const [onMouse, setOnMouse] = useState(null);
  const [isExpand, setExpand] = useState(false);

  const changeHandler = (i: MenuStructure) => {
    setExpand(false);
    onChange(i);
  };

  return (
    <Wrapper>
      <WrapperDesktop>
        {menuConfig?.map((i) =>
          (
            <MenuItem
              active={activeItem?.key === i.key}
              key={i.key}
              onMouseEnter={() =>
                setOnMouse(i.key)}
              onMouseOut={() =>
                setOnMouse(null)}
              onClick={() =>
                changeHandler(i)}
            >
              <MenuIconComponent>
                {React.cloneElement(i?.icon, { fill: activeItem?.key === i.key || onMouse === i?.key ? '#FFFFFF' : '#244EB2' })}
              </MenuIconComponent>
              {' '}
              {i.title}
            </MenuItem>
          ))}
      </WrapperDesktop>

      <WrapperMobile>
        <ActiveItem
          isExpand={isExpand}
          onClick={() =>
            setExpand(!isExpand)}
        >
          <Title>
            <MenuIconComponent>
              {React.cloneElement(activeItem?.icon, { fill: '#FFFFFF' })}
            </MenuIconComponent>
            <span>{activeItem?.title}</span>
          </Title>
          <ArrowIcon
            icon={arrowExpand}
            visible={isExpand}
          />
        </ActiveItem>
        {isExpand ? (
          <MobileMenu>
            {menuConfig.map((it) =>
              (
                <MobileMenuItem
                  key={it.key}
                  active={activeItem?.key === it.key}
                  onMouseEnter={() =>
                    setOnMouse(it.key)}
                  onMouseOut={() =>
                    setOnMouse(null)}
                  onClick={() =>
                    changeHandler(it)}
                >
                  <MenuIconComponent>
                    {React.cloneElement(it?.icon, { fill: '#244EB2' })}
                  </MenuIconComponent>
                  {it?.title}
                </MobileMenuItem>
              ))}
          </MobileMenu>
        ) : null}
      </WrapperMobile>
    </Wrapper>
  );
};

const Wrapper = styled.div`

`;

const WrapperDesktop = styled.div`
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.ocenlight};
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (max-width: 720px) {
    display: none;
  }
`;

const MenuIconComponent = styled.div`
  position: relative;
  top: 1px;
  width: 20px;
  margin-right: 4px;
`;

const WrapperMobile = styled.div`
  position: relative;
  display: none;

  @media (max-width: 720px) {
    position: relative;
    display: block;
  }
`;

interface ActiveItemProps extends UIProps {
  isExpand: boolean;
}

const ActiveItem = styled.div<ActiveItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 40px;
  padding: 14px 16px;
  border-radius: ${(props) =>
    (props.isExpand ? '10px 10px 0 0' : '10px')};
  box-sizing: border-box;

  background-color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  color: ${(prop: UIProps) =>
    prop.theme.colors.white};
  font-size: 0.8rem;

  cursor: pointer;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

interface ArrowIconProps {
  icon?: string;
  visible?: boolean;
}

const ArrowIcon = styled.div<ArrowIconProps>`
  width: 16px;
  height: 16px;
  background-image: url(${(props: ArrowIconProps) =>
    props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: ${(props: ArrowIconProps) =>
    (props.visible ? 'rotate(180deg)' : 'rotate(0)')};
`;

const MobileMenu = styled.ul`
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 2;

  width: 100%;
  padding: 0 0 0 0;
  margin: 0;
  list-style: none;

  background-color: #EAEEF8;
  border-radius: 0 0 10px 10px;

  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.2);
`;
interface MenuItemProps extends UIProps {
  active: boolean;
}

const MobileMenuItem = styled.li<MenuItemProps>`
  display: flex;
  align-items: center;

  margin: 16px 16px 20px;
  box-sizing: border-box;

  font-size: 0.8rem;
  cursor: pointer;
`;

const MenuItem = styled.div<MenuItemProps>`
  width: 100%;
  min-height: 60px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  background-color: ${(prop: MenuItemProps) =>
    (prop.active ? prop.theme.colors.deepBlue : 'none')};
  color: ${(prop: MenuItemProps) =>
    (prop.active ? prop.theme.colors.white : prop.theme.colors.dark)};
  transition: .3s;
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px 20px;
  align-items: center;
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};

  :hover {
    background-color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    color: ${(prop: UIProps) =>
    prop.theme.colors.white};
  }

  @media (max-width: 720px) {
    padding: 5px 10px;
    font-size: 12px;
  }

`;
