import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import './lang';
import './global.css';

import { Datadog } from './services/Datadog';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <App />
  </Sentry.ErrorBoundary>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

Sentry.init({
  dsn: 'https://30b936de961ec902a9a340033483f1ac@o4508682963517440.ingest.us.sentry.io/4508682965811200',
  integrations: [Sentry.replayIntegration()],

  tracesSampleRate: 1.0,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event) {
    const allowedDomain = 'iproxy.online';
    if (window.location.hostname !== allowedDomain) {
      return null;
    }
    return event;
  },
});

// run on production
if (import.meta.env.APP_MODE === 'prod') {
  Datadog.runRum();
}
