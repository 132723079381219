import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useLoaderHook } from '../../hooks';
import { LoaderIcon } from './LoaderIcon';

export function Loader({ forceShow = false }: any) {
  const [showLoader, setShowLoader] = useState(true);
  const [displayBlock, setDisplayBlock] = useState(true);

  const loader = useLoaderHook();

  useEffect(() => {
    loader.listen(({ detail }) => {
      const { status } = detail;
      setShowLoader(status);
    });
  }, []);

  useEffect(() => {
    if (forceShow) {
      setDisplayBlock(forceShow);
      return;
    }

    if (showLoader) {
      setDisplayBlock(true);
    } else {
      setTimeout(() => {
        setDisplayBlock(false);
      }, 300);
    }
  }, [showLoader, forceShow]);

  useEffect(() =>
    () => {
      loader.removeEvent();
    }, []);

  return displayBlock && (
    <Wrapper show={showLoader || false}>
      <LoaderIcon width="150px" height="150px" />
    </Wrapper>
  );
}

interface WrapperProps {
  show?: boolean | null;
  opacity?: boolean | null;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: #fff;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4000;
  transition: .1s;
  opacity:  ${(props) =>
    (props.opacity ? 0 : 1)};
  align-items: center;
  justify-content: center;

  ${(props) =>
    (props.show && props.opacity) && css`
      opacity: 0.88;

  `}
`;
