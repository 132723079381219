import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logoIcon from '../assets/onb_technical.png';
import { storage } from '../services';
import iconLogOut from '../assets/log-out.svg';
import { UIProps } from '../types';

const LogOut = styled.div`
  display: flex;
  align-items: center;

  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  font-weight: 400;

  transition: 0.2s;
  cursor: pointer;

  /* ${(props: UIProps) =>
    props.theme.sizes.font.small};
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  cursor: pointer;
  transition: .2s;
  align-items: center; */

  @media (max-width: 720px) {
    // font-size: 10px;
  }

  &:hover {
    opacity: 0.7;
  }

  &:before {
    margin-right: 10px;
    margin-top: -1px;
    width: 40px;
    height: 40px;
    display: block;
    content: '';
    background-image: url(${iconLogOut});
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${(props: UIProps) =>
    props.theme.colors.white};
    border-radius: 50%;
  }
`;

export interface SideMenuActionsProps {}

export const SideMenuActions: FunctionComponent<SideMenuActionsProps> = () => {
  const { t } = useTranslation();

  const logOut = () => {
    storage.removeItem('SYSTEM', 'token').then(() => {
      // @ts-ignore
      if (window !== undefined) {
        window.location.href = window.location.origin;
      }
    });
  };

  const openChat = () => {
    document?.querySelector<HTMLElement>('.woot-widget-bubble').click();

    if (!document.querySelector('.woot-exit-support-chat')) {
      const container = document.querySelector('.woot-widget-holder');
      const newElement = document.createElement('div');
      newElement.textContent = 'Exit';
      newElement.className = 'woot-exit-support-chat';
      newElement.addEventListener('click', () => {
        document?.querySelector<HTMLElement>('.woot-widget-bubble').click();
      });
      container.appendChild(newElement);
    }
  };

  return (
    <div className="technical">
      <div className="support" onClick={openChat}>
        <div className="icon">
          <img src={logoIcon} width="40px" alt="" />
        </div>
        <div className="label">{t('onboarding.support')}</div>
      </div>
      <LogOut onClick={() =>
        logOut()}
      >
        {t('profile.logOut')}
      </LogOut>
    </div>
  );
};
