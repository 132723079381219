import React, { useState, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Stepper } from '../components/common/Stepper';
import { OnbDownloadForm } from '../components/onboarding/onb-download-form';
import { OnbSetupForm } from '../components/onboarding/onb-setup-form';
import { OnbConnectionForm } from '../components/onboarding/onb-connection-form';
import { OnbFinalForm } from '../components/onboarding/onb-final-form';
import { CommonAppContext } from '../context/CommonAppContext';
import { OnbEmailForm } from '../components/onboarding/onb-email-form';
import logo from '../assets/logo_iproxy.svg';
import { createNewConnection } from '../core/store/actions/connections';
import store from '../core/store/store';
import { SideMenuActions } from './SideMenuActions';

interface MobileTechSupportPropr {
  hide?: boolean;
}
export const MobileTechSupport = styled.div<MobileTechSupportPropr>`
  display: none;
  background-color: #244eb2;
  height: 70px;
  box-sizing: border-box;
  width: 100vw;
  position: relative;
  align-content: center;
  margin-top: 40px;
  padding: 20px;
  align-items: center;
  .technical {
    margin-top: 7px;
    position: relative !important;
    bottom: 0 !important;
  }

  @media only screen and (max-width: 880px) {
    display: ${(props: MobileTechSupportPropr) =>
    (props?.hide ? 'none' : 'flex')};
  }
`;

interface StepperContainerProps {
  hide?: boolean;
}

const StepperContainer = styled.div<StepperContainerProps>`
  padding-top: 20px;
  ${(props: StepperContainerProps) =>
    props?.hide
    && css`
      @media only screen and (max-width: 880px) {
        display: ${props?.hide ? 'none' : 'block'};
      }
    `}
`;

interface OnboardButtonProps {
  disable?: boolean;
  color?: 'blue' | 'green';
  fullWith?: boolean;
}
export const OnboardButton = styled.div<OnboardButtonProps>`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props: OnboardButtonProps) =>
    (!props?.fullWith ? 'fit-content' : '100%')};
  background: ${(props: OnboardButtonProps) =>
    (props?.color === 'green' ? '#60C7A2' : '#244EB2')};
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  padding: 16px 40px;
  cursor: pointer;
  transition: 0.3s;
  ${(props: OnboardButtonProps) =>
    props?.disable
    && css`
      opacity: 0.4;
    `}

  :hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  background-color: #fff;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0;

  @media (max-width: 880px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .technical {
    color: white;
    font-weight: 400;
    height: 50px;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    cursor: pointer;
    transition: 0.3s;
    width: 250px;
    justify-content: space-between;

    .icon {
      display: flex;
      align-items: center;
    }

    .label {
      margin-left: 10px;
      // margin-top: -6px;
    }
  }

  .support {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

    :hover {
      opacity: 0.7;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 30px;
  flex-direction: column;
  width: 100%;

  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;

    width: 100%;
  }

  @media (max-width: 720px) {
    padding: 15px 15px 0;
    .content-container {
      width: 100%;
      min-width: 100%;
    }
  }
`;

export const Sidebar = styled.div`
  background-color: #244eb2;
  width: 380px;
  min-width: 300px;
  box-sizing: border-box;
  padding: 27px;
  position: relative;

  .onb_logo {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 880px) {
    width: 100%;
    padding: 25px 25px 15px;

    .technical {
      display: none;
    }

    .onb_logo {
      margin-top: 0;
      margin-bottom: 0;

      img {
        width: 150px;
      }
    }

    .hide {
      display: none;
    }
  }
`;

export interface OnboardingScreenProps {}

export const OnboardingScreen: FunctionComponent<
OnboardingScreenProps
> = () => {
  const [activeStep, setStep] = useState(0);
  const [onboardingDone, setDone] = useState(false);
  const [history, setHistory] = useState({});
  const context = React.useContext(CommonAppContext);
  const { t } = useTranslation();

  const goToNextScreen = () => {
    if (activeStep < stepperConfig.length - 1) {
      setStep(activeStep + 1);
      setHistory({ ...history, [activeStep]: true });
    } else {
      setDone(true);
    }
  };

  const stepperConfig = [
    {
      title: t('onboarding.email.confirm'),
      content: (
        <OnbEmailForm
          onNext={() => {
            if (
              (context?.onboarding == null
                || context?.onboarding?.isOnboarding)
              && context?.onboarding?.phone == null
            ) {
              store.dispatch(
                createNewConnection.request({
                  onboarding: true,
                  callback: () => {
                    context?.$loadUserOnboardingStatus().then(() => {});
                  },
                }),
              );
            }
            goToNextScreen();
          }}
        />
      ),
    },
    {
      title: t('onboarding.steps.download'),
      content: <OnbDownloadForm />,
    },
    {
      title: t('proxy.authorization'),
      content: (
        <OnbSetupForm phoneId={context?.onboarding?.phone?.id} />
      ),
    },
    {
      title: t('onboarding.steps.setup'),
      content: <OnbConnectionForm onboadingData={context?.onboarding} />,
    },
    {
      title: t('onboarding.steps.finish'),
      content: <OnbFinalForm onboadingData={context?.onboarding} />,
    },
  ];

  return !onboardingDone
    && context?.onboarding != null
    && context?.onboarding?.isOnboarding ? (
      <Wrapper>
        <Content>
          <div className="content-container">
            {React.cloneElement(stepperConfig[activeStep]?.content, {
              nextScreen: goToNextScreen,
            })}
          </div>

          <MobileTechSupport hide={activeStep == null}>
            <SideMenuActions />
          </MobileTechSupport>
        </Content>
        <Sidebar>
          <div className="onb_logo">
            <img width="199px" src={logo} alt="" />
          </div>

          <StepperContainer hide={activeStep == null}>
            <Stepper config={stepperConfig} activeIndex={activeStep} />
          </StepperContainer>
          <SideMenuActions />
        </Sidebar>
      </Wrapper>
    ) : null;
};
