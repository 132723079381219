import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';

import { MenuStructure } from '../types';

import iconArrowDown from '../assets/arrrow_down.svg';

interface DashboardSubItemProps {
  menu: MenuStructure;
}
export const DashboardSubItem: FunctionComponent<DashboardSubItemProps> = (props) => {
  const { menu } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SubmenuItem openSubmenu={isOpen}>
      <div
        className="sub-item-value"
        onClick={() => {
          menu?.onClick();
          setIsOpen(false);
        }}
      >
        {menu?.title}
        { menu?.subMenu ? (<span className="submenu_arrow" />) : null}
      </div>
      {/* {menu?.subMenu && (openAll || isOpen) ? _renderSubmenuOfFloatItem(menu?.subMenu) : null} */}
    </SubmenuItem>
  );
};

interface SubmenuItemProps {
  openSubmenu?: boolean;
}

const SubmenuItem = styled.div<SubmenuItemProps>`
  width: 100%;
  box-sizing: border-box;
  transition: .1s;
  height: 100%;
  position: relative;
  
  .sub-item-value {
    min-height: 35px;
    display: flex;
    align-items: center;
    position: relative;
    width: auto;
    justify-content: space-between;
  }
  
  .submenu_arrow{
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-image: url(${iconArrowDown});
    background-position: center;
    background-repeat: no-repeat;
    transform: ${(prop: SubmenuItemProps) =>
    (prop.openSubmenu ? 'rotate(180deg)' : 'rotate(0)')};
  }
 
`;
