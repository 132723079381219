import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useModalHook } from '../../hooks';
import { UIProps } from '../../types';
import bgFone from '../../assets/bg-empty-connections.svg';

export default function WelcomePanel() {
  const modalOptions = useModalHook();

  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <Heading>
        {t('welcome.heading')}
        {' '}
        {' '}
      </Heading>

      <SubHeading>
        {t('welcome.subHeading')}
      </SubHeading>

      <TopInstruction>
        {t('welcome.topInstruction')}
      </TopInstruction>

      <Row>
        <NumSpan>
          1.
        </NumSpan>
        <RowContent>
          <RowText>
            <RawLink onClick={() => {
              modalOptions.show('phone.new');
            }}
            >
              {t('welcome.step1')}
            </RawLink>
          </RowText>
        </RowContent>
      </Row>

      <Row>
        <NumSpan>
          2.
        </NumSpan>
        <RowContent>
          <div>
            <RowText>
              <RawLink onClick={() => {
                modalOptions.show('balance.topUp');
              }}
              >
                {t('welcome.step2_0')}
              </RawLink>
              {' '}
              {t('welcome.step2_1')}
              <RawLink onClick={() => {
                window.open(i18n.language === 'en' ? 'https://youtu.be/pY2ioCYXJi0'
                  : t('welcome.replenishment_learn_more_link'), '_blank').focus();
              }}
              >
                {' '}
                {t('welcome.step2_2')}
              </RawLink>
            </RowText>
          </div>

        </RowContent>
      </Row>

      <Row>
        <NumSpan>
          3.
        </NumSpan>
        <RowContent>
          <div>
            <RowText>
              {t('welcome.step3_0')}
              <RawLink onClick={() => {
                window.open('https://play.google.com/store/apps/details?id=com.iproxy.android', '_blank').focus();
              }}
              >
                {' Play Market '}
              </RawLink>
              {t('welcome.step3_01')}
              <RawLink onClick={() => {
                window.open('https://iproxy.online/android', '_blank').focus();
              }}
              >
                {' APK '}
              </RawLink>
              {t('welcome.step3_1')}
            </RowText>
          </div>
        </RowContent>
      </Row>

      <Row>
        <NumSpan>
          4.
        </NumSpan>
        <RowContent>
          <div>
            <RowText>
              {t('welcome.step4_0')}
              <RawLink onClick={() => {
                window.open(t('welcome.step4_link'), '_blank').focus();
              }}
              >
                {t('welcome.step4_1')}
              </RawLink>
              {' '}

            </RowText>
          </div>
        </RowContent>
      </Row>

      <Row>
        <NumSpan>
          5.
        </NumSpan>
        <RowContent>
          <div>
            <RowText>
              {t('welcome.step5_0')}
              {' '}
              <RawLink onClick={() => {
                window.open('https://t.me/iproxy_online_support', '_blank').focus();
              }}
              >
                Telegram
              </RawLink>
              {' '}
              {t('welcome.step5_1')}
            </RowText>
          </div>
        </RowContent>
      </Row>

      <BackgroundFon />
    </Wrapper>
  );
}

const BackgroundFon = styled.div`
  max-width: 730px;
  width: 100%;
  height: 500px;
  background-image: url(${bgFone});
  position: absolute;
  top: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  margin-bottom: 40px;
  min-height: 450px;
  @media (max-width: 840px) {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
  @media(max-width: 720px){
    font-size: 16px;
    line-height: 24px;
  }
`;

const RowContent = styled.div`

`;

const NumSpan = styled.span`
  font-weight: 500;
  color: #000;
  font-size: 16px;
  line-height: 26px;
  margin-right: 20px;
`;

const RowText = styled.span`
  font-size: 16px !important;
  font-weight: 400 !important;
`;

const RawLink = styled.span`
  color: #244EB2;
  font-weight: 500;
  cursor: pointer;

  :hover {
    text-decoration: underline;
    opacity: 0.85;
  }
`;

const TopInstruction = styled.div`
  font-size: 20px;
  color: ${(props: UIProps) =>
    props?.theme?.colors?.dark};
  margin: 20px 0;
  font-weight: bold;
  font-weight: 500;
  @media(max-width: 720px){
    font-size: 16px;
    line-height: 24px;
  }
`;

const SubHeading = styled.div`
  font-size: 14px;
  color: ${(props: UIProps) =>
    props?.theme?.colors?.darkGray};
  margin: 20px 0;
  @media(max-width: 720px){
    font-size: 16px;
    line-height: 24px;
  }
`;

const Heading = styled.div`
  color: ${(props: UIProps) =>
    props?.theme?.colors?.deepBlue};
  margin-top: 40px;
  font-size: 24px;
  font-weight: 600;
  @media(max-width: 720px){
    font-size: 24px;
    line-height: 30px;
  }
`;
