import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import {
  EditModalSave,
  ModalSubtitle,
} from '../../../styles/ui-controls';
import { showNotification } from '../../../utils';
import { UIProps } from '../../../types';
import iconCopy from '../../../assets/coppy_icon.svg';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { QrInput } from '../../atoms/qrinput';
import ScrollWrapper from '../../common/ScrollWrapper';
import { Api } from '../../../core/api';

interface AuthProps {
  isPhoneOwner?: boolean;
  phoneId: string;
}

export const Auth: FunctionComponent<AuthProps> = (
  props,
) => {
  const {
    isPhoneOwner = false,
    phoneId,
  } = props;
  const [time, setTime] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const [phonePin, setPhonePin] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const [expiresAt, setExpiresAt] = useState(null);

  const minutes = `${Math.floor(time / 60)}`.padStart(2, '0');
  const seconds = `${time % 60}`.padStart(2, '0');
  const timer = `${minutes}:${seconds}`;

  const context = useContext(ConnectionEditViewContext);
  const { t } = useTranslation();

  const $loadPhonePinCode = (phoneId) => {
    if (phoneId != null && !showTimer) {
      return Api.get(`/phones/${phoneId}/pin_code`, null, null, false).then(
        ({ data }) => {
          setPhonePin(data?.friendlyPinCode);
          setExpiresAt(data?.pinExpiresAt);
          setLoaded(true);
        },
      );
    }

    return null;
  };

  useEffect(() => {
    if (loaded) {
      const timer = setInterval(() => {
        const currentTime = new Date().getTime();

        setTime(() => {
          const remainingTime = Math.max(Math.floor((expiresAt - currentTime) / 1000), 0);

          if (remainingTime === 0) {
            setShowTimer(false);
            setPhonePin(null);
            setLoaded(false);
            setExpiresAt(null);
            return 0;
          }

          return remainingTime;
        });
      }, 1000);

      return () =>
        clearInterval(timer);
    }
    return () => {};
  }, [loaded, expiresAt]);

  return (
    <ScrollWrapper>
      {!isPhoneOwner && !context?.permissions?.authorizationPIN ? null : (
        <>
          {
            showTimer
            && (
            <ModalSubtitle style={{ marginBottom: '10px' }}>
              {`${t('editForm.pinCode')} ${timer}`}
            </ModalSubtitle>
            )
          }

          <EditModalSave
            disabled={showTimer}
            onClick={() => {
              setShowTimer(true);
              $loadPhonePinCode(phoneId);
            }}
          >
            {t('editForm.generatePin')}
          </EditModalSave>

          {
            showTimer
            && (
            <>
              <PinCode
                active={phonePin != null}
                onClick={() => {
                  copy(phonePin);
                  showNotification(`${t('notification.dataCopiedSuccessfully')}`);
                }}
              >
                {phonePin != null ? phonePin : '---'}
              </PinCode>

              <QrInput data={`iproxy://login?pin=${phonePin}`} />
            </>
            )
          }
        </>
      )}
    </ScrollWrapper>
  );
};

interface PinCodeProps extends UIProps {
  active: boolean;
}

const PinCode = styled.div<PinCodeProps>`
  position: relative;

  display: flex;
  align-items: center;
  width: fit-content;
  margin: 16px 0;

  font-weight: 600;
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};

  cursor: ${(props: PinCodeProps) =>
    (props.active ? 'pointer' : 'none')};

  &:after {
    content: "";

    display: block;
    width: 16px;
    height: 16px;

    background-image: url(${iconCopy});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 12px;
  }
`;
