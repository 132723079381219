import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface AttentionProps {
  children?: any;
}

export const Attention: FunctionComponent<AttentionProps> = (props) => {
  const {
    children,
  } = props;

  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(36, 78, 178, 0.2);
  border-radius: 5px;
  background-color: #face3229;
`;
