import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { removeURLParams } from '../utils';
import store from '../core/store/store';
import { setSystemLanguage } from '../core/store/actions/app';
import {
  Wrapper,
  ContentLayer,
  Text,
  ChangeButton,
  CloseButton,
} from '../styles/system-banners';
import theme from '../styles/ApplicationTheme';

export const SetSystemLanguageBlock = () => {
  const { i18n } = useTranslation();
  const [blockText, setBlockText] = useState({ text: '', button: '' });
  const [systemLang, setSystemLang] = useState(null);
  const [siteLang, setSiteLang] = useState(null);
  const [showBlock, setShowBlock] = useState(null);

  const windowGlobal = typeof window !== 'undefined' && window;

  useEffect(() => {
    setSystemLang(windowGlobal.navigator.language);

    if (systemLang !== null && systemLang.includes('en')) {
      setSystemLang('en');
    }

    const defineBlockText = () => {
      if (Object.keys(i18n.options.resources).includes(systemLang)) {
        switch (systemLang) {
          case 'en':
            return (
              setBlockText({
                text: 'Do you want to switch to the English version of the site?',
                button: 'Switch to English',
              }),
              setSiteLang(systemLang)
            );
          case 'es':
            return (
              setBlockText({
                text: '¿Quiere cambiar a la versión en español del sitio?',
                button: 'Cambiar al Español',
              }),
              setSiteLang(systemLang)
            );
          case 'hi':
            return (
              setBlockText({
                text: 'क्या आप साइट के हिंदी संस्करण में स्विच करना चाहते हैं?',
                button: 'Hindi में बदलें',
              }),
              setSiteLang(systemLang)
            );
          case 'pt':
            return (
              setBlockText({
                text: 'Você quer mudar para a versão em português (Brasil) do site?',
                button: 'Mudar para Português',
              }),
              setSiteLang(systemLang)
            );
          case 'ru':
            return (
              setBlockText({
                text: 'Хотите переключиться на русскоязычную версию сайта?',
                button: 'Изменить на Русский',
              }),
              setSiteLang(systemLang)
            );
          case 'th':
            return (
              setBlockText({
                text: 'คุณต้องการเปลี่ยนเป็นเวอร์ชั่นภาษาไทยของเว็บไซต์หรือไม่?',
                button: 'เปลี่ยนเป็นไทย',
              }),
              setSiteLang(systemLang)
            );
          case 'tr':
            return (
              setBlockText({
                text: 'Siteyi Türkçe sürüme geçmek ister misiniz?',
                button: 'Turkçe olarak değiştir',
              }),
              setSiteLang(systemLang)
            );
          case 'uk':
            return (
              setBlockText({
                text: 'Ви хочете перейти на україномовну версію сайту?',
                button: 'Змінити на Українську',
              }),
              setSiteLang(systemLang)
            );
          case 'vi':
            return (
              setBlockText({
                text: 'Bạn có muốn chuyển sang phiên bản tiếng Việt của trang web không?',
                button: 'Chuyển sang tiếng Việt',
              }),
              setSiteLang(systemLang)
            );
          case 'zh':
            return (
              setBlockText({
                text: '您想切换到网站的中文（简体）版本吗？',
                button: '切换到简体中文',
              }),
              setSiteLang(systemLang)
            );
          default:
        }
      } else {
        return (
          setBlockText({
            text: 'Your system language is not presented on the site',
            button: 'Switch to English',
          }),
          setSiteLang('en')
        );
      }
      return null;
    };

    defineBlockText();

    if (
      !Object.keys(i18n.options.resources).includes(systemLang)
      && i18n.language === 'en'
    ) {
      setShowBlock(false);
    } else if (systemLang !== i18n.language) {
      setShowBlock(true);
    } else {
      setShowBlock(false);
    }
  }, [systemLang, i18n.language]);

  const localeHandler = (lang) => {
    setLanguage(lang);
  };

  const setLanguage = (lang) => {
    if (lang === i18n.language) {
      return null;
    }
    i18n.changeLanguage(lang);

    const search = removeURLParams('lang').split('?')[1];

    const newUrl = search ? `?${search}&lang=${lang}` : `?lang=${lang}`;

    window.history.pushState(null, null, newUrl);

    store.dispatch(setSystemLanguage(lang));

    window.localStorage.setItem('switch-lang-flag', 'switched');

    window.localStorage.setItem('system/locale', i18n.language);

    return null;
  };

  return (
    <>
      {showBlock && (
        <Wrapper background={theme.colors.deepBlue}>
          <ContentLayer>
            <Container>
              <Text>{blockText.text}</Text>

              <ButtonsWrapper>
                <ChangeButton onClick={() =>
                  localeHandler(siteLang)}
                >
                  {blockText.button}
                </ChangeButton>

                <CloseButton
                  onClick={() => {
                    setShowBlock(!showBlock);
                    window.localStorage.setItem('switch-lang-flag', 'switched');
                  }}
                />
              </ButtonsWrapper>
            </Container>
          </ContentLayer>
        </Wrapper>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: start;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
  }
`;
