import React, {
  useState, useEffect, useRef, FunctionComponent,
} from 'react';
import styled, { keyframes } from 'styled-components';

import { UIProps } from '../types';

interface TooltipElementProps {
  children?: any;
  tipContent?: any;
}

export const TooltipElement: FunctionComponent<TooltipElementProps> = (props) => {
  const {
    children,
    tipContent,
  } = props;
  const contentRef = useRef(null);
  const tooltipRef = useRef(null);
  const [tooltipXPosition, setTooltipXPosition] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (tooltipRef.current != null && contentRef.current != null) {
      const val = tooltipRef.current.offsetWidth - contentRef.current.offsetWidth;
      setTooltipXPosition(val < 0 ? Math.abs(val / 2) : val / 2);
    }
  }, [contentRef, tooltipRef, show]);

  return (
    <Wrapper
      onMouseEnter={() =>
        setShow(true)}
      onMouseOut={() =>
        setShow(false)}
    >
      {show ? (<TipContent ref={tooltipRef} positionX={tooltipXPosition}>{tipContent}</TipContent>) : null}
      <Content ref={contentRef}>
        {children}
      </Content>
    </Wrapper>
  );
};

const breatheAnimation = keyframes`
  0%{
    -webkit-transform: scaleX(2);
    transform: scaleX(2);
    filter: blur(4px);
    opacity: 0;
  }
  100%{
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    filter: blur(0);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: max-content;
`;

const Content = styled.div`
  width: max-content;
  cursor: pointer;
`;

interface TipContentProp {
  positionX?: number;
}

const TipContent = styled.div<TipContentProp>`
  position: absolute;
  border-radius: 2px;
  padding: 2px 10px;
  box-sizing: border-box;
  top: 0;
  left: ${(props: TipContentProp) =>
    props.positionX}px;
  transform: translateY(-100%);
  background-color: #1d1d1f;
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
  color: ${(prop: UIProps) =>
    prop.theme.colors.white};

  animation-name: ${breatheAnimation};
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  

  :before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    position: absolute;
    background-color: #1d1d1f;
    transform: rotate(45deg) translateX(-50%);
    bottom: -4px;
    left: 50%;

  }
`;
