import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { debounceLoad } from '../../utils';

interface SearchInputComponentProps {
  label?: string;
  onChange: Function;
}

export const SearchInputComponent: FunctionComponent<SearchInputComponentProps> = (props) => {
  const {
    onChange = () => {},
    label = '',
  } = props;

  const onChangeLocal = ({ target }) => {
    debounceLoad(() => {
      onChange(target.value);
    });
  };

  return (
    <Input
      placeholder={label}
      onChange={onChangeLocal}
    />
  );
};

export const Input = styled.input`
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    height: 56px;
    border: 1px solid ${(props) =>
    props.theme.colors.deepBlue};
    border-radius: 4px;
    outline: none;
    :focus{
      border-color: ${(props) =>
    props.theme.colors.darkBlue};
    }
    ::placeholder {
        color: ${(props) =>
    props.theme.colors.astrall};
    }
    ${(props) =>
    props.theme.sizes.font.medium};
`;
