import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { MenuStructure, UIProps } from '../../types';
import { useModalHook } from '../../hooks';
import { useTypedSelector } from '../../core/store/selectors/type-selector';

import logo from '../../assets/logo_iproxy.svg';
import mobileLogo from '../../assets/logo_iproxy_blue.svg';
import faqIcon from '../../assets/menu_icon/faq.svg';
import supportIcon from '../../assets/menu_icon/support.svg';
import transactionIcon from '../../assets/menu_icon/transactions.svg';
import downloadIcon from '../../assets/menu_icon/download_app.svg';
import refferalIcon from '../../assets/menu_icon/refferal.svg';
import apiIcon from '../../assets/menu_icon/api.svg';
import profileIcon from '../../assets/menu_icon/profile.svg';
import walletIcon from '../../assets/menu_icon/wallet.svg';

import blueWalletIcon from '../../assets/menu_icon/blue_wallet.svg';
import blueApiIcon from '../../assets/menu_icon/blue_api.svg';
import blueProfileIcon from '../../assets/menu_icon/blue_profile.svg';
import blueReferralIcon from '../../assets/menu_icon/blue_referral.svg';
import blueTransactionIcon from '../../assets/menu_icon/blue_transactions.svg';
import blueSupportIcon from '../../assets/menu_icon/blue_telegram.svg';
import blueDownloadIcon from '../../assets/menu_icon/blue_download.svg';
import bluefaqIcon from '../../assets/menu_icon/blue_faq.svg';

import mobileCloseIcon from '../../assets/menu_icon/close_mobile.svg';
import hamburgerIcon from '../../assets/menu_icon/hamburger.svg';

import { analyticsClassnames } from '../../utils/analytics-utils';
import { numberWithSpaces } from '../../utils';
import { getDownloadLink } from '../../utils/instruction-links';

import { LangDropDown } from './LangDropDown';

export function TopMenuComponent(props: any) {
  const modalOptions = useModalHook();
  const { balance } = useTypedSelector(({ user }) =>
    user);

  const { t, i18n } = useTranslation();

  const { isPublic = false, expanded, onExpanded } = props;

  const menu: MenuStructure[] = [
    {
      title: `${t('topMenu.faq')}`,
      link: `https://iproxy.online/${i18n.language}/faq/`,
      newTab: true,
      isExternal: true,
      icon: faqIcon,
      blueIcon: bluefaqIcon,
      key: 'faq-link',
    },
    {
      title: `${t('topMenu.support')}`,
      link: 'https://t.me/iproxy_online_support',
      newTab: true,
      isExternal: true,
      icon: supportIcon,
      blueIcon: blueSupportIcon,
      key: 'support-link',
    },
    {
      title: `${t('topMenu.transaction')}`,
      isExternal: false,
      icon: transactionIcon,
      blueIcon: blueTransactionIcon,
      key: 'transaction-screen',
      onClick: () => {
        modalOptions.show('screen.transactions');
      },
    },

    {
      title: `${t('topMenu.downloadApp')}`,
      isExternal: true,
      newTab: true,
      key: 'download-app-link',
      icon: downloadIcon,
      blueIcon: blueDownloadIcon,
      onClick: () => {
        window.open(getDownloadLink(i18n.language));
      },
    },
    {
      title: `${t('topMenu.referral')}`,
      key: 'referral-screen',
      onClick: () => {
        modalOptions.show('screen.referal');
      },
      isExternal: false,
      icon: refferalIcon,
      blueIcon: blueReferralIcon,
    },
    {
      title: `${t('topMenu.api')}`,
      key: 'api',
      onClick: () => {
        modalOptions.show('screen.api');
      },
      isExternal: false,
      icon: apiIcon,
      blueIcon: blueApiIcon,
    },
    {
      title: `${t('topMenu.profile')}`,
      icon: profileIcon,
      key: 'profile-screen',
      blueIcon: blueProfileIcon,
      onClick: () => {
        modalOptions.show('screen.profile');
      },
    },
    {
      title: `${t('topMenu.balance')}`,
      key: 'balance-screen',
      onClick: () => {
        modalOptions.show('balance.topUp');
      },
      isExternal: false,
      icon: walletIcon,
      blueIcon: blueWalletIcon,
      className: analyticsClassnames.wallet,
    },
  ];

  return (
    <>
      <MobileWrapper>
        <TopWhiteMobilePlaceholder expanded={expanded}>
          {expanded ? (
            <HeaderBalance>
              <HeaderBalanceIcon icon={blueWalletIcon} />
              <span>{`${t('wallet.title')}:`}</span>
              <b>
                {numberWithSpaces(balance)}
                {' '}
                {t('wallet.rub')}
              </b>
            </HeaderBalance>
          ) : (
            <>
              <MobileNamePlaceholder>
                <LogoMobile icon={logo} />
              </MobileNamePlaceholder>
              <LangDropDown direction="bottom" />
            </>
          )}
          <MobileSwitchIconPlaceholder
            expanded={expanded}
            onClick={() =>
              onExpanded(!expanded)}
          >
            <SwitchMobileIcon
              src={expanded ? mobileCloseIcon : hamburgerIcon}
              height="28px"
            />
          </MobileSwitchIconPlaceholder>
        </TopWhiteMobilePlaceholder>

        {expanded === false || isPublic ? null : (
          <MobileExpandedContent>
            <MobileExpandedContentInnerPlaceholder>
              <LogoMobile icon={mobileLogo} />
              <MobileTitle>{t('topMenu.title')}</MobileTitle>
              {menu.map((item: MenuStructure) =>
                (
                  <MobileMenuItem
                    key={`index_mobile_${item.key}`}
                    className={item.className}
                    onClick={() => {
                      if (item?.onClick != null) {
                        onExpanded(false);
                        item?.onClick();
                      }
                    }}
                  >
                    <MenuItemIcon icon={item?.blueIcon} />
                    <MobileLink
                      href={item?.link}
                      target={!item?.newTab ? '_self' : '_blank'}
                      title={item.title}
                    >
                      {item.title}
                    </MobileLink>
                  </MobileMenuItem>
                ))}
              <LangDropDown />
            </MobileExpandedContentInnerPlaceholder>
          </MobileExpandedContent>
        )}
      </MobileWrapper>

      <Wrapper>
        <Logo
          isPublic={isPublic}
          onClick={() => {
            window.location.href = '/';
          }}
        />
        {!isPublic ? (
          <ControlContainer>
            <MenuContainer>
              {menu.map((item: MenuStructure) =>
                (
                  <MenuItem
                    key={item.key}
                    className={item.className}
                    onClick={() => {
                      if (item?.onClick != null) {
                        item?.onClick();
                      }
                    }}
                  >
                    <MenuItemIcon icon={item?.icon} />
                    <Link
                      href={item?.link}
                      target={!item?.newTab ? '_self' : '_blank'}
                      title={item.title}
                    >
                      {item.title}
                    </Link>
                  </MenuItem>
                ))}
            </MenuContainer>
          </ControlContainer>
        ) : null}
      </Wrapper>
    </>
  );
}

const MobileMenuItem = styled.div`
  margin: 0 0 24px -6px;
  padding: 6px;
  line-height: 1 !important;

  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  position: relative;
  box-sizing: content-box;
  display: inline-flex;
  text-align: center;
  align-items: center;
  font-weight: normal;
  cursor: pointer;

  :before {
    content: '';
    position: absolute;
    border-bottom: 2px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    width: 100%;
    bottom: -5px;
    opacity: 0;
    left: 0;
  }

  :hover {
    background-color: #4770d1;
    border-radius: 5px;

    color: ${(props: UIProps) =>
    props.theme.colors.white};

    :before {
      opacity: 1;
    }
  }

  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
`;

const MobileLink = styled.a`
  text-decoration: none;
  display: inline-block;
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  box-sizing: content-box;
  transition: 0.3s;
  font-size: 14px;

  :before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

const MobileExpandedContent = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 3;
  bottom: 0px;
  width: 100%;
  background: #ebf5ff;
`;

const MobileExpandedContentInnerPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 32px 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 720px) {
    display: block;
    max-height: 100vh;
    box-sizing: border-box;
    // overflow-y: auto;
  }
`;

const SwitchMobileIcon = styled.img`
  height: ${(props) =>
    props.height};
  cursor: pointer;
`;

const MobileNamePlaceholder = styled.div`
  display: flex;
  flex: 1 1;
  align-items: center;
  margin-right: 10px;

  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow-x: hidden;

  color: #244eb2;
`;

interface MobileSwitchIconPlaceholderProps {
  expanded?: boolean;
}

const MobileSwitchIconPlaceholder = styled.div<MobileSwitchIconPlaceholderProps>`
  display: flex;
  border-radius: 10px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  margin-left: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    (props.expanded ? 'rgba(36, 78, 178, .04)' : 'transparent')};
`;

interface TopWhiteMobilePlaceholderProps {
  expanded?: boolean;
}

const TopWhiteMobilePlaceholder = styled.div<TopWhiteMobilePlaceholderProps>`
  position: ${(props) =>
    (props.expanded ? 'fixed' : 'static')};
  top: 0;
  left: 0;
  z-index: 3;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  padding: 12px 10px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  box-sizing: border-box;
  background: ${(props) =>
    (props.expanded ? '#fff' : props.theme.colors.deepBlue)};

  -webkit-box-shadow: ${(props) =>
    (props.expanded ? 'none' : '0px 2px 16px -6px #000000')};
  box-shadow: ${(props) =>
    (props.expanded ? 'none' : '0px 2px 16px -6px #000000')};
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 63px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 720px) {
    display: none;
  }
`;

const ControlContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface LogoProps {
  isPublic?: boolean;
}

const Logo = styled.div<LogoProps>`
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  width: 125px;
  height: 70px;
  background-size: contain;
  cursor: pointer;

  ${(props) =>
    !props.isPublic
    && css`
      @media only screen and (max-width: 1250px) {
        display: none;
      }
    `}
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 410px;
  position: relative;
  width: 100%;
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  @media (max-width: 720px) {
    box-sizing: border-box;
    min-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const MenuItem = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  box-sizing: content-box;
  display: inline-flex;
  text-align: center;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  padding: 5px;

  :before {
    content: '';
    position: absolute;
    border-bottom: 2px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    width: 100%;
    bottom: -5px;
    opacity: 0;
    left: 0;
  }

  :hover {
    background-color: #4770d1;
    border-radius: 5px;

    color: ${(props: UIProps) =>
    props.theme.colors.white};

    :before {
      opacity: 1;
    }
  }

  :first-child {
    margin-left: 0;
    @media (max-width: 720px) {
      margin-left: 10px;
    }
  }

  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
`;

interface MenuItemIconProps {
  icon?: string;
}

const MenuItemIcon = styled.div<MenuItemIconProps>`
  width: 16px;
  height: 16px;
  margin: 0 5px 0 0;
  background-image: url(${(props: MenuItemIconProps) =>
    props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: 720px) {
    margin: 0 10px 0 0;
  }
`;

const Link = styled.a`
  text-decoration: none;
  display: inline-block;
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  box-sizing: content-box;
  transition: 0.3s;
  font-size: 12px;
  margin-right: 5px;

  :before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  @media (max-width: 720px) {
    font-size: 10px;
  }
`;

interface IconProps {
  icon?: string;
}

const HeaderBalance = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1;
  font-size: 0.7rem;

  & > span {
    margin-right: 4px;
  }
`;

const HeaderBalanceIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 11px;
  background-image: url(${(props: IconProps) =>
    props.icon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const MobileTitle = styled.h3`
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  font-size: 20px;
  margin: 12px 0 32px;
`;

const LogoMobile = styled.div`
  width: 125px;
  height: 19px;

  background-image: url(${(props: IconProps) =>
    props.icon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
