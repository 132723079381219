import React, {
  useState, useEffect, FunctionComponent,
} from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode';

interface QrInputProps {
  data: any;
}

export const QrInput: FunctionComponent<QrInputProps> = (props) => {
  const {
    data,
  } = props;

  const [qrSource, setQrSource] = useState(null);

  useEffect(() => {
    if (data != null) {
      QRCode.toDataURL(data).then((source) =>
        setQrSource(source));
    }
  }, [data]);

  return (
    <Wrapper imgBase64={qrSource} />
  );
};

interface WrapperProps {
  imgBase64: string;
}

const Wrapper = styled.div<WrapperProps>`
  background: url(${(props:WrapperProps) =>
    props.imgBase64}) no-repeat center center;
  background-size: 185px 185px;
  width: 148px;
  height: 148px;

  @media (max-width: 720px) {
    margin: 0 auto;
  }
`;
