import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { OnboardButton } from '../../screens/OnboardingScreen';
import iconQr from '../../assets/onb_qrcode.png';
import iconGoogle from '../../assets/onb_gplay.png';
import { QrInput } from '../atoms/qrinput';
import { CheckBox } from '../atoms/Checkbox';
import { CommonAppContext } from '../../context/CommonAppContext';
import { TextInProgressAtom } from '../atoms';

interface OnbDownloadFormProps {
  nextScreen?: any;
}

export const OnbDownloadForm: FunctionComponent<OnbDownloadFormProps> = (props) => {
  const { nextScreen } = props;
  const [installed, setInstalled] = useState(false);
  const { t } = useTranslation();
  const context = React.useContext(CommonAppContext);

  const c = [
    {
      icon: iconGoogle,
      title: t('onboarding.download.step1.title'),
      desc: t('onboarding.download.step1.desc'),
      content: (
        <ActButton
          className="application_download"
          onClick={() =>
            window.open('https://play.google.com/store/apps/details?id=com.iproxy.android&pli=1', '_blank')}
        >
          {t('onboarding.download.step1.bt')}
        </ActButton>),
    },
    {
      icon: iconQr,
      title: t('onboarding.download.step2.title'),
      desc: t('onboarding.download.step2.desc'),
      content: (<QrInput data="https://play.google.com/store/apps/details?id=com.iproxy.android&pli=1" />),
    },
    // TODO: restore SMS when twilio approval ready
    // {
    //     icon: iconSms,
    //     title: t('onboarding.install.step3.title'),
    //     desc: t('onboarding.install.step3.desc'),
    //     content: (<ActButton onClick={() => modalOptions.show('smsapp')}>
    //         {t('onboarding.install.step3.bt')}
    //     </ActButton>)
    // }
  ];

  return (
    <Wrapper>
      <h2>{t('onboarding.download.title')}</h2>
      <div className="info">
        {t('onboarding.download.desc')}
      </div>

      <div className="setup-options-list">
        {
                    c?.map((item) =>
                      (
                        <div className="setup-option" key={`bb-${item.title}`}>
                          <div className="icon">
                            <img src={item?.icon} alt="" />
                          </div>
                          <div className="title">{item?.title}</div>
                          <div className="desc">{item?.desc}</div>
                          <div className="content-info">{item?.content}</div>
                        </div>
                      ))
                }
      </div>

      <div className="actions">
        <div className="aggree">
          <CheckBox
            className="checkbutton_app_downloaded"
            disabled={context?.onboarding == null || context?.onboarding?.phone == null}
            value={installed}
            onClick={() => {
              setInstalled(!installed);
              context?.$loadUserOnboardingStatus();
            }}
          />
          <div style={{ marginLeft: '10px' }}>{t('onboarding.download.conf')}</div>
        </div>
        <OnboardButton
          id="bt"
          onClick={() => {
            if (installed) {
              nextScreen();
            }
          }}
          disable={!installed}
        >
          <TextInProgressAtom showProgress={context?.onboarding == null || context?.onboarding?.phone == null}>
            {t('onboarding.download.action')}
          </TextInProgressAtom>
        </OnboardButton>
      </div>
    </Wrapper>
  );
};

export const ActButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background: #fff;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #244EB2;
  border: 1px solid #244EB2;
  padding: 8px 40px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  .aggree{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 23px;

    @media only screen and (max-width: 880px) {
      font-size: 14px;
    }
  }

  .info{

    font-weight: 400;
    font-size: 18px;
    align-items: center;
    text-align: center;
    color: #757575;


  }

  h2{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    align-items: center;
    text-align: center;
    color: #244EB2;

  }

  .actions{
    display: flex;
    margin: auto;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    @media only screen and (max-width: 880px) {
      #bt{
        width: 100%;
      }
    }
  }

  .setup-options-list {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 50px;

    @media only screen and (max-width: 880px) {
      flex-direction: column;
    }
  }

  .setup-option {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px;
    text-align: center;
    justify-content: space-between;
    min-width: 300px;

    .content-info{
      text-align: center;
      margin: auto;
      margin-top: 15px;
    }

    .title{
      margin-top: 10px;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      align-items: center;
      text-align: center;
      color: #000000;
    }

    .desc{
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      margin-top: 18px;
    }


    @media only screen and (max-width: 880px) {
      background-color: #F7F8FA;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 25px 30px;
      margin-bottom: 15px;
      width: 100%;
    }
  }
`;
