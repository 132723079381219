import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BasicButton } from '../../styles/ui-controls';
import warning from '../../assets/warning.svg';
import calendar from '../../assets/timetable-icon.svg';

interface ContainerProps {
  width: string;
  padding: string;
  imgSize: string;
  textAlign?: string;
}

const Container = styled.div<ContainerProps>`
  width: ${(props) =>
    props.width};
  padding: ${(props) =>
    props.padding};

  @media (max-width: 720px) {
    width: 100%;
    padding: 0;
  }

  .skip-block {
    padding: 24px;
    display: flex;
    gap: 40px;
    align-items: center;
    background-color: #ffd6d6;
    border: 1px solid #ff0000;
    border-radius: 4px;

    img {
      width: ${(props) =>
    props.imgSize};
      height: ${(props) =>
    props.imgSize};

      @media (max-width: 720px) {
        display: none;
      }
    }

    .button {
      padding: 22px 74px;
      border-radius: 15px;
    }

    @media (max-width: 720px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .skip-text {
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-size: 20px;
  }

  .recommendation {
    font-weight: 500;
    font-size: 16px;
    text-align: ${(props) =>
    props.textAlign};
  }

  .skip-title {
    font-size: 26px;
  }
`;

export interface ApiWarningBlockProps extends ContainerProps {
  components: JSX.Element;
  isWarning: boolean;
  onClick?: Function;
}

export const ApiWarningBlock: FunctionComponent<ApiWarningBlockProps> = ({
  width,
  padding,
  imgSize,
  textAlign = 'center',
  isWarning,
  components,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Container
      width={width}
      padding={padding}
      imgSize={imgSize}
      textAlign={textAlign}
    >
      <div
        className="skip-block"
      >
        <img src={isWarning ? warning : calendar} alt="" />
        <div>
          {components}
        </div>
        {
          onClick
          && (
          <BasicButton
            className="button"
            color="warning"
            onClick={() =>
              onClick()}
          >
            {t('changePswAndApiKey.skip')}
          </BasicButton>
          )
        }
      </div>
    </Container>
  );
};
