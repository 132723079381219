import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { InputLabel, InputWrapper } from '../styles/ui-controls';
import { InputField } from '../elements';
import { getUrlParameterByName } from '../utils';
import { ButtonLoader } from '../components/atoms';
import { Api } from '../core/api';
import { UIProps } from '../types';
import { landingPath } from '../constants';

interface RecoverScreenProps {}

export const RecoverScreen: FunctionComponent<RecoverScreenProps> = () => {
  const { t } = useTranslation();
  const [recoveryData, setRecoveryData] = useState(null);
  const [password, setPassword] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      Api.get(`/recover/${getUrlParameterByName('tr')}`).then(({ data }) => {
        setToken(getUrlParameterByName('tr'));
        setRecoveryData(data?.hasOwnProperty('error') ? null : data);
      });
    }, 100);
  }, []);

  const $updatePassword = () => {
    Api.post(`/recover/${token}`, {
      password,
    }).then(() => {
      window.localStorage.clear();
      window.location.href = landingPath;
    });
  };

  return (
    <Wrapper>
      <Title>{t('profile.setNewPasswordForm')}</Title>
      {recoveryData != null ? (
        <>
          <InputWrapper>
            <InputLabel>{t('profile.newPsw')}</InputLabel>
            <InputField
              value={password}
              floatLabel
              onChange={({ target }) =>
                setPassword(target?.value)}
            />
          </InputWrapper>
          <ButtonLoader
            onClick={() => {
              if (password != null) {
                $updatePassword();
              }
            }}
          >
            {t('profile.changePsw')}
          </ButtonLoader>
        </>
      ) : (
        <Notoken>{t('profile.recoverTokenInvalid')}</Notoken>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  ${(props: UIProps) =>
    props.theme.sizes.font.large};
  margin-bottom: 15px;
`;

const Notoken = styled.div`
  background-color: ${(props: UIProps) =>
    props.theme.colors.lightGray};
  padding: 5px;
`;
