import { createReducer } from 'typesafe-actions';

import { ServersStore } from '../../../types';
import { loadServersList } from '../actions/servers';

const initialAppState: ServersStore = {
  data: null,
};

const serversReducer = createReducer(initialAppState)
  .handleAction(loadServersList.request, (state: ServersStore) =>
    ({ ...state, loading: true }))
  .handleAction(loadServersList.success, (state: ServersStore, { payload }) =>
    ({ ...state, loading: false, data: payload }))
  .handleAction(loadServersList.failure, (state: ServersStore) =>
    ({ ...state, loading: false }));

export default serversReducer;
export type ServersState = ReturnType<typeof serversReducer>;
