export const dateFormats = {
  dateTime: 'DD.MM.YYYY HH:mm',
  dateTimeSec: 'DD.MM.YYYY HH:mm:ss',
  dateGmt: 'DD.MM.YYYY, HH:mm [GMT]Z',
  shortDate: 'DD.MM.YYYY',
  monthYear: 'MMMM YYYY',
  day: 'D',
  timeSec: 'HH:mm:ss',
  time: 'HH:mm',
  mins: 'mm',
  fileDate: 'DD-MM-YYYY-HH-mm',
  fileDateAlt: 'DD.MM.YYYY_HH_MM_ss',
  longDateTime: 'MMMM D, YYYY HH:mm',
  longDateWithDay: 'ddd, MMMM D, YYYY HH:mm',
};

export const alertMessageUrl = 'https://new-cms.iproxy.online/api/alert-message';
