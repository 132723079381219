import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

interface LabelCounterProps {
  isActive?: boolean;
  title?: string;
  bubbleText?: string;
}

export const LabelCounter: FunctionComponent<LabelCounterProps> = (props) => {
  const {
    isActive = false,
    title,
    bubbleText,
  } = props;
  return (
    <Wrapper active={isActive}>
      {title}
      {
                isActive ? <div className="bubble">{bubbleText}</div> : null
            }
    </Wrapper>
  );
};

interface WrapperProps {
  active?: boolean;
  ss?:any;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: fit-content;
  display: flex;
  z-index: 2;
  align-items: center;
  color: ${(props: WrapperProps) =>
    (props.active ? 'white' : '#6198DE')};
  font-weight:  ${(props: WrapperProps) =>
    (props.active ? '500' : '400')};
  
  
  
  .bubble{
    color: #2C75D3;
    font-weight: 600;
    font-size: 12px;
    background-color: white;
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: 32px;
    height: 24px;
    border-radius: 100px;
    text-align: center;
    top: 0;
    right: -40px;
  }
  
  
  @media only screen and (max-width: 880px) {}
  
  ${(props: WrapperProps) =>
    props.active && css`
    :after{
     display: none !important;
    }
  `}
`;
