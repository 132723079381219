import React from 'react';
import { UpdateAppConfirmation } from './components/modals';

const TopUpForm = React.lazy(() =>
  import('./components/common/top-up-form').then((module) =>
    ({
      default: module.TopUpForm,
    })));
const MultiChangeRotation = React.lazy(() =>
  import('./components/forms/mass actions/multi-change-rotation').then(
    (module) =>
      ({
        default: module.MultiChangeRotation,
      }),
  ));
const MultiPayments = React.lazy(() =>
  import('./components/forms/mass actions/multi-payments-connections').then(
    (module) =>
      ({
        default: module.MultiPayments,
      }),
  ));
const MultiRemove = React.lazy(() =>
  import('./components/forms/mass actions/multi-remove-connections').then(
    (module) =>
      ({
        default: module.MultiRemove,
      }),
  ));
const MultiSetupDNS = React.lazy(() =>
  import('./components/forms/mass actions/multi-setupDNS-connections').then(
    (module) =>
      ({
        default: module.MultiSetupDNS,
      }),
  ));
const MultiSetupOvpnDNS = React.lazy(() =>
  import('./components/forms/mass actions/multi-setupOvpnDNS-connections').then(
    (module) =>
      ({
        default: module.MultiSetupOvpnDNS,
      }),
  ));
const MultiSetupRebootInterval = React.lazy(() =>
  import(
    './components/forms/mass actions/multi-setupRebootInterval-connections'
  ).then((module) =>
    ({
      default: module.MultiSetupRebootInterval,
    })));
const MultiRename = React.lazy(() =>
  import('./components/forms/mass actions/multi-rename-connections').then(
    (module) =>
      ({
        default: module.MultiRename,
      }),
  ));
const MultiChangeIP = React.lazy(() =>
  import('./components/forms/mass actions/multi-change-ip').then((module) =>
    ({
      default: module.MultiChangeIP,
    })));
const MultiRebootDevice = React.lazy(() =>
  import('./components/forms/mass actions/multi-reboot-device').then(
    (module) =>
      ({
        default: module.MultiRebootDevice,
      }),
  ));
const MultiDownloadLogs = React.lazy(() =>
  import('./components/forms/mass actions/multi-download-logs').then(
    (module) =>
      ({
        default: module.MultiDownloadLogs,
      }),
  ));
const MultiAddGroup = React.lazy(() =>
  import('./components/forms/mass actions/multi-add-group').then((module) =>
    ({
      default: module.MultiAddGroup,
    })));
const APIScreen = React.lazy(() =>
  import('./screens/APIScreen').then((module) =>
    ({
      default: module.APIScreen,
    })));
const UserProfileScreen = React.lazy(() =>
  import('./screens/UserProfileScreen').then((module) =>
    ({
      default: module.UserProfileScreen,
    })));
const TransactionScreen = React.lazy(() =>
  import('./screens/TransactionScreen').then((module) =>
    ({
      default: module.TransactionScreen,
    })));
const UpcomingChargesScreen = React.lazy(() =>
  import('./screens/UpcomingChargesScreen').then((module) =>
    ({
      default: module.UpcomingChargesScreen,
    })));
const ReferralScreen = React.lazy(() =>
  import('./screens/ReferralScreen').then((module) =>
    ({
      default: module.ReferralScreen,
    })));
const AddConnectionForm = React.lazy(() =>
  import('./components/forms').then((module) =>
    ({
      default: module.AddConnectionForm,
    })));
const AddConnectionGroupForm = React.lazy(() =>
  import('./components/forms').then((module) =>
    ({
      default: module.AddConnectionGroupForm,
    })));
const AddPhoneForm = React.lazy(() =>
  import('./components/forms').then((module) =>
    ({
      default: module.AddPhoneForm,
    })));
const ChangeConnectionNameForm = React.lazy(() =>
  import('./components/forms').then((module) =>
    ({
      default: module.ChangeConnectionNameForm,
    })));
const DashboardSettingsForm = React.lazy(() =>
  import('./components/forms').then((module) =>
    ({
      default: module.DashboardSettingsForm,
    })));
const MultiEditForm = React.lazy(() =>
  import('./components/forms').then((module) =>
    ({
      default: module.MultiEditForm,
    })));
const PhoneEditForm = React.lazy(() =>
  import('./components/forms').then((module) =>
    ({
      default: module.PhoneEditForm,
    })));
const DownloadSmsLink = React.lazy(() =>
  import('./components/modals/download-sms-link').then((module) =>
    ({
      default: module.DownloadSmsLink,
    })));
const TariffForm = React.lazy(() =>
  import('./components/forms').then((module) =>
    ({
      default: module.TariffForm,
    })));
const DownloadCredentials = React.lazy(() =>
  import('./components/forms').then((module) =>
    ({
      default: module.DownloadCredentials,
    })));
const MultiTeamControl = React.lazy(() =>
  import('./components/forms/mass actions/multi-team-control').then(
    (module) =>
      ({
        default: module.MultiTeamControl,
      }),
  ));

export type ModalStackType =
  | 'demo'
  | 'connection.add'
  | 'connection.rename'
  | 'connection.group'
  | 'phone.tariff'
  | 'phone.edit'
  | 'balance.topUp'
  | 'phone.new'
  | 'screen.referal'
  | 'screen.news'
  | 'screen.upcomingCharges'
  | 'dashboard.settings'
  | 'screen.profile'
  | 'multi-group'
  | 'multi-rotation'
  | 'multi-payments'
  | 'multi-remove'
  | 'multi-rename'
  | 'multi-changeIP'
  | 'multi-downloadLogs'
  | 'multi-setupDNS'
  | 'multi-setupOvpnDNS'
  | 'multi-setupRebootInterval'
  | 'multi-rebootDevice'
  | 'screen.transactions'
  | 'phone.update.app'
  | 'multiedit'
  | 'smsapp'
  | 'screen.api'
  | 'multi-teamControl'
  | 'download-credentials';

export interface ConfirmationModalParams {
  onAccept?: Function;
  onDecline?: Function;
  acceptLabel?: string;
  declineLabel?: string;
  data?: any;
}

export interface BaseModalFormParams {
  modalParams?: any;
  initConfirmation?: (component, params?: ConfirmationModalParams) => {};
  _modalOptions?: any;
}

const MODAL_STACK = {
  demo: <div>2323</div>,
  'connection.add': <AddConnectionForm />,
  'connection.rename': <ChangeConnectionNameForm />,
  'connection.group': <AddConnectionGroupForm />,
  'balance.topUp': <TopUpForm />,
  'phone.tariff': <TariffForm />,
  'phone.edit': <PhoneEditForm />,
  'phone.update.app': <UpdateAppConfirmation />,
  'phone.new': <AddPhoneForm />,
  multiedit: <MultiEditForm />,
  'screen.upcomingCharges': <UpcomingChargesScreen />,
  'multi-group': <MultiAddGroup />,
  'multi-rotation': <MultiChangeRotation />,
  'multi-payments': <MultiPayments />,
  'multi-remove': <MultiRemove />,
  'multi-setupDNS': <MultiSetupDNS />,
  'multi-setupOvpnDNS': <MultiSetupOvpnDNS />,
  'multi-setupRebootInterval': <MultiSetupRebootInterval />,
  'multi-rename': <MultiRename />,
  'multi-changeIP': <MultiChangeIP />,
  'multi-rebootDevice': <MultiRebootDevice />,
  'multi-downloadLogs': <MultiDownloadLogs />,
  'screen.referal': <ReferralScreen />,
  'screen.transactions': <TransactionScreen />,
  'screen.profile': <UserProfileScreen />,
  'screen.api': <APIScreen />,
  'dashboard.settings': <DashboardSettingsForm />,
  'multi-teamControl': <MultiTeamControl />,
  'download-credentials': <DownloadCredentials />,
  smsapp: <DownloadSmsLink />,
};

export default MODAL_STACK;
