import React, { useState, FunctionComponent, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { OnboardButton } from '../../screens/OnboardingScreen';
import { UIProps } from '../../types';
import iconCopy from '../../assets/coppy_icon.svg';
import { showNotification } from '../../utils';
import { Api } from '../../core/api';
import { CommonAppContext } from '../../context/CommonAppContext';

interface OnbFinalFormProps {
  onboadingData?: any;
  nextScreen?: Function;
}

export const OnbFinalForm: FunctionComponent<OnbFinalFormProps> = (props) => {
  const {
    onboadingData,
    nextScreen = () => {},
  } = props;

  const [mode, setMode] = useState('http');
  const { t } = useTranslation();
  const context = React.useContext(CommonAppContext);

  const proxy = useMemo(() => {
    if (onboadingData == null) return null;
    return onboadingData?.proxies?.find((proxy: any) =>
      proxy.listen_service === mode);
  }, [mode]);

  const changeIPUrl = `https://i.fxdx.in/api-rt/changeip/${onboadingData?.phone?.id}/${onboadingData?.phone?.changeIPKeys[0]}`;

  const $finish = async () => {
    try {
      await Api.post('/onboarding/finish').then(() => {
        context.setOnboarding({ isOnboarding: false });
      });
    } catch (e) {
      console.error(':>Error: ', e);
    }
  };

  return (
    <Wrapper>

      <h2>{t('onboarding.access.title')}</h2>

      <div className="info">
        {t('onboarding.access.desc1')}
      </div>

      <div className="info">
        {t('onboarding.access.desc2')}
      </div>

      <div className="connection-access">
        <SwitcherContainer>
          <Switch
            active={mode === 'http'}
            onClick={() =>
              setMode('http')}
          >
            http
          </Switch>
          <Switch
            active={mode === 'socks5'}
            onClick={() =>
              setMode('socks5')}
          >
            socks5
          </Switch>
        </SwitcherContainer>

        <div className="cred-block">
          <ConnectionContainer>
            <ItemContent style={{ width: '180px' }} content={`${t('proxy.type')}`}>
              <div className="overflow">{mode}</div>
            </ItemContent>
            <ItemContent style={{ width: '370px' }} content={`${t('proxy.ip')}`}>
              <div className="overflow">{proxy?.hostname || '-'}</div>
            </ItemContent>
            <ItemContent style={{ width: '220px' }} content={`${t('proxy.port')}`}>
              <div className="overflow">{proxy?.port || '-'}</div>
            </ItemContent>
            <ItemContent style={{ width: '420px' }} content={`${t('profile.login')}`}>
              <div className="overflow">{proxy?.login || '-'}</div>
            </ItemContent>
            <ItemContent style={{ width: '500px' }} content={`${t('profile.password')}`}>
              <div className="overflow">{proxy?.password || '-'}</div>
            </ItemContent>

            <MobileCredView onClick={() => {
              showNotification(`${t('notification.dataCopiedSuccessfully')}`);
              copy(`${mode}:${proxy?.hostname}:${proxy?.port}:${proxy?.login}:${proxy?.password}`);
            }}
            >
              <div className="item">
                <div className="label">
                  {t('proxy.type')}
                  :
                </div>
                <div>{mode}</div>
              </div>

              <div className="item">
                <div className="label">
                  {t('proxy.ip')}
                  :
                </div>
                <div>{proxy?.hostname || '-'}</div>
              </div>

              <div className="item">
                <div className="label">
                  {t('proxy.port')}
                  :
                </div>
                <div>{proxy?.port || '-'}</div>
              </div>

              <div className="item">
                <div className="label">
                  {t('profile.login')}
                  :
                </div>
                <div>{proxy?.login || '-'}</div>
              </div>

              <div className="item">
                <div className="label">
                  {t('profile.password')}
                  :
                </div>
                <div>{proxy?.password || '-'}</div>
              </div>
              <div />
            </MobileCredView>
          </ConnectionContainer>
          <div className="cop-con">
            <ConnectionsCredCopy onClick={() => {
              showNotification(`${t('notification.dataCopiedSuccessfully')}`);
              copy(`${mode}:${proxy?.hostname}:${proxy?.port}:${proxy?.login}:${proxy?.password}`);
            }}
            />
          </div>
        </div>
      </div>

      <div className="ip-change">
        <div className="info">
          {t('onboarding.access.ipchange')}
        </div>
        <div className="ip-link">
          <div
            className="link"
            onClick={() => {
              showNotification(`${t('notification.dataCopiedSuccessfully')}`);
              copy(changeIPUrl);
            }}
          >
            {changeIPUrl}
          </div>
          <ConnectionsCredCopy onClick={() => {
            showNotification(`${t('notification.dataCopiedSuccessfully')}`);
            copy(changeIPUrl);
          }}
          />
        </div>
      </div>

      <div className="actions-finish">
        <OnboardButton
          id="bt"
          className="get_iproxi_account_information"
          color="green"
          onClick={() => {
            $finish();
            nextScreen();
          }}
        >
          {t('onboarding.access.action')}
        </OnboardButton>
      </div>
    </Wrapper>
  );
};

const MobileCredView = styled.div`
  display: none;
  cursor: pointer;
  padding: 10px;
  :active, :focus, :hover{
    opacity: 0.8;
  }
  @media only screen and (max-width: 880px) {
    display: block;
  }

  .item {
    display: flex;
    margin-bottom: 5px;
  }

  .label {
    margin-right: 5px;
    font-weight: bold;
  }
`;

const ConnectionContainer = styled.div`
  display: flex;
  margin-top: 25px;
  width: 100%;
  max-width: 800px;
  margin-right: 20px;
  box-sizing: border-box;

  @media only screen and (max-width: 880px) {
    flex-direction: column;
    margin-top: 0;
    padding: 10px;
  }
`;

interface ItemContentProps extends UIProps {
  content: string;
  isEdit?: boolean;
}
const ItemContent = styled.div<ItemContentProps>`
  border: 1px solid #244EB2;
  color: ${(props: ItemContentProps) =>
    (props?.isEdit ? props.theme.colors.greenMud : props.theme.colors.dark)};
  cursor: ${(props: ItemContentProps) =>
    (props?.isEdit ? 'pointer' : 'auto')};
  height: 40px;
  font-size: 14px;

  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 22px 10px;
  font-weight: normal;
  line-height: 15px;
  border-left: none;
  position: relative;
  width: 100%;

  .overflow{
    overflow: hidden;
    width: 100%;
    display: flex;
  }

  :first-child {
    border-left: 1px solid #244EB2;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  :last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  :before {
    content: ${(props: ItemContentProps) =>
    `"${props.content}"`};
    position: absolute;
    color: ${(props: UIProps) =>
    props.theme.colors.dark};
    font-size: 12px;
    line-height: 15px;
    top: -20px;
    z-index: 2;
    left: 5px;
  }


  @media only screen and (max-width: 880px) {
    display: none;
  }
`;

const ConnectionsCredCopy = styled.div`
  width: 15px;
  height: 15px;
  cursor: pointer;
  text-transform: lowercase;
  background-image: url(${iconCopy});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: 880px) {
    display: none;
  }
`;

const SwitcherContainer = styled.div`
  background: #F5F8FF;
  border-radius: 5px;
  display: flex;
  width: 200px;
  box-sizing: border-box;
  padding: 4px;
`;

interface SwitchProps {
  active?: boolean;
}

const Switch = styled.div<SwitchProps>`;
  ${(props: SwitchProps) => {
    if (props.active) {
      return css`
            box-shadow: 0px 0px 4px #D2DDF9;
            background: #FFFFFF;
            color: #244EB2;
          `;
    }
    return '';
  }}
  border-radius: 5px;
  width: 100%;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;

  .cop-con{
    display: flex;
    align-items: center;
    @media only screen and (max-width: 880px) {
      display: none;
    }
  }

  .cred-block{
    display: flex;
    align-items: baseline;
    margin-top: 20px;

    @media only screen and (max-width: 880px) {
      box-sizing: border-box;
      width: 100%;
      border-radius: 5px;
      background-color: #F7F8FA;
    }
  }

.connection-access{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  @media only screen and (max-width: 880px) {
    box-sizing: border-box;
    width: 100%;
  }
}

  .video-guide{
    margin-top: 15px;
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    color: #244EB2;
  }

  .actions-finish{
    margin-top: 40px;

    @media only screen and (max-width: 880px) {
      width: 100%;
      #bt{
        width: 100%;
      }
    }
  }

  .ip-change{
    margin-top: 40px;

    .info{
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }

    .ip-link{
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #244EB2;
      margin-top: 15px;

      .link{
        margin-right: 15px;

        @media only screen and (max-width: 880px) {
          margin-right: 0;
          width: 90%;
          margin: auto;
          text-align: center;
        }
      }

    }
  }

  .actions{
    margin-top: 30px;
  }

  .icon {
    margin-bottom: 20px;
  }

  h2{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #244EB2;
  }

  .info {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 18px;
    color: #757575;
  }
`;
