import styled from 'styled-components';
import { UIProps } from '../../../types';
import iconLink from '../../../assets/link_icon.svg';

export const FormContainer = styled.div`
  @media (max-width: 720px) {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .title {
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.largeBold};
    margin-bottom: 12px;
    font-weight: 600;
    @media (max-width: 720px) {
      font-size: 12px;
    }
  }

  .info {
    display: flex;
    align-items: center;
    @media (max-width: 720px) {
      font-size: 12px;
    }
  }

  .description {
    font-weight: 500;
    ${(prop: UIProps) =>
    prop.theme.sizes.font.medium};
    @media (max-width: 720px) {
      font-size: 12px;
    }
  }

  .helper {
    position: relative;
    ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    display: flex;
    cursor: pointer;
    font-weight: 600;
    margin-top: -28px;
    margin-left: 21px;

    :before {
      margin-right: 5px;
      content: '';
      display: block;
      width: 28px;
      height: 12px;
      background-image: url(${iconLink});
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 720px) {
        width: 28px;
        height: 12px;
      }
    }
  }
`;

export const CheckBoxWrap = styled.div`
  margin-bottom: 10px;
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
  }
`;
