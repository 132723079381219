import React, { FunctionComponent, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UIProps } from '../../types';

interface ConfirmFormProps {
  text: string;
  approveText?: string;
  cancelText?: string;
  hasScrollTo?: boolean;
  onApprove: () => void;
  onCancel: () => void;
}

export const ConfirmForm: FunctionComponent<ConfirmFormProps> = (
  props,
) => {
  const {
    text, approveText, cancelText, hasScrollTo = false, onApprove, onCancel,
  } = props;

  const { t } = useTranslation();
  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current && hasScrollTo) {
      ref.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [hasScrollTo, ref.current]);

  return (
    <Form>
      <div ref={ref} className="content">
        <div className="information">{text}</div>
        <div className="options">
          <div
            className="action approve"
            onClick={onApprove}
          >
            {approveText || t('editForm.confirmRemoval')}
          </div>
          <div
            className="action decline"
            onClick={onCancel}
          >
            {cancelText || t('editForm.cancelRemoval')}
          </div>
        </div>
      </div>
    </Form>
  );
};

const Form = styled.div`
    width: 100%;
    padding: 16px;
    border: 1px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    border-radius: 5px;

    .content {
        ${(prop: UIProps) =>
    prop.theme.sizes.font.medium};
        text-align: center;

        .information {
            color: ${(props: UIProps) =>
    props?.theme?.colors?.dark};
            margin-bottom: 16px;
        }

        .options {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .action {
            width: 50%;
            text-align: center;
            ${(prop: UIProps) =>
    prop.theme.sizes.font.medium};
            cursor: pointer;
        }

        .approve {
            color: ${(props: UIProps) =>
    props?.theme?.colors?.warning};
        }

        .decline {
            color: ${(props: UIProps) =>
    props?.theme?.colors?.deepBlue};
        }
    }
`;
