import styled from 'styled-components';
import { UIProps } from '../../types';

export const ModalTitle = styled.div`
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smallBold};
    margin-bottom: 16px;

    @media (max-width: 720px) {
      font-size: 16px;
    }
`;
