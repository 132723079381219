import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UIProps } from '../../types';
import { setSystemLanguage } from '../../core/store/actions/app';
import store from '../../core/store/store';

import arrowExpand from '../../assets/arrow_expand.svg';
import { removeURLParams } from '../../utils';

export function LangDropDown({ direction = 'top' }: any) {
  const { i18n } = useTranslation();
  const [isVisibleLangs, setVisibleLangs] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>();

  const locales = {
    en: 'English',
    ru: 'Русский',
    tr: 'Türkçe',
    th: 'ไทย',
    uk: 'Українська',
    ua: 'Українська',
    vi: 'Tiếng Việt',
    pt: 'Português',
    es: 'Español',
    hi: 'हिंदी',
    zh: '中文',
  };

  const setLanguage = (lang) => {
    if (lang === i18n.language) {
      return null;
    }
    i18n.changeLanguage(lang);

    window.localStorage.setItem('system/locale', i18n.language);

    const search = removeURLParams('lang').split('?')[1];

    const newUrl = search ? `?${search}&lang=${lang}` : `?lang=${lang}`;

    window.history.pushState(null, null, newUrl);

    store.dispatch(setSystemLanguage(lang));
    setVisibleLangs(false);
    return null;
  };

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        isVisibleLangs
        && wrapperRef
        && wrapperRef.current
        && !wrapperRef.current.contains(e.target as HTMLElement)
      ) {
        setVisibleLangs(false);
      }
    },
    [isVisibleLangs, wrapperRef],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () =>
      document.removeEventListener('mousedown', handleClickOutside);
  }, [isVisibleLangs, handleClickOutside]);

  return (
    <LangContainer ref={wrapperRef}>
      <LangList visible={isVisibleLangs} direction={direction}>
        {Object.keys(i18n.options.resources).map((lang) =>
          (
            <LangItem
              key={lang}
              active={i18n.language === lang}
              onClick={() =>
                setLanguage(lang)}
              style={lang === 'zh' || lang === 'th' || lang === 'hi' ? { opacity: 0.5 } : null}
            >
              {locales[lang]}
            </LangItem>
          ))}
      </LangList>
      <CurrentLang onClick={() =>
        setVisibleLangs(!isVisibleLangs)}
      >
        {i18n.language.toUpperCase()}
        <ArrowIcon icon={arrowExpand} visible={isVisibleLangs} />
      </CurrentLang>
    </LangContainer>
  );
}

const LangContainer = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  z-index: 1;

  width: 150px;
  height: 40px;
  padding: 5px 15px;
  box-sizing: border-box;

  background: #fff;
  border-radius: 10px;
`;

const CurrentLang = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;

  cursor: pointer;
`;

interface ArrowIconProps {
  icon?: string;
  visible?: boolean;
}

const ArrowIcon = styled.div<ArrowIconProps>`
  width: 16px;
  height: 16px;
  background-image: url(${(props: ArrowIconProps) =>
    props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: ${(props: ArrowIconProps) =>
    (props.visible ? 'rotate(180deg)' : 'rotate(0)')};
`;

interface LangListProps {
  visible?: boolean;
  direction: string;
}

const LangList = styled.ul<LangListProps>`
  position: absolute;
  ${(props) =>
    (props.direction === 'bottom' ? 'top: 44px' : 'bottom: 44px')};
  left: 0;

  display: ${(props) =>
    (props.visible ? 'block' : 'none')};
  width: 150px;
  padding: 5px 1px;
  margin: 0;
  list-style: none;
  box-sizing: border-box;

  background: #fff;
  border-radius: 10px;
`;

interface LangItemProps {
  active?: boolean;
}

const LangItem = styled.li<LangItemProps>`
  padding: 5px 15px;
  text-align: left;
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  background-color: ${(props) =>
    (props.active ? '#EBF5FF' : 'transparent')};
  font-weight: ${(props) =>
    (props.active ? 700 : 300)};

  cursor: pointer;
`;
