import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';

import { UIProps } from '../../types';

interface WarningLineProps {
  status?: boolean;
  onClose?: Function;
  style?: string;
  children?: any;

}
export const InlineWarning: FunctionComponent<WarningLineProps> = (props) => {
  const {
    status = true,
    onClose = () => {},
    style = null,
    children,
  } = props;
  const [active, setActive] = useState(status);

  const onClick = () => {
    setActive(false);
    onClose();
  };
  return active ? (
    <Warning innerStyle={style}>
      <ContentBlock>
        <Content>{children}</Content>
        <Close onClick={() =>
          onClick()}
        />
      </ContentBlock>
    </Warning>
  ) : null;
};

const ContentBlock = styled.div`
     margin: auto;
     max-width: 1100px;
     box-sizing: border-box;
     width: auto;
     padding: 10px 5px;
     position: relative;
     display: flex;
     align-items: center;
`;

const Close = styled.div`
  width: 15px;
  height: 15px;
  background-color: red;
  position: absolute;
  right: 0;
  cursor: pointer;
`;

const Content = styled.div`
  
`;

interface WarningProps extends UIProps {
  innerStyle?:string;
}

const Warning = styled.div<WarningProps>`
  position: relative;
  border-bottom: 1px solid ${(prop: UIProps) =>
    prop.theme.colors.warning};
  border-left: 1px solid ${(prop: UIProps) =>
    prop.theme.colors.warning};
  border-right: 1px solid ${(prop: UIProps) =>
    prop.theme.colors.warning};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
  padding: 10px;
  color: ${(prop: UIProps) =>
    prop.theme.colors.warning};
  
  :first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid ${(prop: UIProps) =>
    prop.theme.colors.warning};

  }
  
  :last-child {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  ${(prop: WarningProps) =>
    prop.innerStyle};
`;
