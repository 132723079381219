import React, { useState, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { UIProps } from '../../types';

export interface TabsAtomConfig {
  key?: string;
  title: string;
  component: any;
}

interface TabsAtomProps {
  config: TabsAtomConfig[];
  initTab?: number;
  onTabChange?: Function;
  getActiveTab?: Function;
  handleTabWithWarning?: Function;
  dailyTab?: number;
}

export const TabsAtom: FunctionComponent<TabsAtomProps> = (props) => {
  const {
    initTab = 0,
    onTabChange = () => {},
    config = [],
    getActiveTab = () => {},
    handleTabWithWarning = () => {},
    dailyTab = 0,
  } = props;

  const [activeTab, setActiveTab] = useState(initTab);

  const handleActiveTab = (tab, key) => {
    getActiveTab(tab);
    handleTabWithWarning(key);
  };

  useEffect(() => {
    setActiveTab(dailyTab);
  }, [dailyTab]);

  return (
    <Wrapper>
      <TabContainer>
        {config?.map((item, i) =>
          (
            <Tab
              key={item?.key}
              isActive={i === activeTab}
              onClick={() => {
                setActiveTab(i);
                onTabChange(config[i]);
                handleActiveTab(i, item?.key);
              }}
            >
              {item?.title}
            </Tab>
          ))}
      </TabContainer>
      <Content>
        {config[activeTab].component}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabContainer = styled.div`
    display: flex;
    margin: 0 0 20px;
    border-bottom: 1px solid ${(props:UIProps) =>
    props.theme.colors.spaceGray};;

    @media(max-width: 720px) {
        flex-wrap: wrap;
        margin: 0 -4px 20px;
        border-bottom: 0;
    }
`;

interface TabProps extends UIProps {
  isActive?: boolean;
}

const Tab = styled.div<TabProps>`
    box-sizing: border-box;
    margin-right: 15px;
    border-bottom: 2px solid ${(props:TabProps) =>
    (props?.isActive ? props.theme.colors.deepBlue : 'rgba(0,0,0,0)')};

    transition: .2s;
    cursor: pointer;

    &:hover {
        border-bottom: 2px solid ${(props:TabProps) =>
    props.theme.colors.deepBlue};
    }

    @media(max-width: 720px) {
        margin: 4px;
        padding: 4px 12px;

        border-bottom: 0;
        border-radius: 10px;
        background-color: ${(props:TabProps) =>
    (props?.isActive ? props.theme.colors.deepBlue : '#F5F8FF')};
        color: ${(props:TabProps) =>
    (props?.isActive ? '#FFFFFF' : props.theme.colors.deepBlue)};

        font-size: 0.8rem;
    }
`;

const Content = styled.div`

`;
