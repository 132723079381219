import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import store from '../core/store/store';
import { BasicButton, WrapperScreen } from '../styles/ui-controls';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { updateApiKey } from '../core/store/actions/user';
import { ScreenInfo, InfoBlock } from './styles';

import { ApiWarningBlock } from '../components/common/ApiWarningBlock';
import { ApiKeyInput } from '../components/common/ApiKeyInput';

interface APIScreenProps {
  onUpdate?: Function;
  noFullHeight?: boolean;
}

export function APIScreen({
  onUpdate,
  noFullHeight,
}: APIScreenProps) {
  const { api_key: apiKey } = useTypedSelector(({ user }) =>
    user.profile);
  const { t } = useTranslation();

  return (
    <WrapperScreen noFullHeight={noFullHeight}>
      <ScreenInfo
        marginBottom="25px"
        height="32px"
        justifyContent
        alignItems
        width
        flex
      >
        <div className="title">{t('api.title')}</div>
      </ScreenInfo>
      <Content>
        <ApiKeyInput
          apiKey={apiKey || '-'}
          marginBottom="12px"
        />
        <ApiWarningBlock
          components={(
            <div
              className="recommendation"
              dangerouslySetInnerHTML={{
                __html: t('api.secure'),
              }}
            />
          )}
          width="100%"
          padding="0"
          imgSize="50px"
          textAlign="left"
          isWarning
        />
        <InfoBlock>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: t('api.description'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('api.text', {
                support: `<span style='font-weight: 500'>${t('api.support')}</span>`,
                link: `
                  <a style='font-weight: 500' href="https://t.me/iproxy_online_support" target='_blank'>
                    @iproxy_online_support
                  </a>`,
              }),
            }}
          />
        </InfoBlock>
        <Controls>
          <BasicButton
            onClick={() => {
              store.dispatch(updateApiKey.request(null));
              if (onUpdate) onUpdate();
            }}
            size="medium"
          >
            {t('api.updateApiKey')}
          </BasicButton>
        </Controls>
      </Content>
    </WrapperScreen>
  );
}

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;

  .container {
    margin-top: 10px;
  }

  .skip-block {
    padding: 16px;
    display: flex;
    gap: 20px;
    align-items: center;
    background-color: #ffd6d6;
    border: 1px solid #ff0000;
    border-radius: 4px;
    max-width: 620px;

    img {
      width: 50px;
      height: 50px;
      @media (max-width: 720px) {
        display: none;
      }
    }

    @media (max-width: 720px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .skip-title {
    font-size: 14px;
    font-weight: 600;
    white-space: pre-wrap;
  }
`;
