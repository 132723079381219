import { Epic } from 'redux-observable';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';
import {
  catchError, debounce, filter, switchMap, withLatestFrom, tap,
} from 'rxjs/operators';
import {
  concat, from, interval, of,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { isEqual } from 'lodash';

import moment from 'moment';

import {
  addNewConnection,
  changePhoneIpAddress,
  createNewConnection,
  getPhonePinCode,
  getPhoneRealInfo,
  loadPhoneIpHistory,
  loadUserConnections,
  loadUserPhones,
  logoutPhone,
  rebootPhone,
  refreshPhoneConnection,
  removeConnection,
  removePhone,
  sendPhoneReport,
  buyPhonePhonePlan,
  updateConnection,
  updateMultipleConnectionField,
  changeTariffPlan,
  updateAppVersion,
  updateConnectionCred,
  updateFingerprint,
  removeChangeIpUrl,
  addChangeIpUrl,
  fixPhoneLTE,
  switchPhone,
  findPhone,
  loadPhonesIpHistory,
  showNotRotatedConnections,
  updatePhoneMass, setPhoneCustomActions, loadPhoneSmsHistory, loadUserAlerts, removeAlert, createNewAlert,
  loadOpenVPN,
  loadAllOpenVPNs,
  removeOpenVPN,
  createNewOpenVPN,
  updateOpenVPN,
  updateOpenVPNMass,
  rebootPhones,
} from '../actions/connections';
import { idle } from '../actions/app';
import { loadUserBalance } from '../actions/user';
import { Api } from '../../api';
import { Connection, PlansType } from '../../../types';
import { Alert, OpenVPN, Phone } from '../../../types/phones';

const ApiURLLoadUserPhones = () =>
  '/phones/all';
const ApiURLGetUserConnections = (userId: string) =>
  `/user/${userId}/connections`;
const ApiURLGetUserAlerts = (userId: string) =>
  `/alerts?userId=${userId}`;
const ApiURLRemoveAlert = (alerId: string) =>
  `/alerts/${alerId}`;
const ApiURLCreateNewAlert = () =>
  '/alerts';
const ApiURLGetOpenVPN = (phoneId: string) =>
  `/phones/${phoneId}/uconnections`;
const ApiURLGetAllOpenVPNs = (userId: string) =>
  `/user/${userId}/uconnections`;
const ApiURLRemoveOpenVPN = (phoneId: string, id: string) =>
  `/phones/${phoneId}/uconnections/${id}`;
const ApiURLCreateNewOpenVPN = (phoneId: string) =>
  `/phones/${phoneId}/uconnections`;
const ApiURLUpdateOpenVPN = (phoneId: string, id: string) =>
  `/phones/${phoneId}/uconnections/${id}`;
const ApiURLCreatePhone = (isOnboarding = false) =>
  `/phones/smart_create${isOnboarding ? '?onboarding=yes' : ''}`;
const ApiURLRemoveConnection = () =>
  '/connections/smart_delete';

const ApiURLRemoveIpChangeUrl = (phoneId) =>
  `/phones/${phoneId}/remove-change-ip-code`;
const ApiURLAddIpChangeUrl = (phoneId) =>
  `/phones/${phoneId}/add-change-ip-code`;
const ApiURLRemovePhone = () =>
  '/phones/smart_delete';
const ApiURLAddNewConnection = () =>
  '/connections/to_phone/create';
const ApiURLUpdatePhone = () =>
  '/phone/update';
const ApiURLPhoneIpHistory = ({ phoneId, from, to }) =>
  `/phone/${phoneId}/ip_history_range?from=${from}&to=${to}`;
const ApiURLPhoneSmsHistory = ({ phoneId, from, to }) =>
  `/phones/${phoneId}/sms-in-range?from=${from}&to=${to}`;
const ApiURLGetPhonePin = (phoneId) =>
  `/phones/${phoneId}/pin_code`;
const ApiURLChangeIp = (phoneId, timestamp: number) =>
  `/changeip/${phoneId}/${`${timestamp}`.slice(-4)}`;
const ApiURLRefreshConnection = (phoneId) =>
  `/phone/${phoneId}/action_push/refresh1`;
const ApiURLRebootPhone = (phoneId) =>
  `/phone/${phoneId}/action_push/reboot_device`;
const ApiURLFixLTE = (phoneId) =>
  `/phone/${phoneId}/action_push/fix_lte`;
const ApiURLSwitchPhone = (phoneId, enabled) =>
  `/phone/${phoneId}/action_push/enable_proxy?enabled=${enabled}`;
const ApiURLFindPhone = (phoneId) =>
  `/phone/${phoneId}/action_push/find_my_phone`;
const ApiURLUpdateFingerpint = (phoneId) =>
  `/phone/${phoneId}/action_push/refresh_fingerprint`;
const ApiURLSendPhoneReport = (phoneId) =>
  `/phone/${phoneId}/action_push/debug_report`;
const ApiURLLogout = (phoneId) =>
  `/phone/${phoneId}/session/revoke`;

const ApiURLAllDeviceInfo = (userId) =>
  `/user/${userId}/phones/statuses-map-optimized`;
const ApiURLBuyPhonePlan = (phoneId) =>
  `/phone/${phoneId}/buy_plan`;
const ApiURLChangePhonePlan = (phoneId: string, planId: string) =>
  `/phone/${phoneId}/change-plan/${planId}`;
const ApiURLUpdateAppVersion = (phoneId: string) =>
  `/phone/${phoneId}/action_push/app_upgrade`;
const ApiURLAddUpdateCustomAction = (phoneId: string) =>
  `/phones/${phoneId}/save-custom-actions`;

const ApiURLUpdateConnection = (connectionId: string) =>
  `/connections/${connectionId}/update`;

export const loadUserPhonesEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadUserPhones.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLLoadUserPhones(), null, null, false)).pipe(
        switchMap(({ data }) => {
          const modifiedPhones = data?.map((item) => {
            const activePlan = item?.activePlans[0];
            const expDate = activePlan?.expirationTimestamp;
            const tariffName = Object.keys(PlansType).find(
              (key) =>
                PlansType[key] === activePlan?.id,
            );
            const modifiedTariffName = tariffName === 'BigDaddyPro'
              ? tariffName.replace('BigDaddyPro', 'BigDaddy Pro')
              : tariffName;
            return {
              ...item,
              expDate,
              tariffName: modifiedTariffName,
            };
          });

          return concat(
            of(loadUserPhones.success(modifiedPhones)),
            of(loadUserConnections.request(null)),
            of(loadUserAlerts.request(null)),
            of(loadAllOpenVPNs.request(null)),
          );
        }),
        catchError((error: Error) =>
          of(loadUserPhones.failure(error))),
      )),
    catchError((error: Error) =>
      of(loadUserPhones.failure(error))),
  );

export const loadUserAlertsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadUserAlerts.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLGetUserAlerts(state$.value.user.profile.id), null, null, false)).pipe(
        switchMap(({ data }) => {
          const alerts: Alert[] = data as Alert[];
          return of(loadUserAlerts.success(alerts));
        }),
      )),
    catchError((error: Error) =>
      of(loadUserAlerts.failure(error))),
  );

export const removeAlertEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeAlert.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.delete(ApiURLRemoveAlert(payload.alertId), null, null, false)).pipe(
        switchMap(() =>
          of(removeAlert.success({ phoneId: payload.phoneId, alertId: payload.alertId }))),
      )),
    catchError((error: Error) =>
      of(removeAlert.failure(error))),
  );

export const createNewAlertEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(createNewAlert.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLCreateNewAlert(), { ...payload.alert }, null, false)).pipe(
        switchMap(({ data }) =>
          concat(
            of(createNewAlert.success(data)),
            of(loadUserAlerts.request(null)),
          )),
      )),
    catchError((error: Error) =>
      of(createNewAlert.failure(error))),
  );

export const loadOpenVPNEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadOpenVPN.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) => {
      const request = payload?.phoneIds?.map((phoneId) =>
        Api.get(ApiURLGetOpenVPN(phoneId), null, null, false));

      return from(Promise.all(request)).pipe(
        switchMap((data) => {
          const openVpn: OpenVPN[] = data.map((d) =>
            d.data).flat() as unknown as OpenVPN[];
          return of(loadOpenVPN.success(openVpn)).pipe(tap(() => {
            if (payload?.callback != null) {
              payload?.callback();
            }
          }));
        }),
      );
    }),
    catchError((error: Error) =>
      of(loadOpenVPN.failure(error))),
  );

export const loadAllOpenVPNsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadAllOpenVPNs.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLGetAllOpenVPNs(state$.value.user.profile.id), null, null, false)).pipe(
        switchMap(({ data }) => {
          const openVpn: OpenVPN[] = data as OpenVPN[];
          return of(loadAllOpenVPNs.success(openVpn));
        }),
      )),
    catchError((error: Error) =>
      of(loadAllOpenVPNs.failure(error))),
  );

export const removeOpenVPNEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeOpenVPN.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.delete(ApiURLRemoveOpenVPN(payload.phoneId, payload.openVPNId), null, null, false)).pipe(
        switchMap(() =>
          of(removeOpenVPN.success({ id: payload.openVPNId }))),
      )),
    catchError((error: Error) =>
      of(removeOpenVPN.failure(error))),
  );

export const createNewOpenVPNEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(createNewOpenVPN.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLCreateNewOpenVPN(payload.openVPN.phoneId), { ...payload.openVPN }, null, false)).pipe(
        switchMap(({ data }) => {
          const ids = [payload.openVPN.phoneId];

          return concat(
            of(createNewOpenVPN.success(data)),
            of(loadOpenVPN.request({ phoneIds: ids })),
          ).pipe(tap(() => {
            if (payload?.callback != null) {
              payload?.callback();
            }
          }));
        }),
      )),
    catchError((error: Error) =>
      of(createNewOpenVPN.failure(error))),
  );

export const updateOpenVPNEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateOpenVPN.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.put(ApiURLUpdateOpenVPN(payload.openVPN.phoneId, payload.id), { ...payload.openVPN }, null, false)).pipe(
        switchMap(({ data }) => {
          const ids = [payload.openVPN.phoneId];

          return concat(
            of(updateOpenVPN.success(data)),
            of(loadOpenVPN.request({ phoneIds: ids })),
          ).pipe(tap(() => {
            if (payload?.callback != null) {
              payload?.callback();
            }
          }));
        }),
      )),
    catchError((error: Error) =>
      of(updateOpenVPN.failure(error))),
  );

export const updateOpenVPNMassEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateOpenVPNMass.request)),
    withLatestFrom(state$),

    switchMap(([{ payload }]) => {
      const request = payload?.data?.map((ovpn) =>
        Api.put(ApiURLUpdateOpenVPN(ovpn.phoneId, ovpn.id), { ...ovpn }, null, false));

      const ids = Array.from(new Set(payload?.data?.map(({ phoneId }) =>
        phoneId)));

      return from(Promise.all(request)).pipe(
        switchMap((data) =>
          concat(
            of(updateOpenVPNMass.success(data as unknown as OpenVPN[])),
            of(loadOpenVPN.request({ phoneIds: ids })),
          ).pipe(tap(() => {
            if (payload?.callback != null) {
              payload?.callback();
            }
          }))),
      );
    }),
    catchError((error: Error) =>
      of(updateOpenVPNMass.failure(error))),
  );

export const loadUserConnectionsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadUserConnections.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLGetUserConnections(state$.value.user.profile.id), null, null, false)).pipe(
        switchMap(({ data }) => {
          const connections: Connection[] = data as Connection[];
          return of(loadUserConnections.success(connections));
        }),
      )),
    catchError((error: Error) =>
      of(loadUserConnections.failure(error))),
  );

export const createNewConnectionEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(createNewConnection.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLCreatePhone(payload?.onboarding), (payload?.phone ? payload?.phone as any : {}), null, false)).pipe(
        switchMap(({ data }) => {
          if (payload?.callback != null) {
            payload?.callback(data);
          }
          return concat(
            of(createNewConnection.success(data)),
            of(loadUserConnections.request(null)),
          );
        }),
      )),
    catchError((error: Error) =>
      of(createNewConnection.failure(error))),
  );

export const addNewConnectionEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addNewConnection.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLAddNewConnection(), (payload.connection as any), null, false)).pipe(
        switchMap(({ data }) => {
          payload.callback();
          if (payload?.connection?.allow_from != null) {
            return concat(
              of(addNewConnection.success(data)),
            );
          }
          return of(addNewConnection.success(data));
        }),
      )),
    catchError((error: Error) =>
      of(addNewConnection.failure(error))),
  );

export const removeConnectionEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeConnection.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLRemoveConnection(), { id: payload.connectionId }, null, false)).pipe(
        switchMap(() =>
          of(removeConnection.success({ phoneId: payload.phoneId, connectionId: payload.connectionId }))),
      )),
    catchError((error: Error) =>
      of(removeConnection.failure(error))),
  );

export const updateConnectionEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateConnection.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLUpdatePhone(), { ...payload }, null, false)).pipe(
        switchMap(({ data }) =>
          concat(
            of(updateConnection.success(data as Phone)),
            of(loadUserPhones.request(null)),
          )),
      )),
    catchError((error: Error) =>
      of(updateConnection.failure(error))),
  );

export const updatePhoneMassEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updatePhoneMass.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) => {
      const request = payload?.phonesId?.map((phoneId) =>
        Api.post(ApiURLUpdatePhone(), { id: phoneId, ...payload?.formData }, null, false));

      return from(Promise.all(request)).pipe(
        switchMap((result) => {
          const updatePhones: Phone[] = result.map((res) =>
            res?.data);
          return of(updatePhoneMass.success(updatePhones));
        }),
      );
    }),
    catchError((error: Error) =>
      of(updatePhoneMass.failure(error))),
  );

export const loadPhoneIpHistoryEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadPhoneIpHistory.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLPhoneIpHistory(payload), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(loadPhoneIpHistory.success({
            phoneId: payload.phoneId,
            history: data,
          }))),
        catchError((error: Error) =>
          of(loadPhoneIpHistory.failure(error))),
      )),
    catchError((error: Error) =>
      of(loadPhoneIpHistory.failure(error))),
  );

export const loadPhoneSmsHistoryEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadPhoneSmsHistory.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLPhoneSmsHistory(payload), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(loadPhoneSmsHistory.success({
            phoneId: payload.phoneId,
            history: data,
          }))),
        catchError((error: Error) =>
          of(loadPhoneSmsHistory.failure(error))),
      )),
    catchError((error: Error) =>
      of(loadPhoneSmsHistory.failure(error))),
  );

export const loadPhonesIpHistoryEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadPhonesIpHistory.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) => {
      const request = payload?.phoneIds?.map((phoneId) =>
        Api.get(ApiURLPhoneIpHistory({
          from: payload?.from,
          to: payload?.to,
          phoneId,
        }), null, null, false));

      return from(Promise.all(request)).pipe(
        switchMap((result) => {
          const phonesData = result?.map(({ data }) =>
            data);
          const phones = phonesData?.reduce((acc, historyData) => {
            const ipsMap = historyData?.reduce((a, history) => {
              if (a.has(history?.ip)) {
                const dd = a.get(history?.ip);
                a.set(`${history?.ip}`, dd + 1);
              } else {
                a.set(`${history?.ip}`, 1);
              }
              return a;
            }, new Map());

            const filteredMapList = (Array.from(ipsMap)
              ?.filter((data) =>
                data[1] > 1))
              ?.map((data) =>
                ({ ip: data[0], count: data[1] }));

            if (filteredMapList?.length > 0) {
              acc.push({
                phoneId: historyData[0]?.phoneId,
                ips: filteredMapList,
                totalPeriodIps: Array.from(ipsMap)?.length,
              });
            }

            return acc;
          }, []);

          return concat(
            of(loadPhonesIpHistory.success({ ipDublicates: phones })),
            of(showNotRotatedConnections.request({ phonesHistory: phonesData })),
          );
        }),
        catchError((error: Error) =>
          of(loadPhonesIpHistory.failure(error))),
      );
    }),
    catchError((error: Error) =>
      of(loadPhonesIpHistory.failure(error))),
  );

export const showNotRotatedConnectionsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(showNotRotatedConnections.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) => {
      const min = state$?.value?.app?.dashboard_config?.configs?.ipNotChangeTime;

      if (min != null && min !== 0) {
        const { phonesHistory } = payload;
        const phonesIpThatNotChaned = phonesHistory
          ?.filter((data) =>
            data.length > 0)
          ?.reduce((acc, val) => {
            const hasIp = val?.filter((d) =>
              moment().subtract(min, 'minute').isSameOrBefore(d?.t));

            if (!hasIp.length) {
              acc.push({
                phoneId: val[0]?.phoneId,
                lastIpChangeTimestamp: val[val.length - 1]?.t,
              });
            }
            return acc;
          }, []);

        return of(showNotRotatedConnections.success(phonesIpThatNotChaned));
      }
      return of(idle());
    }),
    catchError((error: Error) =>
      of(showNotRotatedConnections.failure(error))),
  );

export const getPhonePinCodeEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getPhonePinCode.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLGetPhonePin(payload.phoneId), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(getPhonePinCode.success({ pin: data?.result, phoneId: payload.phoneId }))),
      )),
    catchError((error: Error) =>
      of(getPhonePinCode.failure(error))),
  );

export const refreshPhoneConnectionEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(refreshPhoneConnection)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLRefreshConnection(payload.phoneId), null, null, false)).pipe(
        switchMap(() =>
          of(idle())),
      )),
    catchError(() =>
      of(idle())),
  );

export const rebootPhoneEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(rebootPhone)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLRebootPhone(payload.phoneId), null, null, false)).pipe(
        switchMap(() =>
          of(idle())),
      )),
    catchError(() =>
      of(idle())),
  );

export const rebootPhonesEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(rebootPhones)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) => {
      const request = payload?.phoneIds?.map((phoneId) =>
        Api.get(ApiURLRebootPhone(phoneId), null, null, false));

      return from(Promise.all(request)).pipe(
        switchMap(() => {
          payload?.callback();

          return of(idle());
        }),
      );
    }),
    catchError(() =>
      of(idle())),
  );

export const fixLteEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fixPhoneLTE)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLFixLTE(payload.phoneId), null, null, false)).pipe(
        switchMap(() =>
          of(idle())),
      )),
    catchError(() =>
      of(idle())),
  );

export const switchPhoneEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(switchPhone)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLSwitchPhone(payload.phoneId, payload.enabled), null, null, false)).pipe(
        switchMap(() =>
          of(idle())),
      )),
    catchError(() =>
      of(idle())),
  );

export const findPhoneEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(findPhone)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLFindPhone(payload.phoneId), null, null, false)).pipe(
        switchMap(() =>
          of(idle())),
      )),
    catchError(() =>
      of(idle())),
  );

export const updateFingerprintEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateFingerprint)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      concat(
        of(updateConnection.request({ id: payload.phoneId, fingerprint: payload?.fingerType })),
        from(Api.get(ApiURLUpdateFingerpint(payload.phoneId), {
          fingerprint: payload?.fingerType,
        }, null, false)).pipe(
          switchMap(() =>
            of(idle())),
        ),
      )),
    catchError(() =>
      of(idle())),
  );

export const sendPhoneReportEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sendPhoneReport)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLSendPhoneReport(payload.phoneId), null, null, false)).pipe(
        switchMap(() =>
          of(idle())),
      )),
    catchError(() =>
      of(idle())),
  );

export const logoutPhoneEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(logoutPhone)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLLogout(payload.phoneId), null, null, false)).pipe(
        switchMap(() =>
          of(idle())),
      )),
    catchError(() =>
      of(idle())),
  );

export const removePhoneEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removePhone.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      concat(
        of(removePhone.success({ phoneId: payload.phoneId })),
        from(Api.post(ApiURLRemovePhone(), { id: payload.phoneId }, null, false)).pipe(
          switchMap(() =>
            concat(
              of(removePhone.success({ phoneId: payload.phoneId })),
              of(loadUserBalance.request({ userId: state$?.value?.user?.profile?.id })),
            )),
        ),
      )),
    catchError((error: Error) =>
      of(removePhone.failure(error))),
  );

export const changePhoneIpAddressEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(changePhoneIpAddress)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLChangeIp(payload.phoneId, payload.timestamp), null, null, false)).pipe(
        switchMap(() =>
          of(idle())),
      )),
    catchError(() =>
      of(idle())),
  );

export const updateMultipleItemsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateMultipleConnectionField.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) => {
      const data = payload.phoneIds.map((id) =>
        from(Api.post(
          ApiURLUpdatePhone(),
          {
            id,
            [`${payload?.paramKey}`]: `${payload.key}-${String(+new Date()).slice(-3)}-${uuidv4().slice(-4)}`,
          },
          null,
          false,
        ))
          .pipe(
            switchMap(({ data }) =>
              of(updateConnection.success(data as Phone))),
          ));
      return concat(...data);
    }),
    catchError(() =>
      of(idle())),
  );

// export const getPhoneRealInfoEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
//     action$.pipe(
//         filter(isActionOf(getPhoneRealInfo.request)),
//         debounce(() => interval(500)),
//         withLatestFrom(state$),
//         switchMap(() => {
//             const phones = state$?.value?.connections?.data;
//             if(phones != null && phones.length){
//                 let request = phones?.map((v: Phone) => from(Api.get(ApiURLDeviceInfo(v.id))).pipe(
//                     switchMap(({data}) => of(getPhoneRealInfo.success({status: data, phoneId: v.id})))
//                 ))
//
//                 return concat(...request);
//             } else {
//                 return of(idle())
//             }
//         }),
//         catchError((error: Error) => {return of(getPhoneRealInfo.failure(error))})
//     )

export const getPhonesInfoEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getPhoneRealInfo.request)),
    debounce(() =>
      interval(500)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLAllDeviceInfo(state$.value.user.profile.id), null, null, false)).pipe(
        switchMap(({ data }) => {
          const onlineStats = Object.keys(data || {})
            .reduce((acc, val) =>
              ({ ...acc, [val]: { online: data[val].online } }), {});

          if (window.hasOwnProperty('iproxy-phone-info')) {
            if (!isEqual(onlineStats, window['iproxy-phone-info'])) {
              window['iproxy-phone-info'] = onlineStats;
              return concat(
                of(getPhoneRealInfo.success(data)),
              );
            }
            return of(getPhoneRealInfo.success(data));
          }
          window['iproxy-phone-info'] = onlineStats;
          return of(getPhoneRealInfo.success(data));
        }),
        catchError((error: Error) =>
          of(getPhoneRealInfo.failure(error))),
      )),
    catchError((error: Error) =>
      of(getPhoneRealInfo.failure(error))),
  );

export const setPhonePhonePlanEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(buyPhonePhonePlan.request)),
    debounce(() =>
      interval(500)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLBuyPhonePlan(payload.phoneId), {
        code: payload?.code,
        monthsNumber: payload?.month,
        planId: payload?.planId,
      }, null, false)).pipe(
        switchMap(({ data }) =>
          concat(
            of(buyPhonePhonePlan.success({ phone: data?.result })),
            of(loadUserBalance.request({ userId: state$?.value?.user?.profile?.id })),
            of(loadUserPhones.request(null)),
          )),
      )),
    catchError((error: Error) =>
      of(buyPhonePhonePlan.failure(error))),
  );

export const changeTariffPlanEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(changeTariffPlan.request)),
    debounce(() =>
      interval(500)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLChangePhonePlan(payload.phoneId, payload.planId), null, null, false)).pipe(
        switchMap(({ data }) => {
          payload.callback?.({ success: true });
          return of(changeTariffPlan.success({ phone: data?.result?.phone }));
        }),
        catchError((error) => {
          payload.callback?.(error);
          return of(changeTariffPlan.failure(error));
        }),
      )),
  );

export const updateAppVersionEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateAppVersion.request)),
    withLatestFrom(action$),
    switchMap(([{ payload }]) =>
      from(Api.get(ApiURLUpdateAppVersion(payload?.phoneId), null, null, false)).pipe(
        switchMap(() =>
          of(updateAppVersion.success(null, null))),
        catchError((error: Error) =>
          of(updateAppVersion.failure(error))),
      )),
    catchError((error: Error) =>
      of(updateAppVersion.failure(error))),
  );

export const setPhoneCustomActionsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(setPhoneCustomActions.request)),
    withLatestFrom(action$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLAddUpdateCustomAction(payload?.phoneId), {
        customActions: payload?.actions || [],
      } as any)).pipe(
        switchMap(({ data }) => {
          payload?.callback();

          return of(setPhoneCustomActions.success({
            phoneId: data?.id,
            customActions: data?.customActions || [],
          }));
        }),
        catchError((error: Error) =>
          of(setPhoneCustomActions.failure(error))),
      )),
    catchError((error: Error) =>
      of(setPhoneCustomActions.failure(error))),
  );
//

export const updateConnectionCredEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateConnectionCred.request)),
    withLatestFrom(action$),
    switchMap(([{ payload }]) => {
      const r = {
        login: payload?.login,
        password: payload?.password,
        listen_service: payload?.listen_service,
        auth_type: payload?.auth_type,
        description: payload?.description,
        allow_from: payload?.allow_from,
        expirationTimestamp: payload?.expirationTimestamp,
      };

      return from(Api.post(ApiURLUpdateConnection(payload?.connectionId), r, null, false)).pipe(
        switchMap(({ data }) =>
          of(updateConnectionCred.success({
            phoneId: payload?.phoneId,
            connection: data?.result,
          }))),
        catchError((error: Error) =>
          of(updateConnectionCred.failure(error))),
      );
    }),
    catchError((error: Error) =>
      of(updateConnectionCred.failure(error))),
  );

export const addChangeIpUrlEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(addChangeIpUrl.request)),
    withLatestFrom(action$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLAddIpChangeUrl(payload?.phoneId), null, null, false)).pipe(
        switchMap(({ data }) => {
          const changeIPKeys = data?.result?.changeIPKeys || [];
          return of(addChangeIpUrl.success({ changeIPKeys, phoneId: payload?.phoneId }));
        }),
        catchError((error: Error) =>
          of(addChangeIpUrl.failure(error))),
      )),
    catchError((error: Error) =>
      of(addChangeIpUrl.failure(error))),
  );

export const removeChangeIpUrlEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(removeChangeIpUrl.request)),
    withLatestFrom(action$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLRemoveIpChangeUrl(payload?.phoneId), {
        code: payload?.code,
      }, null, false)).pipe(
        switchMap(({ data }) => {
          const changeIPKeys = data?.result?.changeIPKeys || [];
          return of(removeChangeIpUrl.success({ changeIPKeys, phoneId: payload?.phoneId }));
        }),
        catchError((error: Error) =>
          of(removeChangeIpUrl.failure(error))),
      )),
    catchError((error: Error) =>
      of(removeChangeIpUrl.failure(error))),
  );
