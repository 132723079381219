import { debounce } from 'lodash';
import { ToastOptions, toast } from 'react-toastify';
import { Api } from '../core/api';
import { saveToFile } from './core-utils';

export const debounceLoad = debounce((callback) => {
  callback();
}, 300, {
  leading: false,
  trailing: true,
});

export const showNotification = (message, type = 'info') => {
  const options = {
    position: 'top-right',
    autoClose: 5000,
    icon: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'colored',
  } as ToastOptions;

  return type === 'info' ? toast.info(message, options) : toast.error(message, options);
};

export const selectTarget = (currentTarget) => {
  const range = document.createRange();
  range.selectNodeContents(currentTarget);
  const sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
};

export const $downloadConfig = async (id, name, phoneId, isNumericFormatIP) => {
  try {
    const { data } = await Api.get(
      `/phones/${phoneId}/uconnections/${id}/config${
        isNumericFormatIP ? '?noFqdns=1' : ''
      }`,
    );
    const d = window.atob(data?.config_base64);
    saveToFile(`${name}.ovpn`, d);
  } catch (e) {
    console.error('Error:> [Download oVPN Config] :=', e);
  }
};
