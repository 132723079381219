import React from 'react';
import styled from 'styled-components';
import Markdown from 'markdown-it';

import { UIProps } from '../types';

export function WebContentRender({ content } : any) {
  const md = Markdown({
    html: true,
    linkify: true,
    typographer: true,
  });

  return (
    <Web dangerouslySetInnerHTML={{ __html: md.render(content) }} />
  );
}

const Web = styled.div`
   box-sizing: content-box;
   a {
    color: #244EB3;
    text-decoration: none;
    transition: .3s;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }
  
  blockquote {
    background-color: #fdf7f7;
    padding: 15px;
    border-left: 6px solid #f7f3f3;
    border-radius: 5px;
    margin: 0px 35px 0px 35px;
  }
  

  img {
    display: block;
    cursor: pointer;
    background: #C4C4C4;
    width: 50%;
    margin: auto;
  }
  
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    font-weight: normal;
    margin-bottom: 20px; 
     @media only screen and (max-width: 500px) {
      font-size: 12px;
     }
  }
  
  ul {
    text-align: start;
    background: ${(props: UIProps) =>
    props.theme.colors.lightSky};
    border-radius: 5px;
    padding: 15px 35px 15px 35px;
    font-size: 14px;
    line-height: 17px;
    box-sizing: content-box;
    @media only screen and (max-width: 500px) {
     font-size: 12px;
    line-height: 15px;
    }
  }

  ol {
    padding: 0px 35px 0px 35px;
  }

  iframe {
    display: block;
    padding: 10px;
    width: 500px;
    height: 300px;
    margin: 20px auto 20px;
    box-sizing: border-box;

    @media only screen and (max-width: 1000px) {
      width: 100%;
      max-width: 70%;
      height: 35vw;
      max-height: 400px;
      min-width: 275px;
      min-height: 180px;
    }
  }
  
  h1 {
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin: 32px 0;
    padding: 0;
    @media only screen and (max-width: 880px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  
  h2 {
    font-weight: bold;
    margin: 15px 0;
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    
     @media only screen and (max-width: 880px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  
  h3 {
    font-weight: 500;
    margin: 15px 0;
    padding: 0;
    font-size: 14px;
  }
`;
