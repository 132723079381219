import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { UIProps } from '../types';
import { textShorter } from '../utils';

import iconNotification from '../assets/notification.svg';

interface NotificationElementProps {
  speedMs?: number;
  showNotification?: boolean;
  onReady?: Function;
  color?: ColorSet;
  content?: string;
}

const colorSet = {
  primary: {
    line: '#5E88EA',
    bg: '#244EB2',
  },
  warning: {
    line: '#FEA5AA',
    bg: '#FB7880',
  },
  success: {
    line: '#25D096',
    bg: '#1AB580',
  },
};

type ColorSet = 'primary' | 'warning' | 'success';

function Line({
  speed, color, status, onEnd = () => {},
}: any) {
  // @ts-ignore
  const [propsAnimation] = useSpring(() =>
    ({
      width: status ? '100%' : '0%',
      from: { width: '0%' },
      config: { duration: speed },
      onRest: () => {
        onEnd();
      },
    }));

  return (<TimeLine style={propsAnimation} color={color} />);
}

export const NotificationElement: FunctionComponent<NotificationElementProps> = (props) => {
  const {
    speedMs = 4000,
    color = 'primary',
    content = '',
    onReady = () => {},
    showNotification = false,
  } = props;
  const [show, setShow] = useState(false);

  const stop = () => {
    onReady();
    setShow(false);
  };

  useEffect(() => {
    if (!show) {
      setShow(showNotification);
    }
  }, [showNotification]);

  return show ? (
    <Wrapper
      onClick={() =>
        stop()}
      color={color}
    >
      <Line
        color={color}
        speed={speedMs}
        status={show}
        onEnd={() =>
          stop()}
      />
      <Content>
        <IconNotification />
        {textShorter(content, 100)}
      </Content>
    </Wrapper>
  ) : null;
};

interface WrapperProps {
  color: string;
}
const Wrapper = styled.div<WrapperProps>`
  height: 48px;
  position: relative;
  background: ${(props: TimeLineProps) =>
    colorSet[props?.color].bg};
  border-radius: 5px;
  width: max-content;
  overflow: hidden;
  cursor: pointer;
  min-width: 300px;
`;

interface TimeLineProps {
  color: string;

}
const TimeLine = styled(animated.div)<TimeLineProps>`
  height: 5px;
  position: absolute;
  top: 0;
  transition: .3ms;
  background: ${(props: TimeLineProps) =>
    colorSet[props?.color].line};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${(props:UIProps) =>
    props.theme.colors.white};
  padding: 0 10px;
  ${(props:UIProps) =>
    props.theme.sizes.font.small};
`;

const IconNotification = styled.div`
    width: 14px;
    height: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${iconNotification});
    margin-right: 10px;
`;
