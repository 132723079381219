import { createReducer } from 'typesafe-actions';

import { UserStore } from '../../../types';
import {
  getApiKey,
  loadUserBalance,
  loadUserInfo,
  logInUser,
  updateApiKey,
  getInvitedUsers,
  loadUserTransaction,
  loadUserAutoPaymentInfo,
  loadPaymentMethods,
  getReferralCodes,
  addReferralCode,
  removeReferralCode,
} from '../actions/user';

const initialAppState: UserStore = {
  auth: false,
  profile: null,
  balance: null,
  autoPaymentInfo: {
    data: null,
    error: null,
    loading: false,
  },
  apiKey: {
    data: null,
    error: null,
    loading: false,
  },
  invitedUsers: {
    data: null,
    error: null,
    loading: false,
  },
  transactions: {
    data: null,
    error: null,
    loading: false,
  },
  paymentMethods: {
    data: null,
    error: null,
    loading: false,
  },
};

const userReducer = createReducer(initialAppState)
  .handleAction(logInUser.request, (state) =>
    ({ ...state }))
  .handleAction(logInUser.success, (state) =>
    ({ ...state }))
  .handleAction(logInUser.failure, (state) =>
    ({ ...state }))

  .handleAction(loadUserInfo.request, (state) =>
    ({ ...state }))
  .handleAction(loadUserInfo.success, (state, { payload }) =>
    ({
      ...state,
      profile: payload,
    }))
  .handleAction(loadUserInfo.failure, (state) =>
    ({ ...state }))

  .handleAction(loadUserBalance.request, (state) =>
    ({ ...state }))
  .handleAction(loadUserBalance.success, (state, { payload }) =>
    ({ ...state, balance: payload.balance }))
  .handleAction(loadUserBalance.failure, (state) =>
    ({ ...state }))

// Get API key
  .handleAction(getApiKey.request, (state) =>
    ({
      ...state, apiKey: { ...state.apiKey, loading: true },
    }))
  .handleAction(getApiKey.success, (state, { payload }) =>
    ({
      ...state, apiKey: { ...state.apiKey, loading: false, data: payload.token },
    }))
  .handleAction(getApiKey.failure, (state) =>
    ({
      ...state, apiKey: { ...state.apiKey, loading: false },
    }))

// Update API key
  .handleAction(updateApiKey.request, (state) =>
    ({
      ...state, apiKey: { ...state.apiKey, loading: true },
    }))
  .handleAction(updateApiKey.success, (state, { payload }) =>
    ({
      ...state, apiKey: { ...state.apiKey, loading: false, data: payload.token },
    }))
  .handleAction(updateApiKey.failure, (state) =>
    ({
      ...state, apiKey: { ...state.apiKey, loading: false },
    }))

  .handleAction(getInvitedUsers.request, (state) =>
    ({
      ...state, invitedUsers: { ...state.invitedUsers, loading: true },
    }))
  .handleAction(getInvitedUsers.success, (state, { payload }) =>
    ({
      ...state, invitedUsers: { ...state.invitedUsers, loading: false, data: payload?.result?.users },
    }))
  .handleAction(getInvitedUsers.failure, (state) =>
    ({
      ...state, invitedUsers: { ...state.invitedUsers, loading: false },
    }))

  .handleAction(getReferralCodes.request, (state) =>
    ({
      ...state, profile: { ...state.profile, loading: true },
    }))
  .handleAction(getReferralCodes.success, (state, { payload }) =>
    ({
      ...state, profile: { ...state.profile, referralCodes: payload },
    }))
  .handleAction(getReferralCodes.failure, (state) =>
    ({
      ...state, profile: { ...state.profile, loading: false },
    }))

  .handleAction(addReferralCode.request, (state) =>
    ({
      ...state, profile: { ...state.profile, loading: true },
    }))
  .handleAction(addReferralCode.success, (state, { payload }) =>
    ({
      ...state, profile: { ...state.profile, referralCodes: payload.result },
    }))
  .handleAction(addReferralCode.failure, (state) =>
    ({
      ...state, profile: { ...state.profile, loading: false },
    }))

  .handleAction(removeReferralCode.request, (state) =>
    ({
      ...state, profile: { ...state.profile, loading: true },
    }))
  .handleAction(removeReferralCode.success, (state, { payload }) =>
    ({
      ...state, profile: { ...state.profile, referralCodes: payload.result },
    }))
  .handleAction(removeReferralCode.failure, (state) =>
    ({
      ...state, profile: { ...state.profile, loading: false },
    }))

// transactions
  .handleAction(loadUserTransaction.request, (state) =>
    ({
      ...state, transactions: { ...state.transactions, loading: true },
    }))
  .handleAction(loadUserTransaction.success, (state, { payload }) =>
    ({
      ...state, transactions: { ...state.transactions, loading: false, data: payload?.data },
    }))
  .handleAction(loadUserTransaction.failure, (state) =>
    ({
      ...state, transactions: { ...state.transactions, loading: false },
    }))

// auto payment info
  .handleAction(loadUserAutoPaymentInfo.request, (state) =>
    ({
      ...state, autoPaymentInfo: { ...state.autoPaymentInfo, loading: true },
    }))
  .handleAction(loadUserAutoPaymentInfo.success, (state, { payload }) =>
    ({
      ...state, autoPaymentInfo: { ...state.autoPaymentInfo, loading: false, data: payload.data },
    }))
  .handleAction(loadUserAutoPaymentInfo.failure, (state) =>
    ({
      ...state, autoPaymentInfo: { ...state.autoPaymentInfo, loading: false },
    }))

// payments methods
  .handleAction(loadPaymentMethods.request, (state) =>
    ({ ...state, paymentMethods: { ...state.paymentMethods, loading: true } }))
  .handleAction(loadPaymentMethods.success, (state, { payload }) =>
    ({
      ...state, paymentMethods: { ...state.paymentMethods, loading: false, data: payload },
    }))
  .handleAction(loadPaymentMethods.failure, (state) =>
    ({ ...state, paymentMethods: { ...state.paymentMethods, loading: false } }));

export default userReducer;
export type UserState = ReturnType<typeof userReducer>;
