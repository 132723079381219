import React, {
  useState, useRef, FunctionComponent,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SelectOption, UIProps } from '../../../types';
import { BaseModalFormParams } from '../../../modal';
import { BasicButton, WrapperScreen, InputWrapper } from '../../../styles/ui-controls';
import { saveToFile } from '../../../utils';
import { CheckBox } from '../../atoms/Checkbox';
import { FormContainer, Wrapper } from './styles';

import { DropdownElement, InputField } from '../../../elements';

interface DownloadCredentialsProps extends BaseModalFormParams {

}

enum AllFormats {
  TYPE = 'type',
  IP = 'ip',
  HOSTNAME = 'hostname',
  PORT = 'port',
  LOGIN = 'login',
  PASSWORD = 'password',
}

type ChangeIPType = {
  [key: string]: string[];
};

const fileFormats: SelectOption<any>[] = [
  {
    value: 'text/plain',
    label: '.txt',
  },
  {
    value: 'text/csv',
    label: '.csv',
  },
];

export const DownloadCredentials: FunctionComponent<DownloadCredentialsProps> = (props) => {
  const { modalParams } = props;
  const { phones, selectedPhones } = modalParams;

  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [changeIPLink, setChangeIPLink] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [fileFormat, setFileFormat] = useState(fileFormats[0]);
  const chosenFormats = inputValue.split(/([a-zA-Z]+)/).filter(Boolean);
  const inputRef = useRef(null);
  const AllFormatsValues = Object.values(AllFormats);

  function splitAndValidateFormat(value) {
    const REG_EXP = /(?<!type:)\/\/|[^a-zA-Zа-яА-Я0-9]+/g;
    const splittedFormat = value.split(REG_EXP).filter(Boolean);
    const delimiters = value.match(REG_EXP) || [];
    let isError = false;

    const finalFormat = [];
    splittedFormat.forEach((element, index) => {
      finalFormat.push(element);

      if (delimiters[index]) finalFormat.push(delimiters[index]);
    });

    finalFormat.forEach((element, index) => {
      const isEvenElement = index % 2 === 0;

      const isValidElement = isEvenElement
        ? !AllFormatsValues.includes(element)
        : element.length !== 1 && element !== '://';

      isError = isValidElement || isError;
    });

    const lastValue = finalFormat[finalFormat.length - 1];

    if (!AllFormatsValues.includes(lastValue)) isError = true;

    if (value.length === 0) isError = false;

    setInputError(isError);
  }

  const downloadCredentials = async () => {
    try {
      const filename = `credentials_${+new Date()}`;

      const formats = chosenFormats.map((format) => {
        if (format === AllFormats.TYPE) {
          return 'listen_service';
        } if (format === AllFormats.IP) {
          return 'address';
        }
        return format;
      }).join('');

      const connections = phones?.data
        ?.filter((i) =>
          selectedPhones?.includes(i?.id)).map((value) =>
          value?.connections.map((c) => {
            if (c.allow_from) {
              return {
                ...c,
                login: '',
                password: '',
              };
            }
            return c;
          })).flat();

      const formattedConnections = connections.map((obj) => {
        let formattedString = formats;

        Object.keys(obj).forEach((key) => {
          const value = obj[key];

          formattedString = key === 'name'
            ? formattedString
            : formattedString.replace(new RegExp(key, 'g'), value);
        });

        return {
          [obj.phoneId]: formattedString,
        };
      });

      const changeIPUrl = phones?.data
        ?.filter((i) =>
          selectedPhones?.includes(i?.id)).map((value) =>
          ({
            [value.id]: value?.changeIPKeys?.map((key) =>
              `https://i.fxdx.in/api-rt/changeip/${value.id}/${key}`),
          }));

      const result = formattedConnections.map((item) => {
        const [connectionKeys, connectionValues] = Object.entries(item)[0];

        if (changeIPLink) {
          return changeIPUrl.map((url: ChangeIPType) => {
            const [urlKeys, urlValues] = Object.entries(url)[0];

            if (urlKeys === connectionKeys) {
              return `${connectionValues}\n${urlValues?.map((value) =>
                `${value}\n`)}`;
            }

            return '';
          });
        }

        return `${connectionValues}\n`;
      }).join('\n').replace(/,/g, '');

      if (!inputError && inputValue.length !== 0) {
        saveToFile(filename, result, fileFormat.value);
      }
    } catch (e) {
      console.error('Error:> Mass credentials download error: :=', e);
    }
  };

  return (
    <WrapperScreen>
      <Wrapper
        scrollContainerHeigth="200px"
      >
        <FormContainer
          flexDirection="column"
          alignItems="start"
        >
          <div className="info">
            <div className="title">{t('massActions.downloadCredentials')}</div>
            <div className="subtitle">{t('massActions.createFormat')}</div>
          </div>
          <Formats>
            {
              AllFormatsValues.map((format) =>
                (
                  <Format
                    key={format}
                    onClick={() => {
                      setInputValue(inputValue + format);
                      inputRef?.current?.getElementsByTagName('input')[0].focus();
                      splitAndValidateFormat(inputValue + format);
                    }}
                  >
                    {format}
                  </Format>
                ))
            }
          </Formats>

          <FileFormatWrapper>
            <div className="subtitle">{t('massActions.fileFormat')}</div>
            <DropdownWrapper>
              <DropdownElement
                value={fileFormat}
                onSelected={(value) =>
                  setFileFormat(value)}
                options={fileFormats}
              />
            </DropdownWrapper>
          </FileFormatWrapper>

          <CheckBoxWrapper>
            <CheckBox
              value={changeIPLink}
              onClick={() =>
                setChangeIPLink(!changeIPLink)}
              label={t('massActions.changeIP')}
              hasLabel
            />
          </CheckBoxWrapper>

          <InputWrapper ref={inputRef}>
            <InputField
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                splitAndValidateFormat(e.target.value);
              }}
              error={inputError}
              errorMsg={t('massActions.formatError')}
            />
          </InputWrapper>

          <BasicButton
            onClick={() =>
              downloadCredentials()}
            disable={inputError || inputValue.length === 0}
          >
            {t('onboarding.download.step1.bt')}
          </BasicButton>
        </FormContainer>
      </Wrapper>
    </WrapperScreen>
  );
};

const Formats = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
`;

const Format = styled.div`
  padding: 5px 10px;
  border: 1px solid ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s ease, background 0.3s ease, opacity 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;

  &:hover {
    color: ${(prop: UIProps) =>
    prop.theme.colors.white};
    background-color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  }

  &:active {
    opacity: 0.7;
  }
`;

const CheckBoxWrapper = styled.div`
  margin: 20px 0;
  cursor: default;

  div {
    font-size: 16px;
  }
`;

const FileFormatWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
`;

const DropdownWrapper = styled.div`
  flex: 0 1 30%;
`;
