import React, { FunctionComponent, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo_iproxy.svg';
import { SideMenuActions } from './SideMenuActions';
import { Content, MobileTechSupport, Sidebar } from './OnboardingScreen';
import { ChangePassword } from './ChangePassword';
import { UIProps } from '../types';
import {
  DAY_TO_FORCE_RESET_PSWD,
  DAY_IN_MS,
} from '../constants/common';
import { BasicButton } from '../styles/ui-controls';
import { useOnClickOutside } from '../hooks';
import { ApiWarningBlock } from '../components/common/ApiWarningBlock';

const Wrapper = styled.div`
  background-color: #fff;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0;

  .row {
    display: flex;
    width: 100%;
    @media (max-width: 720px) {
      display: block;
    }
  }

  .input-container {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 30px;

    :last-child {
      margin-right: 0;
    }
  }

  .title {
    font-size: 20px;
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    font-weight: bold;
    width: 100%;
    margin-bottom: 20px;
  }

  @media (max-width: 880px) {
    flex-direction: column-reverse;
    justify-content: flex-end;

    .mobile-actions {
      .technical {
        display: flex;
      }
    }
  }

  .technical {
    color: white;
    font-weight: 400;
    height: 50px;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    cursor: pointer;
    transition: 0.3s;
    width: 250px;
    justify-content: space-between;

    .icon {
      display: flex;
      align-items: center;
    }

    .label {
      margin-left: 10px;
    }

    @media only screen and (max-width: 880px) {
      display: none;
    }
  }

  .support {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

    :hover {
      opacity: 0.7;
    }
  }

  .container {
    width: 75%;
    padding: 0 23px;
    margin-top: 20px;

    @media (max-width: 720px) {
      width: 100%;
      padding: 0;
    }
  }
`;

const ConfirmWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.clearDark};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;

const ConfirmBlock = styled.div`
  max-height: 140px;
  max-width: 500px;
  background-color: ${(props: UIProps) =>
    props.theme.colors.white};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;

  @media (max-width: 480px) {
    padding: 30px;
  }
`;

const BaseDescription = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 30px;
`;

export interface ChangePswScreenProps {
  onFinish: Function;
}

export const ChangePswScreen: FunctionComponent<ChangePswScreenProps> = ({
  onFinish,
}) => {
  const { t } = useTranslation();
  const confirmRef = useRef(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const ref = useRef(null);

  const daysLeft = Math.ceil(
    (DAY_TO_FORCE_RESET_PSWD - Date.now()) / DAY_IN_MS,
  );

  useOnClickOutside(confirmRef, () => {
    setShowConfirmModal(false);
  });

  return (
    <Wrapper ref={ref}>
      <Content>
        <div className="content-container">
          {DAY_TO_FORCE_RESET_PSWD > +Date.now() && (
            <ApiWarningBlock
              components={(
                <>
                  <div className="skip-text">
                    <div
                      className="skip-title"
                      dangerouslySetInnerHTML={{
                        __html: t('changePswAndApiKey.skipText', {
                          daysLeft: `<strong>${daysLeft}</strong>`,
                        }),
                      }}
                    />
                    <div>{t('changePswAndApiKey.skipDescription')}</div>
                  </div>
                </>
              )}
              onClick={() => {
                setShowConfirmModal(true);
                if (ref?.current) {
                  ref.current.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                  });
                }
              }}
              width="75%"
              padding="0 23px"
              imgSize="72px"
              isWarning={false}
            />
          )}

          <div className="container">
            <div className="title">
              {t('changePswAndApiKey.changePassword')}
            </div>
            <ChangePassword
              setChangePassword={() =>
                onFinish()}
            />
          </div>
        </div>
        <MobileTechSupport>
          <div className="mobile-actions">
            <SideMenuActions />
          </div>
        </MobileTechSupport>
      </Content>
      <Sidebar>
        <div className="onb_logo">
          <img width="199px" src={logo} alt="" />
        </div>
        <SideMenuActions />
      </Sidebar>
      {showConfirmModal && (
        <ConfirmWrapper>
          <ConfirmBlock ref={confirmRef}>
            <BaseDescription>
              {t('changePswAndApiKey.confirmSkip', {
                daysLeft,
              })}
            </BaseDescription>

            <Buttons>
              <BasicButton
                fillWidth
                onClick={() =>
                  onFinish()}
              >
                {t('editForm.confirmRemoval')}
              </BasicButton>
              <BasicButton
                fillWidth
                color="warning"
                onClick={() =>
                  setShowConfirmModal(false)}
              >
                {t('editForm.cancelRemoval')}
              </BasicButton>
            </Buttons>
          </ConfirmBlock>
        </ConfirmWrapper>
      )}
    </Wrapper>
  );
};
