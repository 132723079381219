import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { getNumEditing } from '../../../utils';
import { SliderBar } from '../../../elements/slider-bar';
import store from '../../../core/store/store';
import { setDashboardConfig } from '../../../core/store/actions/app';
import { CopySettings, DashboardConfig } from '../../../types/dashboard';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { UIProps } from '../../../types';
import { ModalSubtitle } from '../../../styles/ui-controls';
import { CheckBox } from '../../atoms/Checkbox';
import {
  FormContainer,
  CheckBoxWrap,
  TitleBlock,
} from './common';

interface SystemSettingsFormProps {}

export const SystemSettingsForm: FunctionComponent<
SystemSettingsFormProps
> = () => {
  const { t } = useTranslation();
  const appConfigs: DashboardConfig = useTypedSelector(
    ({ app }) =>
      app?.dashboard_config,
  );
  const [customNotificationTime, setCustomNotificationTime] = useState<any>();
  const [ipUniqnesMonitorTime, setIpUniqnesMonitorTime] = useState<any>(1);
  const [ipNotChangeTime, setIpNotChangeTime] = useState<any>(0);
  const [isNumericFormatIP, setIsNumericFormatIP] = useState<any>();

  const [isCopySettings, setIsCopySettings] = useState<boolean>();
  const [copySettings, setCopySettings] = useState<CopySettings | null>();

  useEffect(() => {
    const copySettingsConfig = appConfigs?.configs?.copySettings;
    if (
      appConfigs != null
      && appConfigs?.configs?.tariffExpNotification != null
    ) {
      setCustomNotificationTime(appConfigs?.configs?.tariffExpNotification);
      setIpUniqnesMonitorTime(appConfigs?.configs?.ipUniqnesNotification);
      setIpNotChangeTime(appConfigs?.configs?.ipNotChangeTime);
      setIsNumericFormatIP(appConfigs?.configs?.isNumericFormatIP);
      setIsCopySettings(!!copySettingsConfig);
      setCopySettings(copySettingsConfig);
    }
  }, []);

  return (
    <Wrapper>
      <FormContainer>
        <SliderContainer>
          <div className="timer-label">
            {t('dashboard.expTariff')}
            {' '}
            <span>
              {`${customNotificationTime} ${getNumEditing(
                customNotificationTime,
                i18n.language,
                'hours',
              )}`}
            </span>
          </div>
          <SliderBar
            from={24}
            to={120}
            step={24}
            value={customNotificationTime}
            onSlideEnd={(value) => {
              setCustomNotificationTime(value);
              store.dispatch(
                setDashboardConfig.request({
                  configs: {
                    ...appConfigs?.configs,
                    tariffExpNotification: value,
                  },
                }),
              );
            }}
          />
        </SliderContainer>

        <SliderContainer>
          <div className="timer-label">
            {t('dashboard.ipRepeat')}
            {' '}
            <span>
              {`${
                ipUniqnesMonitorTime != null && ipUniqnesMonitorTime !== 0
                  ? ipUniqnesMonitorTime
                  : '-'
              } ${
                ipUniqnesMonitorTime != null && ipUniqnesMonitorTime !== 0
                  ? getNumEditing(ipUniqnesMonitorTime, i18n.language, 'hours')
                  : ''
              }`}
            </span>
          </div>
          <SliderBar
            from={1}
            to={24}
            step={1}
            value={ipUniqnesMonitorTime}
            onSlideEnd={(value) => {
              setIpUniqnesMonitorTime(value);
              store.dispatch(
                setDashboardConfig.request({
                  configs: {
                    ...appConfigs?.configs,
                    ipUniqnesNotification: value,
                  },
                }),
              );
            }}
          />
        </SliderContainer>

        <SliderContainer>
          <div className="timer-label">
            {t('dashboard.notChanged')}
            {' '}
            <span>
              {`${
                ipNotChangeTime != null && ipNotChangeTime !== 0
                  ? ipNotChangeTime
                  : '-'
              } ${
                ipNotChangeTime != null && ipNotChangeTime !== 0
                  ? getNumEditing(ipNotChangeTime, i18n.language, 'minutes')
                  : ''
              }`}
            </span>
          </div>
          <SliderBar
            from={0}
            to={1440}
            step={1}
            value={ipNotChangeTime}
            onSlideEnd={(value) => {
              setIpNotChangeTime(value);
              store.dispatch(
                setDashboardConfig.request({
                  configs: {
                    ...appConfigs?.configs,
                    ipNotChangeTime: value,
                  },
                }),
              );
            }}
          />
        </SliderContainer>

        <Section>
          <TitleBlock>
            <ModalSubtitle>{t('dashboard.displayHostname')}</ModalSubtitle>
          </TitleBlock>
          <CheckBoxWrap>
            <CheckBox
              value={!isNumericFormatIP}
              onClick={(status) => {
                setIsNumericFormatIP(!status);
                store.dispatch(
                  setDashboardConfig.request({
                    configs: {
                      ...appConfigs?.configs,
                      isNumericFormatIP: !status,
                    },
                  }),
                );
              }}
              label={`${t('dashboard.domainName')}`}
              hasLabel
            />
          </CheckBoxWrap>

          <CheckBoxWrap>
            <CheckBox
              value={isNumericFormatIP}
              onClick={(status) => {
                setIsNumericFormatIP(status);
                store.dispatch(
                  setDashboardConfig.request({
                    configs: {
                      ...appConfigs?.configs,
                      isNumericFormatIP: status,
                    },
                  }),
                );
              }}
              label={`${t('dashboard.numericFormat')}`}
              hasLabel
            />
          </CheckBoxWrap>
        </Section>
        <Section>
          <TitleBlock>
            <ModalSubtitle>{t('copySettings.settings')}</ModalSubtitle>
          </TitleBlock>
          <CheckBoxWrap>
            <CheckBox
              value={isCopySettings}
              onClick={(status) => {
                const defaultSettings = {
                  autoIPRotation: true,
                  commonData: true,
                  ipChangeLink: true,
                };
                setIsCopySettings(status);
                setCopySettings(status ? defaultSettings : null);
                store.dispatch(
                  setDashboardConfig.request({
                    configs: {
                      ...appConfigs?.configs,
                      copySettings: status ? defaultSettings : null,
                    },
                  }),
                );
              }}
              label={t('copySettings.setCustom')}
              hasLabel
            />
          </CheckBoxWrap>
          {!!copySettings && (
            <SubCheckoboxContainer>
              <CheckBoxWrap>
                <CheckBox
                  value={copySettings.commonData}
                  onClick={(status) => {
                    setCopySettings({ ...copySettings, commonData: status });
                    store.dispatch(
                      setDashboardConfig.request({
                        configs: {
                          ...appConfigs?.configs,
                          copySettings: {
                            ...appConfigs?.configs.copySettings,
                            commonData: status,
                          },
                        },
                      }),
                    );
                  }}
                  label="http/socks5"
                  hasLabel
                />
              </CheckBoxWrap>
              <CheckBoxWrap>
                <CheckBox
                  value={copySettings.autoIPRotation}
                  onClick={(status) => {
                    setCopySettings({
                      ...copySettings,
                      autoIPRotation: status,
                    });
                    store.dispatch(
                      setDashboardConfig.request({
                        configs: {
                          ...appConfigs?.configs,
                          copySettings: {
                            ...appConfigs?.configs.copySettings,
                            autoIPRotation: status,
                          },
                        },
                      }),
                    );
                  }}
                  label={t('copySettings.autoIPRotation')}
                  hasLabel
                />
              </CheckBoxWrap>
              <CheckBoxWrap>
                <CheckBox
                  value={copySettings.ipChangeLink}
                  onClick={(status) => {
                    setCopySettings({ ...copySettings, ipChangeLink: status });
                    store.dispatch(
                      setDashboardConfig.request({
                        configs: {
                          ...appConfigs?.configs,
                          copySettings: {
                            ...appConfigs?.configs.copySettings,
                            ipChangeLink: status,
                          },
                        },
                      }),
                    );
                  }}
                  label={t('copySettings.ipChangeLink')}
                  hasLabel
                />
              </CheckBoxWrap>
            </SubCheckoboxContainer>
          )}
        </Section>
      </FormContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const SliderContainer = styled.div`
  width: 100%;

  .timer-label {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};

    span {
      font-weight: 500;
    }
  }
`;

const Section = styled.div`
  margin-top: 32px;
`;

const SubCheckoboxContainer = styled.div`
  margin-left: 10px;
`;
