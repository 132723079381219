import React from 'react';
import styled from 'styled-components';

import Hotkeys from 'react-hot-keys';
import { ConnectionsBoard } from '../components';
import { SuportHelpModal } from '../components/common/SuportHelpModal';
import { CommonAppContext } from '../context/CommonAppContext';
import { UIProps } from '../types';
import widgetIcon from '../assets/widget_button_icon.svg';

export function DashboardScreen() {
  const context = React.useContext(CommonAppContext);

  const openChat = () => {
    document?.querySelector<HTMLElement>('.woot-widget-bubble').click();

    if (!document.querySelector('.woot-exit-support-chat')) {
      const container = document.querySelector('.woot-widget-holder');
      const newElement = document.createElement('div');
      newElement.textContent = 'Exit';
      newElement.className = 'woot-exit-support-chat';
      newElement.addEventListener('click', () => {
        document?.querySelector<HTMLElement>('.woot-widget-bubble').click();
      });
      container.appendChild(newElement);
    }
  };
  // shift+alt+h  or shift+option+h
  return (
    <Hotkeys
      keyName="shift+alt+h"
      onKeyDown={() =>
        context.setShowSupportModal(true)}
    >
      {context?.showSupportModal ? <SuportHelpModal /> : null}
      <Wrapper>
        <ConnectionsBoard />
        <WrapperWidget>
          <WrapperButton>
            <WidgetOpenButton
              src={widgetIcon}
              onClick={() => {
                openChat();
              }}
            />
          </WrapperButton>
        </WrapperWidget>
      </Wrapper>
    </Hotkeys>
  );
}

const Wrapper = styled.div`
  width: 98%;
  margin: auto;
`;

const WrapperWidget = styled.div`
  display: flex;
  position: fixed;
  z-index: 100;
  right: 20px;
  bottom: 30px;
  flex-direction: row;

  @media only screen and (max-width: 880px) {
    bottom: 40px;
  }
`;

const WrapperButton = styled.div`
  display: absolute;
  z-index: 100;
  margin-left: 15px;
  bottom: 0;
`;
interface WidgetOpenButtonProps {
  src: string;
}

const WidgetOpenButton = styled.div<WidgetOpenButtonProps>`
  width: 78px;
  height: 78px;
  border-radius: 78px;
  ${(props: UIProps) =>
    props.theme.gradients.darkBlue};
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;

  :hover {
    opacity: 0.7;
  }

  :before {
    content: '';
    display: flex;
    width: 25px;
    height: 25px;
    background: url(${(props: WidgetOpenButtonProps) =>
    props.src}) no-repeat;
  }

  @media only screen and (max-width: 880px) {
    width: 45px;
    height: 45px;
    border-radius: 45px;
    :before {
      content: '';
      display: flex;
      width: 14px;
      height: 14px;
      background: url($${(props: WidgetOpenButtonProps) =>
    props.src}) no-repeat;
    }
  }
`;
