import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import store from '../../../core/store/store';
import { setDashboardConfig } from '../../../core/store/actions/app';
import { DashboardConfig } from '../../../types/dashboard';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { ModalSubtitle } from '../../../styles/ui-controls';
import { CheckBox } from '../../atoms/Checkbox';
import {
  FormContainer,
  CheckBoxWrap,
  TitleBlock,
} from './common';
import { DropdownElement } from '../../../elements';
import { SelectOption } from '../../../types';

interface DisplayColumnsFormProps {}

export const TableSettingsForm: FunctionComponent<
DisplayColumnsFormProps
> = () => {
  const { t } = useTranslation();
  const sortingOptions: SelectOption<any>[] = [
    {
      value: false,
      label: t('connection.sortByName'),
    },
    {
      value: true,
      label: t('connection.customSorting'),
    },
  ];
  const appConfigs: DashboardConfig = useTypedSelector(
    ({ app }) =>
      app?.dashboard_config,
  );
  const [isOvpnConfigs, setIsOvpnConfigs] = useState<any>();
  const [isRotation, setIsRotation] = useState<any>();
  const [isExternalIp, setIsExternalIp] = useState<any>();
  const [isTariff, setIsTariff] = useState<any>();
  const [isExpirationDates, setIsExpirationDates] = useState<any>();
  const [isConnectionId, setIsConnectionId] = useState<any>();
  const [isUsage, setIsUsage] = useState<any>();
  const [isServerGeo, setIsServerGeo] = useState<any>();
  const [sortingOption, setSortingOption] = useState<any>(sortingOptions[0]);

  useEffect(() => {
    if (appConfigs != null) {
      setIsOvpnConfigs(appConfigs?.configs?.isOvpnConfigsColumn);
      setIsRotation(appConfigs?.configs?.isRotationColumn);
      setIsExternalIp(appConfigs?.configs?.isExternalIpColumn);
      setIsTariff(appConfigs?.configs?.isTariffColumn);
      setIsExpirationDates(appConfigs?.configs?.isExpirationDatesColumn);
      setIsConnectionId(appConfigs?.configs?.isConnectionIdColumn);
      setIsUsage(appConfigs?.configs?.isUsageColumn);
      setIsServerGeo(appConfigs?.configs?.isServerGeoColumn);
      setSortingOption(sortingOptions.find((item) =>
        item.value === appConfigs?.configs?.isCustomSorting));
    }
  }, []);

  return (
    <Wrapper>
      <FormContainer>
        <Section>
          <TitleBlock>
            <ModalSubtitle>{t('dashboard.displayColumns')}</ModalSubtitle>
          </TitleBlock>
          <CheckBoxWrap>
            <CheckBox
              value={isOvpnConfigs}
              onClick={(status) => {
                setIsOvpnConfigs(status);
                store.dispatch(
                  setDashboardConfig.request({
                    configs: {
                      ...appConfigs?.configs,
                      isOvpnConfigsColumn: status,
                    },
                  }),
                );
              }}
              label="oVPN Configs"
              hasLabel
            />
          </CheckBoxWrap>
          <CheckBoxWrap>
            <CheckBox
              value={isRotation}
              onClick={(status) => {
                setIsRotation(status);
                store.dispatch(
                  setDashboardConfig.request({
                    configs: {
                      ...appConfigs?.configs,
                      isRotationColumn: status,
                    },
                  }),
                );
              }}
              label={`${t('rotation.title')}`}
              hasLabel
            />
          </CheckBoxWrap>
          <CheckBoxWrap>
            <CheckBox
              value={isExternalIp}
              onClick={(status) => {
                setIsExternalIp(status);
                store.dispatch(
                  setDashboardConfig.request({
                    configs: {
                      ...appConfigs?.configs,
                      isExternalIpColumn: status,
                    },
                  }),
                );
              }}
              label={`${t('rotation.externalIP')}`}
              hasLabel
            />
          </CheckBoxWrap>
          <CheckBoxWrap>
            <CheckBox
              value={isTariff}
              onClick={(status) => {
                setIsTariff(status);
                store.dispatch(
                  setDashboardConfig.request({
                    configs: {
                      ...appConfigs?.configs,
                      isTariffColumn: status,
                    },
                  }),
                );
              }}
              label={`${t('tariff.title')}`}
              hasLabel
            />
          </CheckBoxWrap>
          <CheckBoxWrap>
            <CheckBox
              value={isExpirationDates}
              onClick={(status) => {
                setIsExpirationDates(status);
                store.dispatch(
                  setDashboardConfig.request({
                    configs: {
                      ...appConfigs?.configs,
                      isExpirationDatesColumn: status,
                    },
                  }),
                );
              }}
              label={`${t('tariff.expirationDates')}`}
              hasLabel
            />
          </CheckBoxWrap>
          <CheckBoxWrap>
            <CheckBox
              value={isConnectionId}
              onClick={(status) => {
                setIsConnectionId(status);
                store.dispatch(
                  setDashboardConfig.request({
                    configs: {
                      ...appConfigs?.configs,
                      isConnectionIdColumn: status,
                    },
                  }),
                );
              }}
              label={`${t('dashboard.connectionID')}`}
              hasLabel
            />
          </CheckBoxWrap>
          <CheckBoxWrap>
            <CheckBox
              value={isUsage}
              onClick={(status) => {
                setIsUsage(status);
                store.dispatch(
                  setDashboardConfig.request({
                    configs: {
                      ...appConfigs?.configs,
                      isUsageColumn: status,
                    },
                  }),
                );
              }}
              label={`${t('connection.usage')}`}
              hasLabel
            />
          </CheckBoxWrap>
          <CheckBoxWrap>
            <CheckBox
              value={isServerGeo}
              onClick={(status) => {
                setIsServerGeo(status);
                store.dispatch(
                  setDashboardConfig.request({
                    configs: {
                      ...appConfigs?.configs,
                      isServerGeoColumn: status,
                    },
                  }),
                );
              }}
              label={`${t('connection.serverGeo')}`}
              hasLabel
            />
          </CheckBoxWrap>
        </Section>
        <Section style={{ marginTop: '32px' }}>
          <TitleBlock>
            <ModalSubtitle>{t('dashboard.defaultSorting')}</ModalSubtitle>
          </TitleBlock>
          <DropdownElement
            value={sortingOption}
            onSelected={(value) => {
              setSortingOption(value);
              store.dispatch(
                setDashboardConfig.request({
                  configs: {
                    ...appConfigs?.configs,
                    isCustomSorting: value.value,
                  },
                }),
              );
            }}
            options={sortingOptions}
          />
        </Section>
      </FormContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Section = styled.div``;
