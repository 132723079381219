import React, {
  useState, useEffect, useRef, FunctionComponent,
} from 'react';
import { useWindowSize } from '@react-hook/window-size';
import styled from 'styled-components';
import { LabelCounter } from '../atoms/label-counter';

interface StepperProps {
  config?: any;
  customBubbleText?: string;
  activeIndex?: number;
}

// #6198DE

export const Stepper: FunctionComponent<StepperProps> = (props) => {
  const {
    config,
    customBubbleText,
    activeIndex = 0,
  } = props;
  const [width] = useWindowSize();
  const lineRef = useRef(null);
  const [configWithStyling, setConfigWithStyling] = useState(config);

  useEffect(() => {
    const dotSize = 10;
    const totalConfig = config?.length || 0;
    const lineWidth = lineRef?.current?.offsetWidth || 0;
    const sectionWidth = lineWidth / totalConfig;

    const c = config?.map((item: any, index: number) => {
      const pointPosition = index === 0
        ? 0
        : index === totalConfig - 1
          ? lineWidth - dotSize
          : (sectionWidth + sectionWidth / totalConfig) * index + 1;

      const textLabel = document.getElementById(`stepper_active_${index}`);
      const textLabelLength = textLabel?.offsetWidth || 0;

      const labelPosition = index === 0
        ? 0
        : index === totalConfig - 1
          ? textLabelLength
          : textLabelLength / 2 - dotSize / 2;

      return {
        ...item,
        style: {
          left: pointPosition,
          labelLeft: labelPosition,
        },
      };
    });
    setConfigWithStyling(c);
  }, [lineRef, config, width]);

  return (
    <Wrapper>

      <StepperContainer className="desktop">
        {
                    configWithStyling.map((item: any, index: number) => {
                      const showActive = activeIndex === index;
                      return (
                        <Step key={item?.title} active={activeIndex > index}>
                          <Dot active={showActive || activeIndex > index} />
                          <LabelCounter
                            isActive={showActive}
                            title={item.title}
                            bubbleText={customBubbleText != null ? customBubbleText : `${index + 1}/${config?.length}`}
                          />
                        </Step>
                      );
                    })
                }

      </StepperContainer>

      <StepperContainer className="mobile">
        <Line ref={lineRef} />
        <Line active style={{ width: `${configWithStyling[activeIndex || 0]?.style?.left}px` }} />

        {
                    configWithStyling.map((item: any, index: number) => {
                      const showActive = activeIndex === index;

                      return (
                        <Step
                          key={item?.title}
                          className="absolute bg"
                          style={{ top: '-5px', left: `${item?.style?.left}px` }}
                          active={showActive}
                        >
                          <div className="absolute" style={{ left: 0, top: 0 }}>
                            <Dot active={showActive || activeIndex > index} />
                          </div>
                          <div className="absolute" style={{ left: '-16px', top: '-6px' }}>
                            <LabelCounter
                              isActive={showActive}
                              title=""
                              bubbleText={customBubbleText != null ? customBubbleText : `${index + 1}/${config?.length}`}
                            />
                          </div>
                          {
                                    showActive ? (
                                      <div
                                        className="absolute mobile-title"
                                        id={`stepper_active_${index}`}
                                        style={{ left: `-${item?.style?.labelLeft || 0}px` }}
                                      >
                                        {item?.title}
                                      </div>
                                    ) : null
                                }
                        </Step>
                      );
                    })
                }
      </StepperContainer>
    </Wrapper>
  );
};

interface DotProps {
  active?: boolean;
}

const Dot = styled.div<DotProps>`
  width: 10px;
  height: 10px;
  background-color: ${(props:DotProps) =>
    (props.active ? '#fff' : '#6198DE')};
  border-radius: 50%;
  margin-right: 15px;
  top: 0;
  left: 0;
  position: relative;
  z-index: 1;
`;

const Wrapper = styled.div`
  height: auto;
  position: relative;
  //min-height: 70px;
  
  .mobile-title{
    color: #fff;
    top: 20px;
    left: -50%;
    
  }
  
  .absolute{
    position: absolute;
  }
  .mobile{
    display: none;
  }
  
    @media only screen and (max-width: 880px) {
      .desktop{
        display: none;
      }
      
      .mobile{
        display: flex;
        min-height: 70px;
      }
    }
`;

interface LineProps {
  active?: boolean;
}

const Line = styled.div<LineProps>`
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: ${(props: LineProps) =>
    (props?.active ? '#fff' : '#6198DE')} ;
`;

const StepperContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  position: relative;
  height: auto;
  min-height: 50px;

`;

interface StepProps {
  active?: boolean;
  showLine?: boolean;
}

const Step = styled.div<StepProps>`
  display: flex;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 35px;
  position: relative;
  transition: all 0.3s ease-in-out;

  
  @media only screen and (min-width: 880px) {
    :after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: ${(props: StepProps) =>
    (props?.active ? '#fff' : '#6198DE')} ;
      left: 4px;
      top: 50%;
      transform: translateY(-25%);;
    } 
    :last-of-type:after {
      display: none;
    }
  }
  
`;
