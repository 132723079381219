import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import theme from '../styles/ApplicationTheme';
import { SelectOption } from '../types';

interface DropdownElementProps {
  value?: SelectOption<any>;
  options?: SelectOption<any>[];
  onSelected?: Function;
  disabled?: boolean;
  isSearchable?: boolean;
  placeholder?: any;
}

export const DropdownElement: FunctionComponent<DropdownElementProps> = (
  props,
) => {
  const {
    value,
    options,
    placeholder,
    isSearchable = true,
    disabled = false,
    onSelected,
  } = props;

  const colourStyles = {
    control: (styles, { isFocused }) =>
      ({
        ...styles,
        border: `1px solid ${
          isFocused ? theme.colors.deepBlue : theme.colors.darkGray
        }`,
        boxShadow: isFocused ? '0px 0px 6px rgba(36, 78, 178, 0.2)' : 'none',
        cursor: 'pointer',
        ':hover': {
          border: `1px solid ${
            isFocused ? theme.colors.deepBlue : theme.colors.darkGray
          }`,
        },
      }),
    option: (styles, {
      isDisabled, isFocused, isSelected,
    }) =>
      ({
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? null
            : isFocused
              ? theme.colors.ocenlight
              : null,
        fontWeight: isSelected ? 700 : 400,
        fontSize: '14px',
        color: isSelected ? theme.colors.deepBlue : theme.colors.deepBlue,
        cursor: isDisabled ? 'not-allowed' : 'pointer',

        ':active': {
          ...styles[':active'],
          backgroundColor: theme.colors.ocenlight,
        },
      }),
    indicatorSeparator: (styles) =>
      ({
        ...styles,
        display: 'none',
        color: theme.colors.deepBlue,
      }),
    indicatorContainer: (styles) =>
      ({
        ...styles,
        color: theme.colors.deepBlue,
      }),
    input: (styles) =>
      ({ ...styles }),
    svg: (styles) =>
      ({ ...styles, color: theme.colors.deepBlue }),
    placeholder: (styles) =>
      ({ ...styles, fontSize: '14px' }),
    singleValue: (styles) =>
      ({
        ...styles,
        color: theme.colors.darkGray,
        fontSize: '14px',
      }),
    valueContainer: (styles) =>
      ({
        ...styles,
        color: 'red',
      }),
  };

  return (
    <Wrapper>
      <Select
        value={value}
        placeholder={placeholder}
        theme={(theme) =>
          ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: theme.colors.danger,
            },
          })}
        isSearchable={isSearchable}
        isDisabled={disabled}
        styles={colourStyles as any}
        onChange={(value) =>
          onSelected(value)}
        options={options}
        menuPosition="fixed"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
