import React, { useState, useEffect, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  EditModalActionContainer,
  EditModalSave,
  InputWrapper,
} from '../styles/ui-controls';
import { InputField } from '../elements';

import { Api } from '../core/api';
import { InputLabel } from './UserProfileScreen';
import store from '../core/store/store';
import { loadUserInfo, updateApiKey } from '../core/store/actions/user';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { ApiWarningBlock } from '../components/common/ApiWarningBlock';
import { ApiKeyInput } from '../components/common/ApiKeyInput';
import { Error } from '../elements/InputField';
import { MIN_PASSWORD_LENGTH } from '../constants/common';

import checkIcon from '../assets/check-icon.svg';
import errorIcon from '../assets/close-red.svg';

const PASSWORD_REG_EXP = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{${MIN_PASSWORD_LENGTH},}$`,
);

export interface ChangePasswordProps {
  isProfile?: boolean;
  setChangePassword?: Function;
}

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const RenderNewPasswordChecks = (check: boolean, msg: string) =>
  (
    <InnerWrapper>
      <img
        alt={check ? 'Check icon' : 'Error icon'}
        src={check ? checkIcon : errorIcon}
      />
      <Error>
        {msg}
      </Error>
    </InnerWrapper>
  );

export const ChangePassword: FunctionComponent<ChangePasswordProps> = ({
  isProfile,
  setChangePassword,
}) => {
  const { t } = useTranslation();

  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const { id, api_key: apiKey } = useTypedSelector(({ user }) =>
    user?.profile);

  const [passwordErrors, setPasswordErrors] = useState({
    old: {
      msg: '',
      hasError: false,
    },
    new: {
      msg: '',
      hasError: false,
    },
    confirm: {
      msg: '',
      hasError: false,
    },
  });

  const [newPasswordChecks, setNewPasswordChecks] = useState({
    isLowerCaseChar: false,
    isUpperCaseChar: false,
    isDigitChar: false,
    isSpecialChar: false,
    isValidLength: false,
  });

  const validateOldPassword = () =>
    oldPassword !== null && oldPassword !== '';

  const validateNewPasswordChecks = (value) => {
    const isValidSpecialChars = /^[A-Za-z\d@$!%*#?&]*$/.test(value);
    const isLowerCaseChar = /[a-z]/.test(value);
    const isUpperCaseChar = /[A-Z]/.test(value);
    const isDigitChar = /\d/.test(value);
    const isSpecialChar = /[@$!%*#?&]/.test(value);
    const isValidLength = value.length >= MIN_PASSWORD_LENGTH;

    if (!isValidSpecialChars) {
      setNewPasswordChecks((prevState) =>
        ({
          ...prevState,
          isLowerCaseChar,
          isUpperCaseChar,
          isDigitChar,
          isSpecialChar: false,
          isValidLength,
        }));
      return;
    }

    setNewPasswordChecks((prevState) =>
      ({
        ...prevState,
        isLowerCaseChar,
        isUpperCaseChar,
        isDigitChar,
        isSpecialChar,
        isValidLength,
      }));
  };

  const validateNewPassword = () =>
    password !== null
    && password !== ''
    && password !== oldPassword
    && PASSWORD_REG_EXP.test(password);

  const validateConfirmationPassword = () =>
    password === passwordConfirm;

  useEffect(() => {
    setPasswordErrors((prevErrors) =>
      ({
        ...prevErrors,
        old: {
          msg:
          !validateOldPassword() && oldPassword?.length > 0
            ? `${t('profile.oldPswAlert')}`
            : '',
          hasError: !validateOldPassword() && oldPassword?.length > 0,
        },
      }));
  }, [oldPassword]);

  useEffect(() => {
    setPasswordErrors((prevErrors) =>
      ({
        ...prevErrors,
        new: {
          msg:
          !validateNewPassword() && password?.length > 0
            ? password === oldPassword
              ? `${t('profile.newPswMatchesOld')}`
              : password
                ? `${t('profile.newPasswordValidation')}`
                : `${t('profile.newPswAlert')}`
            : '',
          hasError: !validateNewPassword() && password?.length > 0,
        },
      }));
  }, [password, oldPassword]);

  useEffect(() => {
    setPasswordErrors((prevErrors) =>
      ({
        ...prevErrors,
        confirm: {
          msg:
          !validateConfirmationPassword() && passwordConfirm?.length > 0
            ? `${t('profile.confirmPswAlert')}`
            : '',
          hasError:
          !validateConfirmationPassword() && passwordConfirm?.length > 0,
        },
      }));
  }, [password, passwordConfirm]);

  const isFormValid = () =>
    validateOldPassword()
    && validateNewPassword()
    && validateConfirmationPassword();

  const $changePass = async () => {
    if (isFormValid()) {
      try {
        await Api.post(
          '/change_password',
          { oldPassword, newPassword: password },
          null,
          false,
        ).then(() => {
          setChangePassword();
          if (apiKey) {
            store.dispatch(updateApiKey.request({ userId: id }));
          }
          store.dispatch(loadUserInfo.request(null, null));
        });

        setChangePassword(false);
      } catch (e) {
        setPasswordErrors((prevErrors) =>
          ({
            ...prevErrors,
            old: {
              hasError: true,
              msg: `${t('profile.oldPswAlertIncorrect')}`,
            },
          }));
      }
    }
  };

  return (
    <>
      <InputWrapper>
        <InputLabel>{t('profile.oldPsw')}</InputLabel>
        <InputField
          value={oldPassword}
          errorMsg={passwordErrors.old.msg}
          error={passwordErrors.old.hasError}
          type="password"
          floatLabel
          placeholder={`${t('profile.password')}`}
          onChange={({ target }) => {
            setOldPassword(target?.value);
          }}
          showPasswordIcon
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>{t('profile.newPsw')}</InputLabel>
        <InputField
          value={password}
          type="password"
          errorMsg={passwordErrors.new.msg}
          error={passwordErrors.new.hasError}
          floatLabel
          placeholder={`${t('profile.password')}`}
          onChange={({ target }) => {
            validateNewPasswordChecks(target?.value);
            setPassword(target?.value);
          }}
          showPasswordIcon
        />
        {
            passwordErrors.new.hasError
            && (
            <>
              {RenderNewPasswordChecks(newPasswordChecks.isLowerCaseChar, t('profile.lowercase'))}
              {RenderNewPasswordChecks(newPasswordChecks.isUpperCaseChar, t('profile.uppercase'))}
              {RenderNewPasswordChecks(newPasswordChecks.isDigitChar, t('profile.digit'))}
              {RenderNewPasswordChecks(newPasswordChecks.isSpecialChar, t('profile.special'))}
              {RenderNewPasswordChecks(newPasswordChecks.isValidLength, t('profile.length', { length: MIN_PASSWORD_LENGTH }))}
            </>
            )
          }
      </InputWrapper>
      <InputWrapper>
        <InputLabel>{t('profile.confirmPsw')}</InputLabel>
        <InputField
          value={passwordConfirm}
          type="password"
          errorMsg={passwordErrors.confirm.msg}
          error={passwordErrors.confirm.hasError}
          floatLabel
          placeholder={`${t('profile.password')}`}
          onChange={({ target }) =>
            setPasswordConfirm(target?.value)}
          showPasswordIcon
        />
      </InputWrapper>

      <InputWrapper>
        <InputLabel>{isProfile ? t('profile.oldApiKey') : t('profile.apiKey')}</InputLabel>
        <ApiKeyInput apiKey={apiKey || '-'} />
      </InputWrapper>

      <ApiWarningBlock
        components={(
          <div
            className="recommendation"
            dangerouslySetInnerHTML={{
              __html: t('profile.apiKeyWarning'),
            }}
          />
        )}
        width="100%"
        padding="0"
        imgSize="40px"
        isWarning
      />

      <EditModalActionContainer style={{ marginTop: '12px' }}>
        <EditModalSave
          disabled={!isFormValid()}
          onClick={() =>
            $changePass()}
        >
          {t('editForm.save')}
        </EditModalSave>
      </EditModalActionContainer>
    </>
  );
};
