import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import iconAndroid from '../../assets/onb_android.png';
import { OnboardButton } from '../../screens/OnboardingScreen';

interface OnbWelcomeFormProps {
  nextScreen?: Function;
}

export const OnbWelcomeForm: FunctionComponent<OnbWelcomeFormProps> = (
  props,
) => {
  const { nextScreen = () => {} } = props;
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="icon">
        <img src={iconAndroid} alt="" />
      </div>

      <h2>{t('onboarding.welcome.title')}</h2>

      <div>
        <div className="info">{t('onboarding.welcome.description_p1')}</div>
        <div className="info">{t('onboarding.welcome.description_p2')}</div>
      </div>

      <div className="actions">
        <OnboardButton
          id="bt"
          className="start_registration_inapp"
          onClick={() =>
            nextScreen()}
        >
          {t('onboarding.welcome.start')}
        </OnboardButton>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  .actions {
    margin-top: 30px;
  }

  .icon {
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #244eb2;
  }

  .info {
    margin-bottom: 13px;
    font-weight: 400;
    font-size: 18px;
    color: #757575;
  }

  @media only screen and (max-width: 880px) {
    text-align: left;
    margin-top: 50px;

    .actions {
      width: 100%;
      #bt {
        width: 100% !important;
      }
    }

    .icon {
      img {
        width: 100px;
      }
    }
  }
`;
