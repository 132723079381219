import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UIProps } from '../../types';

import { LangDropDown } from './LangDropDown';
import { ContentComponent } from '../ContentComponent';

import logo from '../../assets/logo_iproxy.svg';

interface FooterTemplateProps {

}

export const FooterTemplate: FunctionComponent<FooterTemplateProps> = () => {
  const { t } = useTranslation();

  return (
    <Footer>
      <ContentComponent>
        <FooterContent>
          <LeftContainer>
            <Logo onClick={() => {
              window.location.href = '/';
            }}
            />
            <LangDropDown />
          </LeftContainer>
          <LegalMenu>
            <LegalMenuItem onClick={() =>
              window.open('https://iproxy.online/terms-of-service', '_blank')}
            >
              {t('legalMenu.termsAndConditions')}
            </LegalMenuItem>
            <LegalMenuItem onClick={() =>
              window.open('https://iproxy.online/privacy-policy', '_blank')}
            >
              {t('legalMenu.privacyPolicy')}
            </LegalMenuItem>
          </LegalMenu>
        </FooterContent>
      </ContentComponent>
    </Footer>
  );
};

const LeftContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 720px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const Footer = styled.div`
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  box-sizing: border-box;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  box-sizing: border-box;

  @media (max-width: 720px) {
    flex-direction: column;
    padding: 8px 0;
  }
`;

const Logo = styled.div`
  width: 125px;
  height: 70px;
  margin-right: 60px;

  background-size: contain;
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;

  cursor: pointer;

  @media (max-width: 720px) {
    margin-right: 0;
  }
`;

const LegalMenu = styled.div`
  display: flex;
  flex-direction: row;

  color: ${(prop: UIProps) =>
    prop.theme.colors.white};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.medium};
  font-weight: normal;

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;
    margin: 24px 0;
  }
`;

const LegalMenuItem = styled.div`
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;

  @media (max-width: 720px) {
    text-align: center;
    font-size: 1rem;
    margin: 0 0 16px;

    &:last-child {
      margin: 0;
    }
  }

  :hover {
    text-shadow: 0 0 .65px ${(prop: UIProps) =>
    prop.theme.colors.white}, 0 0 .65px ${(prop: UIProps) =>
  prop.theme.colors.white};
  }
`;
