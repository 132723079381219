import React, { FunctionComponent, useRef } from 'react';
import styled from 'styled-components';

import { MenuStructure, UIProps } from '../../types';
import { useModalHook } from '../../hooks';

interface FloatDropDownProps {
  topPosition?: number;
  leftPosition?: number;
  selectedItems?: any;
  show?: boolean;
  callClose: Function;
  menuList: MenuStructure;
}

export const FloatDropDown:FunctionComponent<FloatDropDownProps> = (props) => {
  const {
    topPosition = 0,
    selectedItems = null,
    leftPosition = 0,
    show = false,
    callClose = () => {},
    menuList = null,
  } = props;
  const childrenRef = useRef(null);
  const modalOptions = useModalHook();
  const onClick = (menu: MenuStructure) => {
    if (menu.isModal) {
      modalOptions.show(menu.modalKey, { selectedPhones: selectedItems });
    }

    // menu.action();
    callClose();
  };
  return show && menuList != null ? (
    <Wrapper ref={childrenRef} left={leftPosition} top={topPosition}>
      {
                menuList.subMenu.map((menu: MenuStructure) =>
                  (
                    <MenuItem
                      key={menu.key}
                      onClick={() =>
                        onClick(menu)}
                    >
                      {menu.title}
                    </MenuItem>
                  ))
            }
    </Wrapper>
  ) : null;
};

interface WrapperProps extends UIProps {
  top?: number;
  left?: number;
}

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  background-color: ${(props:WrapperProps) =>
    props.theme.colors.white};
  box-shadow: 1px 1px 6px 2px #1d293f24;
  top: ${(props:WrapperProps) =>
    `${props.top}px`};
  left: ${(props:WrapperProps) =>
    `${props.left}px`};
  min-width: 310px;
  box-sizing: border-box;
  padding: 10px;
`;

const MenuItem = styled.div`
 ${(props:UIProps) =>
    props.theme.sizes.font.medium};
 box-sizing: border-box;
 padding: 10px;
 cursor: pointer;
 transition: .4s;
 
 :hover {
  color: ${(props:UIProps) =>
    props.theme.colors.deepBlue};
  box-shadow: inset 1px 1px 15px 0px #0000000a;
  padding-left: 15px;
 }
`;
