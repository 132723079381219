import React, {
  useState, useEffect, FunctionComponent, useRef,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';
import { MenuStructure, UIProps, PhonesStatusTabsType } from '../types';
import { DashboardSubItem } from './DashboardActionSubMenu';
import { useModalHook, useOnClickOutside } from '../hooks';

import iconArrowDown from '../assets/arrrow_down.svg';
import iconSettings from '../assets/settings_icon.svg';
import iconListView from '../assets/menu_icon.svg';
import iconGridView from '../assets/grid_view.svg';
import iconAdd from '../assets/plus_icon.svg';
import iconMassAction from '../assets/mass-action-icon.svg';
import { ReactComponent as IconShowOnline } from '../assets/online.svg';
import { ReactComponent as IconShowOffline } from '../assets/offline.svg';
import GroupIcon from '../assets/group_icon2.svg';
import deselectAll from '../assets/phone_icons/deselect_all.svg';
import selectAll from '../assets/phone_icons/yes_icon_white.svg';
import closeIcon from '../assets/phone_icons/remove_icon_white.svg';
import settingsIcon from '../assets/settings_icon_slider.svg';

import { useTypedSelector } from '../core/store/selectors/type-selector';
import { ConnectionViewContext } from '../context/ConnectionViewContext';

import { analyticsClassnames } from '../utils/analytics-utils';

interface DashboardActionsMenuProps {
  hasSelectedItems?: boolean;
  selectedPhones?: any;
  onOnlineOnly?: Function;
  hasPhones?: boolean;
  onSelectAll?: Function;
  onClear?: Function;
}

interface PhonesStatusTabsProps {
  title?: string;
  icon?: JSX.Element;
  tab: PhonesStatusTabsType;
  tooltip: string;
  btData: string;
  status: boolean | null;
}

const phonesStatusTabs: PhonesStatusTabsProps[] = [
  {
    title: 'stats.allPorts',
    tab: PhonesStatusTabsType.All,
    tooltip: 'toolTip.showAll',
    btData: 'show_all',
    status: null,
  },
  {
    icon: <IconShowOnline />,
    tab: PhonesStatusTabsType.Online,
    tooltip: 'toolTip.showOnlyOnline',
    btData: 'show_online_only',
    status: true,
  },
  {
    icon: <IconShowOffline />,
    tab: PhonesStatusTabsType.Offline,
    tooltip: 'toolTip.showOnlyOffline',
    btData: 'show_offline_only',
    status: false,
  },
];

export const DashboardActionsMenu: FunctionComponent<
DashboardActionsMenuProps
> = (props) => {
  const {
    hasPhones,
    hasSelectedItems = false,
    onOnlineOnly = () => {},
    onSelectAll = () => {},
    selectedPhones = null,
    onClear = () => {},
  } = props;

  const modalOptions = useModalHook();
  const supMenuClick = useRef(null);
  const { t } = useTranslation();

  const [openAll] = useState(false);
  const [showFloatMenu, setShowFloatMenu] = useState(false);
  const [onlyOnline, setOnlyOnline] = useState(null);
  const phones = useTypedSelector(({ connections }) =>
    connections);
  const { profile } = useTypedSelector(({ user }) =>
    user);
  const [allSelected, setAllSelected] = useState(false);
  const [expandMobileMenu, setExpandMenu] = useState(false);
  const [activePhonesStatusTab, setActivePhonesStatusTab] = useState<PhonesStatusTabsType>(PhonesStatusTabsType.All);

  const context = React.useContext(ConnectionViewContext);

  const dashBoardMenu: MenuStructure[] = [
    {
      title: `${t('massActions.downloadCredentials')}`,
      onClick: () => {
        modalOptions.show('download-credentials', { selectedPhones, phones });
      },
    },
    {
      title: `${t('massActions.addToGroup')}`,
      onClick: () => {
        modalOptions.show('multi-group', { selectedPhones });
      },
    },
    {
      title: `${t('massActions.changeRotation')}`,
      onClick: () => {
        modalOptions.show('multi-rotation', { selectedPhones });
      },
    },
    {
      title: `${t('massActions.multiPayments')}`,
      onClick: () => {
        modalOptions.show('multi-payments', { selectedPhones });
      },
    },
    {
      title: `${t('massActions.multiRemove')}`,
      onClick: () => {
        modalOptions.show('multi-remove', { selectedPhones, onClear });
      },
    },
    {
      title: `${t('massActions.multiRename')}`,
      onClick: () => {
        modalOptions.show('multi-rename', { selectedPhones });
      },
    },
    {
      title: `${t('connection.changeIP')}`,
      onClick: () => {
        modalOptions.show('multi-changeIP', { selectedPhones });
      },
    },
    {
      title: `${t('massActions.shareTeam')}`,
      onClick: () => {
        modalOptions.show('multi-teamControl', { selectedPhones });
      },
    },
    // {
    //   title: `${t('massActions.globalDNS')}`,
    //   onClick: () => {
    //     modalOptions.show('multi-setupDNS', { selectedPhones });
    //   },
    // },
    {
      title: `${t('massActions.openVPNDNS')}`,
      onClick: () => {
        modalOptions.show('multi-setupOvpnDNS', { selectedPhones });
      },
    },
    {
      title: `${t('massActions.deviceRebootInterval')}`,
      onClick: () => {
        modalOptions.show('multi-setupRebootInterval', { selectedPhones });
      },
    },
    {
      title: `${t('proSettings.rebootDevice')}`,
      onClick: () => {
        modalOptions.show('multi-rebootDevice', { selectedPhones });
      },
    },
    !profile?.isLogsForbidden
      ? {
        title: `${t('logs.download')}`,
        onClick: () => {
          modalOptions.show('multi-downloadLogs', { selectedPhones });
        },
      }
      : null,
  ];

  useOnClickOutside(supMenuClick, () =>
    setShowFloatMenu(false));

  useEffect(() => {
    if (hasSelectedItems != null) {
      setShowFloatMenu(false);
    }
  }, [hasSelectedItems]);

  useEffect(() => {
    onOnlineOnly(onlyOnline);
  }, [onlyOnline]);

  const gridViewListOrTile = context?.gridView
    ? t('toolTip.list')
    : t('toolTip.tile');

  return (
    <Wrapper>
      <MenuWrapper>
        {hasSelectedItems ? (
          <ConnectionItem
            onClick={() => {
              setShowFloatMenu(!showFloatMenu);
            }}
            ref={supMenuClick}
          >
            <ItemIcon src={iconMassAction} />
            <span className="actions">{t('connection.bulkActions')}</span>
            <span className="counter">{selectedPhones.length}</span>
            <ItemIcon src={iconArrowDown} rotation90={showFloatMenu} />
            {showFloatMenu ? (
              <SubmenuContainer openAll={openAll}>
                {dashBoardMenu?.map(
                  (menu) =>
                    menu && <DashboardSubItem menu={menu} key={menu.title} />,
                )}
              </SubmenuContainer>
            ) : null}
          </ConnectionItem>
        ) : (
          <ConnectionItem
            className={analyticsClassnames.createConnection}
            onClick={() => {
              modalOptions.show('phone.new');
            }}
          >
            <ItemIcon src={iconAdd} />
            <span>{t('connection.createConnection')}</span>
          </ConnectionItem>
        )}

        <MobileMenuButton onClick={() =>
          setExpandMenu(true)}
        >
          <ItemIcon src={settingsIcon} />
        </MobileMenuButton>

        <MenuNavWrapper className={expandMobileMenu ? 'visible' : undefined}>
          <MenuClose>
            <div
              className="icon-close"
              onClick={() =>
                setExpandMenu(false)}
            >
              <ItemIcon src={closeIcon} />
            </div>
          </MenuClose>
          <MenuNav>
            <MenuNavItem
              onClick={() => {
                modalOptions.show('connection.group');
                setExpandMenu(false);
              }}
            >
              <ItemIcon src={GroupIcon} />
              <span>{t('connection.addGroup')}</span>
            </MenuNavItem>

            <MenuNavItem
              onClick={() => {
                context?.setGridView(!context?.gridView);
                setExpandMenu(false);
              }}
              data-tip={gridViewListOrTile}
            >
              {context?.gridView ? (
                <ItemIcon src={iconListView} />
              ) : (
                <ItemIcon src={iconGridView} />
              )}
              <span className="mobile">{gridViewListOrTile}</span>
            </MenuNavItem>

            {phonesStatusTabs.map(
              ({
                title, icon, tooltip, tab, btData, status,
              }) =>
                (
                  <MenuNavItem
                    key={btData}
                    data-place="left"
                    data-tip={t(tooltip)}
                    onClick={() => {
                      setOnlyOnline(status);
                      setExpandMenu(false);
                      setActivePhonesStatusTab(tab);
                    }}
                    className={
                    activePhonesStatusTab === tab ? 'active-menu-item' : null
                  }
                  >
                    {title && t(title)}
                    {icon && icon}
                    <span className="mobile">{t(tooltip)}</span>
                  </MenuNavItem>
                ),
            )}

            <MenuNavItem
              data-place="left"
              data-tip={`${t('toolTip.selectAll')}`}
              onClick={() => {
                if (hasPhones) {
                  onSelectAll(allSelected);
                  setAllSelected(!allSelected);
                }

                setExpandMenu(false);
              }}
            >
              <ItemIcon src={allSelected ? deselectAll : selectAll} />
              <span className="mobile">{t('toolTip.selectAll')}</span>
            </MenuNavItem>

            <MenuNavItem
              onClick={() => {
                modalOptions.show('dashboard.settings');
                setExpandMenu(false);
              }}
            >
              <ItemIcon src={iconSettings} />
              <span className="mobile">{t('toolTip.settings')}</span>
            </MenuNavItem>
          </MenuNav>
        </MenuNavWrapper>
      </MenuWrapper>

      <ReactTooltip />
    </Wrapper>
  );
};

interface ItemIconProp {
  src?: string;
  rotation90?: boolean;
}

const ItemIcon = styled.div<ItemIconProp>`
  width: 16px;
  height: 16px;
  background-image: url(${(props: ItemIconProp) =>
    props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: ${(prop: ItemIconProp) =>
    (prop.rotation90 ? ' rotate(180deg)' : ' rotate(0)')};
`;

const Wrapper = styled.div``;

interface SubmenuContainerProps extends UIProps {
  openAll?: boolean;
}

const SubmenuContainer = styled.div<SubmenuContainerProps>`
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 14px;
  line-height: 17px;
  color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.white};
  width: 100%;
  position: absolute;
  top: 42px;
  right: 0;
  background-color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.deepBlue};
  box-sizing: border-box;
  z-index: 10;
  padding: 8px 0 8px 0;
  border-radius: 5px;
  transition: 0.2s;
  overflow: hidden;

  .active-arrow {
    width: 100%;
    height: 30px;
    cursor: pointer;
    background-image: url(${iconArrowDown});
    background-position: center;
    background-repeat: no-repeat;
    transform: ${(prop: SubmenuContainerProps) =>
    (prop.openAll ? 'rotate(180deg)' : 'rotate(0)')};

    :hover {
      background-color: #4770d1;
    }
  }

  .sub-item-value {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
    padding: 0 20px;

    :hover {
      background-color: #4770d1;
    }
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ConnectionItem = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  padding: 0 20px;
  height: 38px;
  box-sizing: border-box;

  background-color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.deepBlue};
  border-radius: 5px 0 0 5px;

  cursor: pointer;

  &:hover:before {
    content: '';
    width: 100%;
    height: 130%;
    background-color: rgb(255, 255, 255, 0.1);
    position: absolute;
    top: -16%;
    left: 0;
  }

  & > span {
    margin-left: 5px;
  }

  & > span.actions {
    margin: 0 15px;
  }

  & > span.counter {
    margin-right: 15px;
    border-radius: 100px;
    background-color: white;
    color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.deepBlue};
    padding: 0 9px;
    font-weight: 600;
  }

  @media (max-width: 720px) {
    flex: 1 1;
    border-radius: 5px;
    justify-content: center;
  }
`;

const MenuNavWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  background-color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.deepBlue};
  border-radius: 0 5px 5px 0;
  box-sizing: border-box;

  @media (max-width: 720px) {
    display: none;
  }

  &.visible {
    @media (max-width: 720px) {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 3;
      height: 100vh;

      display: flex;
      flex-direction: column;
      padding: 0 20px;
    }
  }
`;

const MenuNav = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  @media (max-width: 720px) {
    width: 100%;
    flex-direction: column;
  }
`;

const MenuNavItem = styled.li`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;

  padding: 0 20px;
  border-right: 1px solid rgb(255, 255, 255);
  box-sizing: border-box;

  color: #fff;
  font-size: 14px;
  transition: 0.3s;
  cursor: pointer;

  &:hover:before,
  &.active-menu-item:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255, 0.1);
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 720px) {
    width: 100%;
    padding: 30px 20px;

    border-right: none;
    border-left: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    &:hover:before {
      height: 100%;
      top: 0;
    }
  }

  &:first-child {
    border-left: 1px solid rgb(255, 255, 255);

    @media (max-width: 720px) {
      border-left: none;
    }
  }

  &:last-child {
    border-right: none;

    @media (max-width: 720px) {
      border-bottom: none;
    }
  }

  & > span {
    margin-left: 5px;
  }

  & > span.mobile {
    display: none;

    @media (max-width: 720px) {
      display: inline;
    }
  }
`;

const MobileMenuButton = styled.div`
  position: relative;

  display: none;
  width: 38px;
  height: 38px;
  margin-left: 8px;

  background-color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.deepBlue};
  border-radius: 5px;
  cursor: pointer;

  &:hover:before {
    content: '';
    width: 100%;
    height: 130%;
    background-color: rgb(255, 255, 255, 0.1);
    position: absolute;
    top: -16%;
    left: 0;
  }

  @media (max-width: 720px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MenuClose = styled.div`
  display: none;
  cursor: pointer;

  div.icon-close > div {
    width: 32px;
    height: 32px;
  }

  @media (max-width: 720px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 60px;
  }
`;
