import React, {
  useState,
  useEffect,
  FunctionComponent,
  useMemo,
  Suspense,
} from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import { DashboardConfig } from '../../types/dashboard';
import { LoaderIcon } from '../common/LoaderIcon';
import { MenuStructure, UIProps } from '../../types';
import { VerticalModalMenu } from '../common/VerticalModalMenu';
import { Permission, Phone } from '../../types/phones';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import { showNotification, textShorter } from '../../utils';
import { NotificationStreams, useNotificatorHook } from '../../hooks';
import { NotificationElement } from '../../elements';
import { ReactComponent as IconPrimary } from '../../assets/edit modal/primary.svg';
import { ReactComponent as IconAdvanced } from '../../assets/edit modal/advanced.svg';
import { ReactComponent as IconBigDaddyPro } from '../../assets/edit modal/big-daddy-pro.svg';
import { ReactComponent as IconChangeIP } from '../../assets/edit modal/change-ip-address.svg';
import { ReactComponent as IconTariff } from '../../assets/edit modal/tariff.svg';
import { ReactComponent as IconBot } from '../../assets/edit modal/telegram-bot.svg';
import { ReactComponent as IconSMSApp } from '../../assets/edit modal/sms-app.svg';
import { ReactComponent as IconHistory } from '../../assets/edit modal/history.svg';
import { ReactComponent as IconStats } from '../../assets/edit modal/statistic.svg';
import { ConnectionEditViewProvider } from '../../context/ConnectionEditViewContext';
import { ModalTitle } from '../common/ModalTitle';

const PrimarySettingsForm = React.lazy(() =>
  import('../edit-connection/PrimarySettingsForm').then((module) =>
    ({
      default: module.PrimarySettingsForm,
    })));
const AdvancedSettingsForm = React.lazy(() =>
  import('../edit-connection/AdvancedSettingsForm').then((module) =>
    ({
      default: module.AdvancedSettingsForm,
    })));
const BigDaddyProForm = React.lazy(() =>
  import('../edit-connection/BigDaddyProForm').then((module) =>
    ({
      default: module.BigDaddyProForm,
    })));
const ChangeIpForm = React.lazy(() =>
  import('../edit-connection/ChangeIpForm').then((module) =>
    ({
      default: module.ChangeIpForm,
    })));
const TariffForm = React.lazy(() =>
  import('../edit-connection/PhoneTariffSettings/TariffForm').then(
    (module) =>
      ({ default: module.TariffForm }),
  ));
const TelegramBotForm = React.lazy(() =>
  import('../edit-connection/TelegramBotForm').then((module) =>
    ({
      default: module.TelegramBotForm,
    })));
const SMSAppForm = React.lazy(() =>
  import('../edit-connection/SMSAppForm').then((module) =>
    ({
      default: module.SMSAppForm,
    })));
const HistoryPanel = React.lazy(
  () =>
    import('../edit-connection/HistorySettings/HistoryPanel'),
);
const StatisticForm = React.lazy(() =>
  import('../edit-connection/StatisticForm').then((module) =>
    ({
      default: module.StatisticForm,
    })));

interface PhoneEditFormProps {
  modalParams?: {
    phone: Phone;
    menuKey?: string;
  };
}

export const debounceGetPhone = debounce(
  (callback) => {
    callback();
  },
  50,
  {
    leading: false,
    trailing: true,
  },
);

const debounceGetComponent = debounce(
  (callback) => {
    callback();
  },
  50,
  {
    leading: false,
    trailing: true,
  },
);

const defaultPermission: Permission = {
  airplaneMode: true,
  authorizationPIN: true,
  batteryLevelLower: true,
  connectionChangeTariff: false,
  connectionPayment: false,
  connectionRemove: false,
  descriptionEdit: false,
  dnsSetting: true,
  enableUniqueIP: true,
  findPhone: true,
  fixLTE: true,
  ipHistoryView: true,
  logsDownload: true,
  macrosURL: true,
  nameEdit: false,
  openVPNCreate: false,
  openVPNEdit: false,
  openVPNRemove: false,
  pofSetting: true,
  proxiesAreDisconnected: true,
  proxyCreate: false,
  proxyEdit: false,
  proxyRemove: false,
  proxyView: true,
  rebootDevice: true,
  repeatIPView: true,
  rotationSetting: true,
  scheduledReboot: true,
  smsControl: true,
  tariffStatisticView: false,
  toggleAirplaneMode: true,
  uptimeView: true,
  urlChangeIPAdd: true,
  urlChangeIPRemove: true,
  newTeamMembersAdd: false,
};

export const PhoneEditForm: FunctionComponent<PhoneEditFormProps> = (props) => {
  const { modalParams } = props;

  const dashboardConfigs: DashboardConfig = useTypedSelector(
    ({ app }) =>
      app?.dashboard_config,
  );
  const connections = useTypedSelector(({ connections }) =>
    connections.data);
  const notificator = useNotificatorHook();
  const [showNofification, setShowNofification] = useState(false);
  const [notificatonContent] = useState(null);
  const profile = useTypedSelector(({ user }) =>
    user.profile);

  const { t } = useTranslation();

  const menuConfig: MenuStructure[] = [
    {
      key: 'primary',
      title: `${t('editForm.general')}`,
      icon: <IconPrimary />,
      component: <PrimarySettingsForm />,
    },
    {
      key: 'advanced',
      title: `${t('editForm.advanced')}`,
      icon: <IconAdvanced />,
      component: <AdvancedSettingsForm />,
    },
    {
      key: 'big daddy pro',
      title: 'BigDaddy Pro',
      icon: <IconBigDaddyPro />,
      component: <BigDaddyProForm />,
    },
    {
      key: 'changeip',
      title: `${t('connection.changeIP')}`,
      icon: <IconChangeIP />,
      component: <ChangeIpForm />,
    },
    {
      key: 'tariff',
      icon: <IconTariff />,
      title: `${t('tariff.title')}`,
      component: <TariffForm />,
    },
    {
      key: 'bot',
      icon: <IconBot />,
      title: `${t('bot.title')}`,
      component: <TelegramBotForm />,
    },
    {
      key: 'sms app',
      icon: <IconSMSApp />,
      title: 'SMS App',
      component: <SMSAppForm />,
    },
    {
      key: 'history',
      icon: <IconHistory />,
      title: `${t('editForm.history')}`,
      component: <HistoryPanel />,
    },
    {
      key: 'statistic',
      icon: <IconStats />,
      title: `${t('stats.menu')}`,
      component: <StatisticForm />,
    },
  ];

  const [selectedMenu, setSelectedMenu] = useState<MenuStructure>(
    menuConfig[0],
  );

  const selectedPhone = useMemo(
    () =>
      connections?.find((i) =>
        i.id === modalParams.phone.id) || null,
    [modalParams.phone.id, connections],
  );
  const isOwner = useMemo(
    () =>
      selectedPhone?.userId === profile?.id,
    [selectedPhone, profile],
  );

  const permissions = useMemo(() => {
    const settings = selectedPhone?.sharedUsersSettings != null
      ? selectedPhone?.sharedUsersSettings
      : [];
    const permission = settings.find((s) =>
      s.email === profile.email);

    return permission ? permission.permissions : defaultPermission;
  }, [selectedPhone, profile]);

  useEffect(() => {
    debounceGetComponent(() => {
      if (modalParams?.menuKey) {
        const config = menuConfig.find((i) =>
          i.key === modalParams.menuKey);
        setSelectedMenu(config != null ? config : menuConfig[0]);
      } else {
        setSelectedMenu(menuConfig[0]);
      }
    });
  }, [modalParams]);

  useEffect(() => {
    notificator._listen((data) => {
      const { detail } = data;
      if (
        detail?.stream
        && detail?.stream === NotificationStreams.CONNECTION_EDIT_FORM
      ) {
        debounceGetPhone(() => {
          showNotification(<>{detail?.content}</>);
        });
      }
    });
    return () => {};
  }, []);

  return (
    <ConnectionEditViewProvider
      isOwner={isOwner}
      selectedPhone={selectedPhone}
      dashboardConfig={dashboardConfigs?.configs}
      permissions={permissions}
    >
      <Wrapper>
        <Info>
          <ModalTitle>{t('editForm.title')}</ModalTitle>
          <Divider />
          <Description>
            {textShorter(
              `${selectedPhone?.name}${
                selectedPhone?.description ? `- ${selectedPhone.description}` : ''
              }`,
              100,
            )}
          </Description>
          <NotificationBlock>
            <NotificationElement
              onReady={() =>
                setShowNofification(false)}
              color="primary"
              content={notificatonContent}
              showNotification={showNofification}
            />
          </NotificationBlock>
          <Divider />
          <Connection>
            {t('connection.connectionId')}
            :
            {' '}
            <span className="connectionId">{selectedPhone?.id}</span>
          </Connection>
        </Info>

        <Container>
          <Menu>
            <VerticalModalMenu
              menuConfig={menuConfig}
              onChange={(data) =>
                setSelectedMenu(data)}
              activeItem={selectedMenu}
            />
          </Menu>
          <Main>
            <Suspense
              fallback={(
                <ComponentLoaderHolder>
                  <LoaderIcon width="150px" height="150px" />
                </ComponentLoaderHolder>
                )}
            >
              {selectedMenu?.component != null
                ? React.cloneElement(selectedMenu?.component, {
                  menuKey: selectedMenu?.key,
                })
                : null}
            </Suspense>
          </Main>
        </Container>
      </Wrapper>
    </ConnectionEditViewProvider>
  );
};

const ComponentLoaderHolder = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 100%;
`;

const NotificationBlock = styled.div`
  min-width: 200px;
  position: absolute;
  top: -10px;
  right: 0;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  min-width: 1000px;
  box-sizing: border-box;

  @media (max-width: 1210px) {
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    box-sizing: border-box;
    max-height: 100vh;
    height: 100vh;
    overflow-y: auto;
    background: white;
    padding: 10px 25px 10px 10px;
  }
`;

const Description = styled.div`
  color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.smallBold};

  @media (max-width: 720px) {
    width: calc(100vw - 200px);
    font-size: 0.8rem;
  }
`;

const Connection = styled.div`
  color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.smallBold};
  margin-top: 10px;

  .connectionId {
    font-weight: bold;
  }

  @media (max-width: 720px) {
    font-size: 0.8rem;
  }
`;

const Container = styled.div`
  display: flex;
  margin-top: 24px;
  position: relative;

  @media (max-width: 720px) {
    display: block;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const Menu = styled.div`
  width: 220px;
  margin-right: 20px;
  box-sizing: border-box;
  @media (max-width: 720px) {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    margin-right: 0px;
  }
`;

const Main = styled.div`
  width: 100%;
  flex: 1;

  @media (max-width: 720px) {
    margin-top: 10px;
    height: 100%;
  }
`;

const Info = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;

  * {
    margin: 0;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 15px;
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};

  @media (max-width: 720px) {
    display: none;
  }
`;
