import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { OnboardButton } from '../../screens/OnboardingScreen';
import { VideoInstructionBlock } from '../../styles/ui-controls';
import { Auth } from '../edit-connection/BasicSettings/Auth';
import theme from '../../styles/ApplicationTheme';

interface OnbSetupFormProps {
  nextScreen?: Function;
  phoneId: string;
}

export const OnbSetupForm: FunctionComponent<OnbSetupFormProps> = (props) => {
  const {
    nextScreen,
    phoneId,
  } = props;
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <h2>{t('proxy.authorization')}</h2>

      <div className="info">
        {t('onboarding.install.description')}
      </div>
      <div className="video-inst">
        <VideoInstructionBlock onClick={() => {
          window.open(
            i18n?.language === 'ru' ? 'https://youtu.be/V-qu-72mlvw?t=24' : 'https://youtu.be/bTgF8WKemQc?t=25',
            '_blank',
          );
        }}
        >
          {t('bot.videoInstructions')}
        </VideoInstructionBlock>
      </div>

      {
        phoneId === undefined || phoneId === null ? (
          <span className="warning">
            {t('onboarding.install.warning')}
          </span>
        ) : (
          <div className="auth-wrapper">
            <Auth
              isPhoneOwner
              phoneId={phoneId}
            />
          </div>
        )
      }

      <div className="actions">
        <OnboardButton
          id="bt"
          className="verification_code_in_application"
          onClick={() =>
            nextScreen()}
        >
          {t('onboarding.download.action')}
        </OnboardButton>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .video-inst{
    margin: 15px 0 25px 0;
  }

  .auth-wrapper div div div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .warning {
    color: ${theme.colors.warning};
  }

  .actions{
    margin-top: 40px;

    @media only screen and (max-width: 880px) {
      width: 100%;
      #bt{
        width: 100%;
      }
    }
  }

  .icon {
    margin-bottom: 20px;
  }

  h2{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #244EB2;
  }

  .info {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 18px;
    color: #757575;
  }

  @media only screen and (max-width: 880px) {

    .dis-info{
      background-color: #F7F8FA;
      box-sizing: border-box;
      padding: 18px;
      border-radius: 10px;
      text-align: left;
      font-size: 14px;
    }
  }
`;
