import { Epic } from 'redux-observable';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';
import {
  concat, from, interval, of,
} from 'rxjs';
import axios from 'axios';

import {
  catchError, debounce, filter, switchMap, withLatestFrom,
} from 'rxjs/operators';
import i18n from 'i18next';
import { getPhoneRealInfo } from '../actions/connections';
import {
  hidePeloader,
  idle,
  initAuthorizedApp,
  loadDashboardConfig,
  loadLatestAppVersion,
  setDashboardConfig,
  setSystemLanguage,
  updatePhonesGroup,
} from '../actions/app';
import {
  loadUserInfo,
} from '../actions/user';
import { loadPlanList } from '../actions/plans';
import { useLoaderHook } from '../../../hooks';
import { Api } from '../../api';
import { DashboardConfig, DConfig } from '../../../types/dashboard';
import { storage } from '../../../services';
import { getUrlParameterByName } from '../../../utils';

const ApiURLAppLatestVersion = () =>
  'https://iproxy.online/api-rt/1/app_config';
const ApiURLDashboardConfig = () =>
  '/dashboard_config';

// phone/${phoneId}/action_push/${action}

export const initAuthorizedEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(initAuthorizedApp)),
    debounce(() =>
      interval(200)),
    withLatestFrom(action$),
    switchMap(() =>
      concat(
        concat(
          of(loadUserInfo.request(null, null)),
          of(loadPlanList.request(null, null)),
          of(loadLatestAppVersion.request(null, null)),
          of(hidePeloader()),
        ),
        of(loadDashboardConfig.request(null, null)),
        of(getPhoneRealInfo.request(null, null)),
      )),
    catchError(() =>
      of(idle())),
  );

export const hidePreloaderEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(hidePeloader)),
    debounce(() =>
      interval(200)),
    withLatestFrom(action$),
    switchMap(() => {
      const loader = useLoaderHook();
      loader.hide();
      return of(idle());
    }),
    catchError(() =>
      of(idle())),
  );

export const loadLatestAppVersionEpic : Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(loadLatestAppVersion.request)),
    withLatestFrom(action$),
    switchMap(() =>
      from(axios.get(ApiURLAppLatestVersion())).pipe(
        switchMap(({ data }) =>
          of(loadLatestAppVersion.success(data?.app?.version))),
        catchError((error: Error) =>
          of(loadLatestAppVersion.failure(error))),

      )),
    catchError(() =>
      of(idle())),
  );

export const loadUserInfoEpic : Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(loadUserInfo.success)),
    withLatestFrom(action$),
    switchMap(() =>
      of(idle())),
    catchError(() =>
      of(idle())),
  );

export const setSystemLanguageEpic : Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(setSystemLanguage)),
    withLatestFrom(action$),
    switchMap(([{ payload }]) =>
      of(setDashboardConfig.request({
        configs: {
          // @ts-ignore
          ...state$.value.app.dashboard_config.configs,
          language: payload,
        },
      }))),
    catchError(() =>
      of(idle())),
  );

export const updatePhonesGroupEpic : Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updatePhonesGroup.request)),
    withLatestFrom(action$),
    switchMap(([{ payload }]) => {
      const { groupId, phonesId, action } = payload;
      const phoneGroups = state$.value?.app?.dashboard_config?.configs?.connectionGroups;
      const updatedGroups = phoneGroups?.map((group) =>
        ({
          ...group,
          connections: group?.connections?.filter((connection) =>
            !phonesId?.includes(connection)),
        }))?.map((group) =>
        ({
          ...group,
          connections: group.name === groupId
            ? action === 'add'
              ? [...group.connections, ...phonesId]
              : group.connections.filter((connection) =>
                !phonesId.includes(connection))
            : group.connections,
        }));

      return of(setDashboardConfig.request({
        configs: {
        // @ts-ignore
          ...state$.value.app.dashboard_config.configs,
          connectionGroups: updatedGroups,
        },
        callback: payload.callback,
      }));
    }),

    catchError(() =>
      of(idle())),
  );

export const loadDashboardConfigEpic : Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(loadDashboardConfig.request)),
    withLatestFrom(action$),
    switchMap(() =>
      from(Api.get(ApiURLDashboardConfig(), null, null, false)).pipe(
        switchMap(({ data }) => {
          const response: DashboardConfig = data?.result as DashboardConfig;
          const config: DConfig = {
            connectionGroups: response?.configs?.connectionGroups ? response?.configs?.connectionGroups : null,
            expandStatus: response?.configs?.expandStatus != null ? response?.configs?.expandStatus : null,
            isConnectionIdColumn: response?.configs?.isConnectionIdColumn === undefined
              ? true
              : response?.configs?.isConnectionIdColumn,
            isOvpnConfigsColumn: response?.configs?.isOvpnConfigsColumn === undefined
              ? true
              : response?.configs?.isOvpnConfigsColumn,
            isRotationColumn: response?.configs?.isRotationColumn === undefined
              ? true
              : response?.configs?.isRotationColumn,
            isExternalIpColumn: response?.configs?.isExternalIpColumn === undefined
              ? true
              : response?.configs?.isExternalIpColumn,
            isTariffColumn: response?.configs?.isTariffColumn === undefined
              ? true
              : response?.configs?.isTariffColumn,
            isExpirationDatesColumn: response?.configs?.isExpirationDatesColumn === undefined
              ? true
              : response?.configs?.isExpirationDatesColumn,
            isUsageColumn: response?.configs?.isUsageColumn === undefined
              ? true
              : response?.configs?.isUsageColumn,
            isServerGeoColumn: response?.configs?.isServerGeoColumn === undefined
              ? true
              : response?.configs?.isServerGeoColumn,
            isCustomSorting: response?.configs?.isCustomSorting === undefined
              ? false
              : response?.configs?.isCustomSorting,
          };

          if (i18n.language !== response?.configs?.language
                        && response?.configs?.language != null
                        && getUrlParameterByName('lang') == null
                        && i18n.options.resources[getUrlParameterByName('lang')]
          ) {
            i18n.changeLanguage(response?.configs?.language);
            storage.addItem('SYSTEM', 'locale', response?.configs?.language);
          }

          return of(loadDashboardConfig.success({
            ...response,
            configs: {
              ...response?.configs,
              ...config,
            },
          }));
        }),
      )),
    catchError(() =>
      of(idle())),
  );

export const setDashboardConfigEpic : Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(setDashboardConfig.request)),
    withLatestFrom(action$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLDashboardConfig(), payload as any)).pipe(
        switchMap(({ data }) => {
          if (payload.callback) {
            payload.callback();
          }

          return of(setDashboardConfig.success(data?.result as DashboardConfig));
        }),
      )),
    catchError(() =>
      of(idle())),
  );
