import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import moment from 'moment';
import iconArrowBlue from '../../assets/bullet-direction.svg';

interface CalendarAtomProps {
  onChange: Function;
  value: any;
  locale?: any
  minDate?: any
  maxDate?: any
  nextLabel?: string
  prevLabel?: string
  navigationLabel?: Function;
}

export const CalendarAtom: FunctionComponent<CalendarAtomProps> = (props) => {
  const {
    onChange = () => {},
    navigationLabel = () => {},
    value = new Date(),
    minDate,
    maxDate = null,
    nextLabel = '',
    prevLabel = '',
    locale = moment().locale('en').format('LL'),
  } = props;
  return (
    <Wrapper>
      <Calendar
        className="calender"
        navigationLabel={navigationLabel}
        nextLabel={nextLabel}
        prevLabel={prevLabel}
        onChange={onChange}
        value={value}
        locale={locale}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .calender {
    min-width: 350px;
  }


  .react-calendar__navigation__label__labelText--from{
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
  }

  .react-calendar__navigation__label{
    left: 0;
    position: absolute;
    top: 10px;
  }

  .react-calendar__navigation button:enabled:hover {
    background-color: transparent !important;
  }

  .react-calendar__navigation button:enabled:focus {
    background-color: transparent !important;
  }

  .react-calendar__navigation__prev-button{
    position: absolute;
    right: 25px;
    display: block;
    width: 33px;
    height: 33px;
    background-image: url(${iconArrowBlue});
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(-180deg);
    top: 5px;
    min-width: auto;
    transition: .2s;
    :hover {
      opacity: 0.8;
    }
  }

  .react-calendar__navigation__next-button{
    position: absolute;
    min-width: auto;
    right: 0;
    top: 5px;
    display: block;
    width: 33px;
    height: 33px;
    background-image: url(${iconArrowBlue});
    background-position: center;
    background-repeat: no-repeat;
    transition: .2s;

    :hover {
      opacity: 0.8;
    }
  }

  .react-calendar {
    width: 300px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 20px;
    padding-top: 10px;

    button {
      font-family: 'Montserrat', sans-serif !important;
    }
  }
  .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button{
    display: none;
  }

  .react-calendar__navigation{
    margin: auto;
    position: relative;
  }

  .react-calendar__viewContainer {
    margin: auto;
  }

  .react-calendar__tile--now {
    background: #1ab580;
    color: #fff;
  }

  .react-calendar__month-view {
    //margin: 20px;
    border-radius: 5px;
  }

  .react-calendar__tile {
    transition: .2s;
  }

  .react-calendar__tile:enabled:hover{
    background-color: #F5F8FF;
    color: #000;
  }

  .react-calendar__month-view__weekdays{

    font-size: 12px;
    font-family: 'Montserrat', sans-serif;

    abbr {
      text-decoration: none !important;
    }

    .react-calendar__month-view__weekdays__weekday{
      border-bottom: none;
      border-top: none;


      :first-child{
        border-left: none;
      }

      :last-child {
        border-right: none;
      }
    }
  }

  .react-calendar__month-view__days{
    border: 1px solid #e9e9e9;
    border-bottom: none;
    border-left: none;
    overflow: hidden;
    border-radius: 5px;

    button {
      font-size: 12px;
      font: 'Montserrat', sans-serif;
      border-left: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;;
    }
  }
`;
