import styled from 'styled-components';
import { UIProps } from '../../../types';
import iconLink from '../../../assets/link_icon.svg';
import iconRemove from '../../../assets/phone_icons/remove_icon.svg';
import iconWarning from '../../../assets/warning_icon.svg';

interface FormContainerProps extends UIProps {
  marginBottom?: string;
  marginBottomAdaptive?: string;
  flexDirection?: string;
  alignItems?: string;
}
export const FormContainer = styled.div<FormContainerProps>`
  margin-bottom: ${(props: FormContainerProps) =>
    props.marginBottom || '35px'};

  @media (max-width: 720px) {
    font-size: 12px;
    margin-bottom: ${(props: FormContainerProps) =>
    props.marginBottomAdaptive || '15px'};
  }

  .additional-block {
    margin-top: 30px;

    .shedule-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  .title {
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.largeBold};
    margin-bottom: 10px;
    font-weight: 600;
    @media (max-width: 720px) {
      font-size: 12px;
    }
  }

  .subtitle {
    color: ${(prop: UIProps) =>
    prop.theme.colors.dark};
  }

  .info {
    display: flex;
    align-items: ${(props: FormContainerProps) =>
    props.alignItems};
    flex-direction: ${(props: FormContainerProps) =>
    props.flexDirection};
    gap: 10px;
    @media (max-width: 720px) {
      font-size: 12px;
    }
  }

  .description {
    font-weight: 500;
    ${(prop: UIProps) =>
    prop.theme.sizes.font.medium};
    @media (max-width: 720px) {
      font-size: 12px;
    }
  }

  .helper {
    position: relative;
    ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    display: flex;
    cursor: pointer;
    font-weight: 600;
    margin-top: -28px;
    margin-left: 21px;

    :before {
      margin-right: 5px;
      content: '';
      display: block;
      width: 28px;
      height: 12px;
      background-image: url(${iconLink});
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 720px) {
        width: 28px;
        height: 12px;
      }
    }
  }

  .drop-down {
    width: 310px;

    @media (max-width: 720px) {
      width: 100%;
    }
  }

  .period-box {
    margin-top: 10px;

    .label {
      font-weight: 600;
      padding-left: 10px;
      margin-bottom: 10px;
      @media (max-width: 720px) {
        margin-bottom: 5px;
      }
    }
  }

  .code-box {
    margin-top: 10px;

    .label {
      font-weight: 600;
      padding-left: 10px;
      margin-bottom: 10px;
      @media (max-width: 720px) {
        margin-bottom: 5px;
      }
    }
  }

  .warning_msg {
    color: ${(props: UIProps) =>
    props.theme.colors.warning};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    display: flex;
    text-align: end;

    &:before {
      content: '';
      display: block;
      background-image: url(${iconWarning});
      background-position: center;
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
      margin-right: 10px;

      @media (max-width: 720px) {
        margin-right: 0;
      }
    }
  }
`;

interface WrapperProps extends UIProps {
  minWidth?: string;
  scrollContainerHeigth?: string;
}
export const Wrapper = styled.div<WrapperProps>`
  min-width: ${(props: WrapperProps) =>
    props.minWidth || '630px'};
  box-sizing: border-box;
  min-height: 440px;
  position: relative;
  @media (max-width: 720px) {
    min-width: unset;
  }

  .savebt {
    display: flex;
    padding: 10px 0;
    justify-content: end;
  }

  .content {
    width: 94%;
    padding: 0 10px;
    box-sizing: border-box;
    margin: auto;
  }

  .total-goups {
    margin-top: 10px;
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller}
  }

  .scroll-container {
    margin-top: 5px;
    height: ${(props: WrapperProps) =>
    props.scrollContainerHeigth || '250px'};
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #244eb214;
    border-radius: 5px;

    @media (max-width: 720px) {
      min-height: 350px;
    }
  }

  .processing {
    text-align: center;
  }
`;

interface GroupsListProps extends UIProps {}
export const GroupsList = styled.div<GroupsListProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .close {
    display: none;
    position: absolute;
    width: 10px;
    height: 10px;
    cursor: pointer;
    align-self: center;
    right: 15px;
    background-image: url(${iconRemove});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

interface GroupsItemProps extends UIProps {
  hasCoupon?: boolean;
  itemContentWidth?: string;
}
export const GroupsItem = styled.div<GroupsItemProps>`
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eef1f8;
  min-height: 15px;
  :hover {
    background: rgba(180, 180, 180, 0.15);
    .close {
      display: block;
    }
  }

  .price {
    font-size: ${(props: GroupsItemProps) =>
    (props.hasCoupon ? '16px' : '18px')};
    line-height: 22px;
    text-decoration: ${(props: GroupsItemProps) =>
    (props.hasCoupon ? 'line-through' : 'none')};
    color: ${(props: GroupsItemProps) =>
    (props.hasCoupon ? props.theme.colors.darkGray : props.theme.colors.dark)};
    display: inline;
  }

  .discount-price {
    font-size: 18px;
    line-height: 22px;
    color: ${(props: GroupsItemProps) =>
    props.theme.colors.greenMud};
    display: inline;
    margin-left: 10px;
  }

  .item-content {
    align-self: center;

    :nth-child(1) {
      width: ${(props: GroupsItemProps) =>
    props.itemContentWidth || '60%'};

      @media (max-width: 720px) {
        width: ${(props: GroupsItemProps) =>
    props.itemContentWidth === '40%' && '30%'};
      }
    }

    :nth-child(2) {
      margin-left: 5px;
      width: 40%;
      @media (max-width: 720px) {
        width: 35%;
      }
    }
  }
`;
