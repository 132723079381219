import styled from 'styled-components';

import closeIcon from '../assets/phone_icons/remove_icon_white.svg';

interface WrapperProps {
  background: string;
}

export const Wrapper = styled.div<WrapperProps>`
  background: ${(props) =>
    props.background};
  padding: 16px 0;
  width: 100%;
  box-sizing: border-box;
`;

export const ContentLayer = styled.div`
  margin: auto;
  position: relative;
  z-index: 5;
  padding-left: 10px;
  box-sizing: border-box;
  max-width: 90%;

  @media (max-width: 720px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: ${(props) =>
    props.theme.colors.white};

  @media (max-width: 768px) {
    font-size: 14px;
    max-width: 350px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const ChangeButton = styled.div`
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: ${(props) =>
    props.theme.colors.darkBlue};
  background-color: ${(props) =>
    props.theme.colors.white};
  padding: 14px 24px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 16px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    text-align: center;
    padding: 7px 10px;
  }
`;

export const CloseButton = styled.div`
  display: block;
  margin-left: 24px;
  background-color: transparent;
  background-image: url(${closeIcon});
  background-position: center;
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;
