import React, {
  FunctionComponent,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SystemSettingsForm } from './settings/system-settings-form';
import { PaymentSettingsForm } from './settings/payment-settings-form';
import { TabsAtom, TabsAtomConfig } from '../atoms';
import { IntegrationSettingsForm } from './settings/integration-settings-form';
import { WrapperScreen } from '../../styles/ui-controls';
import { TableSettingsForm } from './settings/display-columns-form';

interface DashboardSettingsFormProps {}

export const DashboardSettingsForm: FunctionComponent<
DashboardSettingsFormProps
> = () => {
  const { t } = useTranslation();

  const tabsConfig: TabsAtomConfig[] = [
    {
      key: 'system settings',
      title: `${t('dashboard.setup')}`,
      component: (
        <SystemSettingsForm />
      ),
    },
    {
      key: 'columns settings',
      title: `${t('dashboard.tableSettings')}`,
      component: (
        <TableSettingsForm />
      ),
    },
    {
      key: 'integration settings',
      title: 'Integration',
      component: (
        <IntegrationSettingsForm />
      ),
    },
    {
      key: 'payment settings',
      title: `${t('dashboard.settings')}`,
      component: (
        <PaymentSettingsForm />
      ),
    },
  ];
  return (
    <WrapperScreen>
      <Wrapper>
        <TabsAtom config={tabsConfig} />
      </Wrapper>
    </WrapperScreen>
  );
};

const Wrapper = styled.div`
  min-width: 630px;
  min-height: 350px;
  padding: 0 16px;

  @media (max-width: 720px) {
    min-width: unset;
    padding: 0 24px;
  }
`;
