import {
  ThemeUI, UIColors, UIGradients, UIShadows, UISize,
} from '../types';

const sizes: UISize = {
  maxWidth: '98%',
  font: {
    medium: `
            font-size: 16px;
            line-height: 20px;
        `,
    smaller: `
            font-size: 12px;
            line-height: 15px;
        `,
    small: `
            font-size: 14px;
            line-height: 17px;
        `,
    smallBold: `
            font-size: 14px;
            line-height: 17px;
            font-weight: 700;
        `,
    mediumBold: `
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
        `,
    largeBold: `
           font-size: 20px;
            line-height: 24px;
            font-weight: bold;
        `,
    large: `
            font-size: 24px;
            line-height: 29px;
        `,
    larger: `
            font-size: 26px;
            line-height: 31px;
        `,
    largerBold: `
            font-size: 26px;
            line-height: 31px;
            font-weight: bold;
        `,
  },
};

const colors: UIColors = {
  lightOcean: '#EBF5FF',
  lightGray: '#f1f4fa',
  lightSky: '#F7FBFF',
  deepBlue: '#244EB2',
  darkBlue: '#001851',
  warning: '#FF3737',
  pinkRed: '#FF4B56',
  ligthYellow: '#fffae4',
  strongYellow: '#fff3bf',
  lightPink: '#ffe0e5',
  darkGray: '#757575',
  dark: '#1D293F',
  clearDark: 'rgba(4,3,24,0.6)',
  clearWhite: 'rgb(4 3 24 / 40%)',
  lazure: '#3C75FF',
  green: '#0AC806',
  greenMud: '#1AB580',
  white: '#FFFFFF',
  corol: '#FFD6D6',
  astrall: '#A0A5D3',
  darkOcean: '#81AFDF',
  ocenlight: '#EAEEF8',
  spaceGray: '#c3c3c3',
  scrollBg: 'rgba(0, 0, 0, 0.2)',
};

const gradients: UIGradients = {
  lightOcean: 'background: linear-gradient(250.86deg, #88B8E0 2.04%, #6388DA 97.97%)',
  darkBlue: 'background: linear-gradient(283.99deg, #536EFF 19.64%, #0007B5 104.42%);',
};

const shadows:UIShadows = {
  spread: '0 4px 24px #E1E8F5',
  tiny: '',
};

const theme: ThemeUI = {
  fonts: '',
  colors,
  gradients,
  sizes,
  shadows,
};

export default theme;
