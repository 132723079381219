import React, {
  useState,
  useEffect,
  useRef,
  FunctionComponent,
  Suspense,
} from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { useSpring, animated } from 'react-spring';

import Hotkeys from 'react-hot-keys';
import store from '../../core/store/store';
import { UIProps } from '../../types';
import { useModalHook, useOnClickOutside } from '../../hooks';
import { BaseModalFormParams, ConfirmationModalParams } from '../../modal';
import { modalConfirmation, modalMonitor } from '../../core/store/actions/app';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import iconClose from '../../assets/phone_icons/remove_icon.svg';
import iconCloseActive from '../../assets/phone_icons/remove_icon_white.svg';
import { LoaderIcon } from './LoaderIcon';

const debounceCall = debounce(
  (callback) => {
    callback();
  },
  50,
  {
    leading: false,
    trailing: true,
  },
);

export const ModalComponent: FunctionComponent<any> = () => {
  const modalOptions = useModalHook();
  const childrenRef = useRef(null);
  const modalStore = useTypedSelector(({ app }) =>
    app.modal);

  const [content, setContent] = useState(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [confirmationContent, setConfirmationContent] = useState(null);
  const [showCloseBt, setShowCloseBt] = useState(true);
  const animateContainer = useSpring({ opacity: showModal ? 1 : 0 });

  const modalStatus = (status) => {
    if (
      document.getElementsByClassName('medium-zoom-image--opened').length === 0
    ) {
      document.getElementsByTagName('html')[0].style.overflowY = status === true ? 'hidden' : 'scroll';
      setShowModal(status);
    }
  };

  useEffect(() => {
    if (!showModal) {
      store.dispatch(
        modalMonitor({
          data: null,
          active: true,
          confirmation: false,
          error: null,
          loading: null,
        }),
      );
      setConfirmationContent(null);
    }
  }, [showModal]);

  useEffect(() => {
    if (!modalStore.confirmation) {
      setConfirmationContent(null);
    }
  }, [modalStore]);

  useOnClickOutside(childrenRef, () =>
    modalStatus(false));
  useEffect(() => {
    modalOptions.listen(({ detail }) => {
      debounceCall(() => {
        const {
          content, status, params, key, hasCloseBt,
        } = detail;
        modalStatus(status);
        setShowCloseBt(hasCloseBt);
        if (content != null) {
          const componentParams: BaseModalFormParams = {
            modalParams: params,
            _modalOptions: modalOptions,
            initConfirmation: modalOptions.activeConfirmation as any,
          };
          store.dispatch(
            modalMonitor({
              data: {
                modalKey: key,
                params: componentParams,
              },
              active: true,
            }),
          );
          setContent(React.cloneElement(content, componentParams));
        }
      });
    });

    modalOptions._listenConfirmationParams(({ detail }) => {
      const { content, params } = detail;
      const { data } = params as ConfirmationModalParams;
      store.dispatch(modalConfirmation({ show: true }));
      setConfirmationContent(React.cloneElement(content, data));
    });
  }, [modalOptions]);

  return showModal ? (
    <Hotkeys
      keyName="esc"
      onKeyDown={() =>
        modalStatus(false)}
    >
      <Wrapper>
        <ModalContent ref={childrenRef}>
          {showCloseBt ? (
            <CloseBt onClick={() =>
              modalStatus(false)}
            />
          ) : null}
          <div className="content-frame-body">
            <animated.div style={animateContainer}>
              <Suspense
                fallback={(
                  <ComponentLoaderHolder>
                    <LoaderIcon width="150px" height="150px" />
                  </ComponentLoaderHolder>
                )}
              >
                {content}
              </Suspense>
            </animated.div>
          </div>
          {modalStore.confirmation && confirmationContent != null ? (
            <Confirmation>
              <ConfirmationContent>{confirmationContent}</ConfirmationContent>
            </Confirmation>
          ) : null}
        </ModalContent>
      </Wrapper>
    </Hotkeys>
  ) : null;
};

const Wrapper = styled.div`
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.clearDark};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .content-frame-body {
    min-width: 300px;
    min-height: 200px;
  }

  @media (max-width: 992px) {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 720px) {
    overflow-y: auto;
    height: 100vh;
    align-items: flex-end;
    max-height: 100vh;
    box-sizing: border-box;
    overflow-x: hidden;
  }
`;

const ModalContent = styled(animated.div)`
  padding: 30px;
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.white};
  border-radius: 10px;
  min-width: 300px;
  position: relative;
  min-height: 200px;
  transition: 0.2s;

  @media (max-width: 1210px) {
    padding: 10px;
  }

  @media (max-width: 720px) {
    box-sizing: border-box;
    width: 100vw;
    padding: 5px 5px 55px;
    border-radius: 0px;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const Confirmation = styled.div`
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.clearWhite};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  box-sizing: border-box;
  box-shadow: -2px 0 5px 1px #0404041a;
`;

const ConfirmationContent = styled.div`
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.white};
  padding: 20px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  bottom: 0;
`;

const CloseBt = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  right: -65px;
  background-image: url(${iconClose});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.white};
  border-radius: 10px;
  width: 54px;
  height: 38px;
  box-sizing: border-box;

  &:hover {
    background-image: url(${iconCloseActive});
    background-color: ${(prop: UIProps) =>
    prop.theme.colors.warning};
    z-index: 100000;
  }

  @media (max-width: 1210px) {
    position: fixed;
    width: 38px;
    height: 38px;
    right: 17px;
    top: 12px;
    z-index: 100000;
    border: 1px solid #eaeef8;
    background-color: transparent;
  }
`;

export const ComponentLoaderHolder = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 100%;
`;
