import React, {
  useState, useEffect, useRef, FunctionComponent,
} from 'react';
import styled from 'styled-components';
import 'moment/locale/ru';
import 'react-calendar/dist/Calendar.css';

import { useOnClickOutside } from '../../hooks';
import { CalendarAtom } from './CalendarAtom';
import { DateContainer } from './DateContainer';

interface CustomeDateInputAtomProps {
  indicator?: boolean
  value?: any
  activeData?: boolean
  onClick?: Function
  onChange?: Function
  onBlur?: Function
  locale?: string
  minDate?: number
  maxDate?: number
  disabled?: boolean
}

export const CustomeDateInputAtom: FunctionComponent<CustomeDateInputAtomProps> = (props) => {
  const {
    indicator = false,
    maxDate,
    activeData = false,
    disabled = false,
    value = new Date(),
    onClick = () => {},
    onChange = () => {},
    onBlur = () => {},
    locale,
    minDate,
  } = props;

  const containerRef = useRef(null);
  const [data, setDate] = useState(value);
  const [showPicker, setShowPicker] = useState(false);
  const onChangeLocal = (e) => {
    setDate(new Date(e));
    setShowPicker(false);
    onChange(e);
  };
  useOnClickOutside(containerRef, () => {
    if (showPicker) {
      setShowPicker(false);
    }
  });

  useEffect(() => {
    const newValue = value instanceof Date ? value : new Date(value);
    const newDate = data instanceof Date ? data : new Date(data);

    if (newValue?.getTime() !== newDate?.getTime()) {
      setDate(value);
    }
  }, [value]);

  return (
    <CalendarContainer ref={containerRef}>
      <DateContainer
        indicator={indicator}
        active={activeData}
        disabled={disabled}
        locale={locale}
        value={data}
        format="LL"
        onBlur={(e) =>
          onBlur(e)}
        onClick={(e) => {
          if (disabled) {
            return null;
          }
          setShowPicker(!showPicker);
          return onClick(e);
        }}
      />

      {
                showPicker && !disabled ? (
                  <PickerContainer>
                    <CalendarAtom
                      navigationLabel={(p) =>
                        p?.label?.replace('г.', '')}
                      nextLabel=""
                      prevLabel=""
                      onChange={onChangeLocal}
                      value={new Date(data)}
                      locale={locale}
                      minDate={new Date(minDate) || null}
                      maxDate={new Date(maxDate) || null}
                    />

                  </PickerContainer>
                ) : null
            }
    </CalendarContainer>
  );
};

const CalendarContainer = styled.div`
 position: relative;

`;

const PickerContainer = styled.div`
    position: absolute;
    top: 0;
    z-index: 100;

    .calender {
        min-width: 350px;
    }

    @media(max-width: 720px){
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;
